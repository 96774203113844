import React from 'react';
import PropTypes from 'prop-types';
import ReactOnRails from 'react-on-rails';

import AgingForm from '../components/AgingForm/AgingForm';
import { Aging } from '../models';

const AgingFormApp = (props, _railsContext) => {
  window.Constants = _railsContext.Constants;
  const { aging, directories, authenticityToken } = props;
  const $$aging = new Aging(aging);
  return (
    <AgingForm {...{ $$aging, directories, authenticityToken }} />
  );
};

AgingFormApp.propTypes = {
  aging: PropTypes.object.isRequired,
  directories: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

ReactOnRails.register({ AgingFormApp });

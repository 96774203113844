import React from 'react';
import ReactOnRails from 'react-on-rails';

import { DataSizeChart } from '../components/DataSizeChart';

export interface IDataSizeChartAppProps {
  chartProps: any;
  dataSizeLimit: number;
}

const DataSizeChartApp = (props: IDataSizeChartAppProps, railsContext: RailsContext) => {
  (window as any).Constants = railsContext.Constants;
  return <DataSizeChart chartProps={props}/>;
};

ReactOnRails.register({ DataSizeChartApp });

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { View } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

import { actions, IRootState } from '../../ducks/Calendar';

export const FullCalendarWrapper: React.SFC = () => {
  const calendars = useSelector(
    ($$state: IRootState) => $$state.$$calendarList.calendars,
  );
  const timeZone = useSelector(($$state: IRootState) => $$state.timezone);
  const locale = useSelector(($$state: IRootState) => $$state.locale);
  const hideRegions = useSelector(($$state: IRootState) => $$state.hideRegions);

  const dispatch = useDispatch();

  const handleDatesRender = useCallback(
    (args: { view: View; el: HTMLElement }) => {
      dispatch(
        actions.fetchCalendarRequest(args.view.props.dateProfile.renderRange),
      );
    },
    [dispatch],
  );

  const events = [];
  calendars.forEach(calendar => {
    if (hideRegions.includes(calendar.region)) {
      return;
    }
    events.push({
      title: calendar.summary,
      start: calendar.publishing_time,
      url: `/messages/${calendar.id}`,
      color: calendar.color,
    });
  });

  return (
    <>
      <FullCalendar
        {...{ locale, events, timeZone }}
        defaultView="timeGridWeek"
        plugins={[dayGridPlugin, timeGridPlugin]}
        datesRender={handleDatesRender}
        header={{
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,dayGridMonth',
        }}
        fixedWeekCount={false}
      />
    </>
  );
};

import * as React from 'react';

import I18n from '../../lib/i18n';
import VirtualFile from '../../models/DistributionFile/VirtualFile';

interface IFileViewProps {
  file: VirtualFile;
  canEditDatafile: boolean;
}

export default function FileView(props: IFileViewProps) {
  const { file, canEditDatafile } = props;
  return (
    <div>
      <table className="table">
        <tbody>
          <tr>
            <td className="table__caption">
              <b>{I18n.t('activerecord.attributes.virtual_file.filename')}</b>
            </td>
            <td>{file.filename}</td>
          </tr>
          <tr>
            <td className="table__caption">
              <b>{I18n.t('activerecord.attributes.virtual_file.note')}</b>
            </td>
            <td>{file.note}</td>
          </tr>
          <tr>
            <td className="table__caption">
              <b>{I18n.t('activerecord.attributes.virtual_file.warn_blank_schedule')}</b>
            </td>
            <td>{file.warnBlankSchedule.toString() /* そのうちアイコンにしたい */}</td>
          </tr>
        </tbody>
      </table>
      <div className="button_column">
        <div className="button_left_area">
          {(file.canEditVirtualFile) ? <a
            className="btn btn-info"
            href={`/files/${file.id}/edit`}
          >
            {I18n.t('common.action.edit')}
          </a> : null}
        </div>
        <div className="button_right_area">
          {(file.canDestroy && canEditDatafile) ? <a
            data-confirm={I18n.t('common.confirm.destroy')}
            className="btn btn-danger"
            data-method="delete"
            href={`/files/${file.id}`}
          >
            {I18n.t('common.action.destroy')}
          </a> : null}
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';
import { actions } from '../../../../ducks/MessageForm';
import {
  SimpleFormHidden,
  SimpleFormSelect
} from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';
import RatingList from '../../../../models/RatingList';

interface IProps {
  parentFormName: string;
  $$messageDetail: MessageDetail;
  index: number;
  ratingList: RatingList;
  ratingSystem: string;
}
export default class NoDescriptorRatingForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleRatingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { index, ratingSystem } = this.props;
    const key = ['messageDetails', index, 'ratings', ratingSystem];
    this.dispatch(actions.updateFormStore({ key, value: e.target.value }));
  }

  public render() {
    const { parentFormName, $$messageDetail, ratingSystem, ratingList } = this.props;
    const rating = ratingList.getRating(ratingSystem);
    const optionValues = rating.getOptionValues();

    if (ratingSystem === 'NONE') {
      return(
        <SimpleFormHidden
          name={`${parentFormName}[ratings][NONE]`}
          value={optionValues[0][1]}
        />
      );
    }

    return (
      <SimpleFormSelect
        label={I18n.t('activerecord.attributes.message_detail.ratings')}
        name={`${parentFormName}[ratings][${ratingSystem}]`}
        required={false}
        includeBlank={true}
        values={optionValues}
        value={$$messageDetail.ratings.get(ratingSystem) || ''}
        onChange={this.handleRatingChange}
      />
    );
  }
}

import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import createStore from '../store/DeviceForm';
import DeviceForm from '../components/DeviceForm';

const DeviceFormApp = (props, _railsContext) => {
  window.Constants = _railsContext.Constants;
  const store = createStore(props, _railsContext);

  const reactComponent = (
    <Provider {...{ store }}>
      <DeviceForm />
    </Provider>
  );
  return reactComponent;
};

ReactOnRails.register({ DeviceFormApp });

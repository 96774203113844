import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../../lib/i18n';
import {
  SimpleFormCheckbox,
} from '../../lib/components/SimpleForm';
import { SelectableList } from '../../models';

export default class MessageSelector extends React.PureComponent {
  static propTypes = {
    directories: SelectableList.PropTypes.isRequired,
    toggleTestData: PropTypes.func.isRequired,
  }
  static contextTypes = {
    timezone: PropTypes.string,
  }
  renderMessageRow(message) {
    const { toggleTestData } = this.props;
    const dateObj = new Date(message.publishing_time);
    const date = dateObj.toLocaleDateString(I18n.locale, { timeZone: this.context.timezone });
    const time = dateObj.toLocaleTimeString(I18n.locale, { timeZone: this.context.timezone });
    return (
      <tr key={`message_${message.id}`}>
        <td className="data_td_checkbox">
          <SimpleFormCheckbox
            name="data_list[deliverable_ids][]"
            controlId={`test_data_${message.id}`}
            label={message.label()}
            checked={message.selected}
            value={message.id.toString()}
            collection
            onChange={() => toggleTestData(message.id)}
          />
        </td>
        <td><p className="testData_statusValue">{message.status}</p></td>
        <td><p className="testData_dateShowValue">{`${date} ${time}`}</p></td>
      </tr>
    );
  }
  renderTableBodies() {
    return this.props.directories.map((dir) => {
      const attributes = dir.selected ? { id: 'select_item' } : { className: 'select_tableBody_hidden' };
      const rows = dir.deliverables.map(message => this.renderMessageRow(message));
      return (
        <tbody key={dir.id} {...attributes}>
          { rows }
        </tbody>
      );
    });
  }
  render() {
    return (
      <table className="table table-striped text-center sortTable">
        <thead>
          <tr>
            <th className="testData_label">{I18n.t('activerecord.attributes.message.summary')}</th>
            <th className="testData_status">{I18n.t('activerecord.attributes.message.status')}</th>
            <th className="testData_dataShow">{I18n.t('activerecord.attributes.message.publishing_time')}</th>
          </tr>
        </thead>
        { this.renderTableBodies() }
      </table>
    );
  }
}

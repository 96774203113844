import { Record } from 'immutable';

export interface ITopicRecord {
  id: number;
  name: string;
  npns_topic_id: string;
  languages: string[];
  countries: string[];
}
const defaultValue: ITopicRecord = {
  id: 0,
  name: '',
  npns_topic_id: '',
  languages: [],
  countries: [],
};

export default class Topic extends Record(defaultValue) {
  public static maxLength = 80;

  public toSelectOption(): { label: string, value: string } {
    return {
      label: this.name,
      value: this.npns_topic_id,
    };
  }
}

import ActionReducer, { PayloadAction } from 'action-reducer';
import { List, Record } from 'immutable';
import { call, put, takeEvery } from 'redux-saga/effects';

import { get } from '../lib/Api';
import Calendar from '../models/Calendar';
import CalendarList from '../models/CalendarList';
import moment from 'moment';

export interface IRootState {
  $$calendarList: CalendarList;
  hideRegions: List<string>;
  timezone: string;
  locale: string;
}

const defaultValue: IRootState = {
  $$calendarList: new CalendarList(),
  hideRegions: List(),
  timezone: 'UTC',
  locale: 'en',
};
const initialRecord = Record(defaultValue);
export const $$initialState = new initialRecord();

const { reducer, createAction } = ActionReducer($$initialState);
export default reducer;

const FETCH_CALENDAR_REQUEST = 'calendar/fetchCalendarRequest';

interface ICalendarResponse {
  result: Array<{
    id: number;
    status: string;
    summary: string;
    region: string;
    publishing_time: string;
  }>;
}

export const actions = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchCalendarRequest: createAction(FETCH_CALENDAR_REQUEST, ($$state, payload: { start: Date; end: Date }) => $$state.asImmutable(),),
  fetchCalendar: createAction(
    'calendar/fetchCalendar',
    ($$state, payload: ICalendarResponse) => {
      const calendars = List<Calendar>(
        payload.result.map(calendar => new Calendar(calendar)),
      );
      const regions = List(payload.result.map(calendar => calendar.region))
        .filter(region => region !== null)
        .toSet()
        .toList();
      payload.result.map(calendar => calendar.region);
      return $$state.update('$$calendarList', $$calendarList =>
        $$calendarList.set('calendars', calendars).set('regions', regions),
      );
    },
  ),
  toggleHideRegions: createAction(
    'calendar/toggleHideRegions',
    ($$state, payload: string) => {
      let hideRegions = $$state.hideRegions;
      if (hideRegions.includes(payload)) {
        hideRegions = hideRegions.delete(hideRegions.indexOf(payload));
      } else {
        hideRegions = hideRegions.push(payload);
      }
      return $$state.set('hideRegions', hideRegions);
    },
  ),
};

function* fetchCalendar(
  action: PayloadAction<typeof actions.fetchCalendarRequest>,
) {
  // eslint-disable-next-line no-useless-catch
  try {
    const { start, end } = action.payload;
    const res: ICalendarResponse = yield call(
      get,
      `?format=json&q[publishing_time_gteq]=${moment.utc(start).format('YYYY-MM-DD HH:mm:ss')}&q[publishing_time_lt]=${moment.utc(end).format('YYYY-MM-DD HH:mm:ss')}`,
    );
    yield put(actions.fetchCalendar(res));
  } catch (e) {
    throw e;
  }
}

export function* calendarSaga() {
  yield takeEvery(FETCH_CALENDAR_REQUEST, fetchCalendar);
}

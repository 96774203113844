import PropTypes from 'prop-types';
import { Map, Record } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';
import TestData from './TestData';
import VirtualFile from './VirtualFile';
import SelectableList from './SelectableList';

const DirectoryRecord = new Record({
  id: undefined,
  name: undefined,
  deliverables: new SelectableList(),
  virtualFiles: new SelectableList(),
  selected: false,
});

export default class Directory extends DirectoryRecord {
  static PropTypes = recordOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    deliverables: SelectableList.PropTypes.isRequired,
    virtualFiles: SelectableList.PropTypes,
    selected: PropTypes.bool,
  })

  constructor(directory) {
    const { id, name, deliverables } = directory;
    const virtualFiles = VirtualFile.fromDatafiles(deliverables);
    super(
      {
        id,
        name,
        deliverables: (new SelectableList(deliverables, TestData)),
        virtualFiles,
      }
    );
  }
  isMessage() {
    const deliverable = this.deliverables.first();
    if (deliverable === undefined) {
      return null;
    }
    return deliverable.virtual_file_id === undefined;
  }
  isData() {
    return !this.is_message();
  }
  select() {
    return this.set('selected', true);
  }
  unselect() {
    return this.set('selected', false);
  }
  toggleData(id) {
    const newDeliverables = this.deliverables.toggle(id);
    return this.set('deliverables', newDeliverables);
  }
  unselectData(id) {
    const newDeliverables = this.deliverables.unselect(id);
    return this.set('deliverables', newDeliverables);
  }
  toggleVirtualFile(id) {
    const newVirtualFiles = this.virtualFiles.toggle(id);
    return this.set('virtualFiles', newVirtualFiles);
  }
  selectDataExclusive(id) {
    const virtualFileId = this.deliverables.filter(
      datafile => datafile.id === id
    ).first().virtual_file_id;
    const newDeliverables = new SelectableList(this.deliverables.map((data) => {
      if (data.virtual_file_id === virtualFileId) {
        if (data.id === id) {
          return data.toggle();
        }
        return data.unselect();
      }
      return data;
    }), TestData);
    return this.set('deliverables', newDeliverables);
  }
  fileDirectoryMap() {
    return new Map(this.deliverables.valueSeq().map(file => [file.id, this.id]));
  }
  filterDatafileByVirtualFile(virtualFileId) {
    return this.deliverables.filter(
      datafile => datafile.virtual_file_id === virtualFileId
    );
  }
}

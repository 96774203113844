import { Action, createAction, handleActions } from 'redux-actions';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { get } from '../lib/Api';
import MessagePreviewState from '../models/MessagePreviewState';

// Actions
const FETCH_UNPACKED_NEWS_REQUESTED = 'MessagePreview/FETCH_UNPACKED_NEWS_REQUESTED';
const FETCH_UNPACKED_NEWS_SUCCEEDED = 'MessagePreview/FETCH_UNPACKED_NEWS_SUCCEEDED';
const FETCH_UNPACKED_NEWS_FAILED = 'MessagePreview/FETCH_UNPACKED_NEWS_FAILED';
const CHANGE_ACTIVE_LANUAGE = 'MessagePreview/CHANGE_ACTIVE_LANGUAGE';
const OPEN = 'MessagePreview/OPEN';
const CLOSE = 'MessagePreview/CLOSE';

export const $$initialState = new MessagePreviewState();

// Recucer
const reducer = handleActions({
  [FETCH_UNPACKED_NEWS_REQUESTED]: ($$state) => $$state.startFetching(),
  [FETCH_UNPACKED_NEWS_SUCCEEDED]: ($$state, action: Action<any>) => $$state.setUnpackedNews(action.payload),
  [FETCH_UNPACKED_NEWS_FAILED]: ($$state, action: Action<any>) => $$state.setFetchError(action.payload),
  [CHANGE_ACTIVE_LANUAGE]: ($$state, action: Action<any>) => $$state.setActiveLanguage(action.payload),
  [OPEN]: ($$state) => $$state.openModal(),
  [CLOSE]: ($$state) => $$state.closeModal(),
}, $$initialState);

export default reducer;

// Action Creators
const fetchUnpackedNewsRequest = createAction(FETCH_UNPACKED_NEWS_REQUESTED);
const fetchUnpackedNewsSucceeded = createAction(FETCH_UNPACKED_NEWS_SUCCEEDED);
const fetchUnpackedNewsFailed = createAction(FETCH_UNPACKED_NEWS_FAILED);
const changeActiveLanguage = createAction(CHANGE_ACTIVE_LANUAGE);
const open = createAction(OPEN);
const close = createAction(CLOSE);

export const actions = {
  fetchUnpackedNewsRequest,
  fetchUnpackedNewsSucceeded,
  fetchUnpackedNewsFailed,
  changeActiveLanguage,
  open,
  close,
};

// Saga
function* fetchUnpackedNews(action: Action<any>) {
  try {
    const url = `/message_details/${action.payload}/preview.json`;
    const json = yield call(get, url);
    yield put(fetchUnpackedNewsSucceeded(json));
  } catch (e) {
    yield put(fetchUnpackedNewsFailed(e.message));
  }
}

export function* updateUnpackedNewsSaga() {
  yield takeEvery(FETCH_UNPACKED_NEWS_REQUESTED, fetchUnpackedNews);
}

export function* messagePreviewSaga() {
  yield fork(updateUnpackedNewsSaga);
}

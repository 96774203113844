import * as React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import NewsSettingFormContainer from '../components/NewsSettingForm';
import { setDispatch } from '../lib/dispatchExporter';
import { IShopCountry } from '../models/Constants';
import { INewsDescription } from '../models/NewsDescription';
import { INewsSetting } from '../models/NewsSetting';
import { IDescriptionLength, ISelectOptions } from '../models/NewsSettingFormState';
import { ITopicCountry } from '../models/TopicCountry';
import createStore from '../store/NewsSettingForm';

export interface INewsSettingFormAppProps {
  constants: {
    languages: string[];
    shopCountries: IShopCountry[];
  };
  descriptionLength: IDescriptionLength;
  news_descriptions: INewsDescription[];
  news_setting: INewsSetting;
  selectOptions: ISelectOptions;
  topicCountries: ITopicCountry[];
}

const NewsSettingFormApp = (props: INewsSettingFormAppProps, railsContext: RailsContext) => {
  (window as any).Constants = railsContext.Constants;
  const store = createStore(props, railsContext);
  setDispatch(store.dispatch);

  return (
    <Provider store={store}>
      <NewsSettingFormContainer />
    </Provider>
  );
};

ReactOnRails.register({ NewsSettingFormApp });

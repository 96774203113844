import React from 'react';
import PropTypes from 'prop-types';
import { size } from 'lodash';

import I18n from '../../lib/i18n';
import Alert from '../../lib/components/Alert';
import {
  SimpleFormString,
  SimpleFormDateTime,
} from '../../lib/components/SimpleForm';

import { Aging, duplicateNum } from '../../models';
import DataTreeSelector from './DataTreeSelector';

export default class AgingForm extends React.PureComponent {
  static propTypes = {
    $$aging: Aging.PropTypes.isRequired,
    directories: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { deliverCount: null };
  }

  validationErrorNotification() {
    const { $$aging } = this.props;
    if (size($$aging.errors) > 0) {
      return (
        <Alert message={I18n.t('common.message.has_validation_errors')} alertType="danger" />
      );
    }
    return (null);
  }

  handleChange() {
    const start = this.startTime.getValue();
    const end = this.endtTime.getValue();
    const interval = this.interval.getValue();
    if (size(start) * size(end) * size(interval) > 0) {
      const deliverCount = duplicateNum(start, end, interval);
      this.setState({ deliverCount });
    } else {
      this.setState({ deliverCount: null });
    }
  }

  render() {
    const { $$aging, directories } = this.props;
    const selectorHasError = $$aging.errors.base_deliverable_id !== undefined;
    return (
      <div>
        <div className="well">
          { this.state.deliverCount || 'N/A' } 回配信されます。
        </div>
        <div className="form-inputs">
          {this.validationErrorNotification()}
          <SimpleFormDateTime
            name="aging[start_time]"
            required
            label={I18n.t('activerecord.attributes.aging.start_time')}
            defaultValue={$$aging.start_time}
            errorMessage={$$aging.errors.start_time}
            onChange={() => this.handleChange()}
            ref={(c) => { this.startTime = c; }}
          />
          <SimpleFormDateTime
            name="aging[end_time]"
            required
            label={I18n.t('activerecord.attributes.aging.end_time')}
            defaultValue={$$aging.end_time}
            errorMessage={$$aging.errors.end_time}
            onChange={() => this.handleChange()}
            ref={(c) => { this.endtTime = c; }}
          />
          <SimpleFormString
            name="aging[interval]"
            required
            label={I18n.t('activerecord.attributes.aging.interval')}
            defaultValue={$$aging.interval}
            errorMessage={$$aging.errors.interval}
            onChange={() => this.handleChange()}
            ref={(c) => { this.interval = c; }}
          />
          <DataTreeSelector
            directories={directories}
            checkedId={$$aging.base_deliverable_id}
            hasError={selectorHasError}
          />
        </div>
      </div>
    );
  }
}

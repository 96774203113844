import React from 'react';
import ReactOnRails from 'react-on-rails';
import GlyphPopover from '../lib/components/GlyphPopover';

const GlyphPopoverApp = (props) => {
  const { title, body } = props;
  const reactComponent = (
    <GlyphPopover {...{ title, body }} />
  );

  return reactComponent;
};

ReactOnRails.register({ GlyphPopoverApp });

import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import { SimpleFormSelect, SimpleFormString } from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import MessageDetail from '../../../models/MessageDetail';
import { IRootState } from '../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    $$message: state.$$formStore.message,
  };
}

interface IProps {
  index: number;
  $$messageDetail: MessageDetail;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
class NsoParametersForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { updateFormStore, validateForm } = actions;
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.value;
    this.dispatch(updateFormStore({ key, value }));
    this.dispatch(validateForm({ key }));
  }

  public nsoRelativeUrlSelector(index: number) {
    const { $$messageDetail } = this.props;
    const nsoTypes = (window as any).Constants.message.nsoTypes;
    const nsoTypeOptions = Object.keys(nsoTypes)
      .map<[string, string]>(
        e => [I18n.t(`activerecord.hints.message_detail.more_nso_relative_url.${nsoTypes[e]}`), nsoTypes[e]]
      );

    return (
      <SimpleFormSelect
        required={true}
        name={`message[message_details_attributes][${index}][more_nso_relative_url]`}
        label={I18n.t('activerecord.attributes.message_detail.more_nso_relative_url')}
        includeBlank={true}
        values={nsoTypeOptions}
        value={$$messageDetail.more_nso_relative_url || ''}
        errorMessage={$$messageDetail.getIn(['errors', 'more_nso_relative_url'])}
        popoverText={I18n.t('message_details.tooltips.more_nso_relative_url')}
        onChange={this.handleChange}
      />
    );
  }

  public render() {
    const { index, $$messageDetail } = this.props;
    const lengths: { [k: string]: string } = {};
    Object.keys($$messageDetail.validators).forEach(key => {
      const length = $$messageDetail[key] ? $$messageDetail[key].length : 0;
      lengths[key] = `${length}/${$$messageDetail.validators[key].maximum}`;
    });

    return (
      <fieldset>
        {this.nsoRelativeUrlSelector(index)}
        <SimpleFormString
          name={`message[message_details_attributes][${index}][more_nso_text]`}
          required={true}
          label={I18n.t('activerecord.attributes.message_detail.more_nso_text')}
          value={$$messageDetail.more_nso_text || ''}
          errorMessage={$$messageDetail.errors.more_nso_text}
          helpMessage={lengths.more_nso_text}
          popoverText={I18n.t('message_details.tooltips.more_nso_text')}
          onChange={this.handleChange}
          deviceFont={true}
        />
      </fieldset>
    );
  }
}

export default connect(mapStateToProps)(NsoParametersForm);

import React from 'react';

interface IProps {
  more: {};
}

const MoreLink = (props: IProps) => {
  const { more } = props;
  const moreLinks = Object.keys(more);

  if (moreLinks.length !== 0) {
    const moreLinkNodes = moreLinks.map((moreType) => (
      <div className="news-more-link" key={moreType}>
        { more[moreType].text }
      </div>
    ));

    return <div>{ moreLinkNodes }</div>;
  }

  return null;
};

export default MoreLink;

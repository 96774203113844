import ActionReducer, { PayloadAction } from 'action-reducer';
import { List } from 'immutable';
import moment from 'moment-timezone';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { get } from '../lib/Api';
import I18n from '../lib/i18n';
import FeaturedProductDetail from '../models/FeaturedProductDetail';
import MainContent from '../models/MainContent';
import { ICountry } from '../models/Message';
import MessageDetail from '../models/MessageDetail';
import MessageFormState from '../models/MessageFormState';
import Product from '../models/Product';
import Rating from '../models/Rating';
import SuggestVideoList from '../models/SuggestVideoList';
import { IRootState } from '../store/MessageForm';

const { createAction, reducer } = ActionReducer(new MessageFormState());
export default reducer;

// Actions
const FETCH_COUNTRIES_REQUESTED = 'MessageForm/FETCH_COUNTRIES_REQUESTED';
const FETCH_RELATED_CHANNELS_REQUEST = 'MessageForm/FETCH_RELATED_CHANNELS_REQUEST';
const FETCH_PRODUCTS_REQUESTED = 'MessageForm/FETCH_PRODUCTS_REQUESTED';
const FETCH_PRICE_REQUESTED = 'MessageForm/FETCH_PRICE_REQUESTED';
const FETCH_SUGGEST_VIDEO_LIST_REQUEST = 'MessageForm/FETCH_SUGGEST_VIDEO_LIST_REQUEST';
const FETCH_MOVIE_IMAGE = 'MessageForm/FETCH_MOVIE_IMAGE';
const FETCH_MOVIE_SCHEDULE = 'MessageForm/FETCH_MOVIE_SCHEDULE';
const FETCH_NEW_MAIN_CONTENT_REQUEST = 'MessageForm/FETCH_NEW_MAIN_CONTENT_REQUEST';
/* eslint-disable @typescript-eslint/no-unused-vars */
export const actions = {
  fetchCountriesRequest: createAction(FETCH_COUNTRIES_REQUESTED, ($$state, payload: { countryGroupId: string }) => $$state.set('isFetching', true)),
  fetchRelatedChannelsRequest: createAction(FETCH_RELATED_CHANNELS_REQUEST, ($$state, payload: { nsUid: string }) => $$state.asImmutable()),
  fetchPriceRequest: createAction(FETCH_PRICE_REQUESTED, ($$state, payload: { language: string, country: string, nsUid: string, _this: any }) => $$state.asImmutable()),
  fetchProductsRequest: createAction(FETCH_PRODUCTS_REQUESTED, $$state => $$state.setIn(['relatedProducts', 'isFetching'], true)),
  fetchMovieImage: createAction(FETCH_MOVIE_IMAGE, ($$state, payload: { prefix: string[], index: number, digest: string }) => $$state.asImmutable()),
  fetchMovieSchedule: createAction(FETCH_MOVIE_SCHEDULE, ($$state, payload: { prefix: string[], index: number, digest: string }) => $$state.asImmutable()),
  fetchNewMainContentRequest: createAction(FETCH_NEW_MAIN_CONTENT_REQUEST, ($$state, payload: { index: number }) => $$state.set('isFetching', true)),
  updateFormStore: createAction('MessageForm/updateFormStore', ($$state, ...payload: Parameters<typeof $$state.updateLazy>) => $$state.updateLazy(...payload)),
  validateForm: createAction('MessageForm/validateForm', ($$state, ...payload: Parameters<typeof $$state.validate>) => $$state.validate(...payload)),
  updateEditableLanguages: createAction('MessageForm/updateEditableLanguages', $$state => $$state.updateEditableLanguages()),
  updateCanSubmit: createAction('MessageForm/updateCanSubmit', $$state => $$state.setCanSubmit()),
  updateProductsForChangeCountries: createAction('Messageform/updateProductsForChangeCountries', ($$state, ...payload: Parameters<typeof $$state.updateProductsForChangeCountries>) => $$state.updateProductsForChangeCountries(...payload)),
  fetchSuggestVideoListRequest: createAction(FETCH_SUGGEST_VIDEO_LIST_REQUEST, $$state => $$state.asImmutable()),
  applySuggestVideoList: createAction('MessageForm/applySuggestVideoList', $$state => $$state.suggestVideoList.applySuggestVideoList($$state)),
  applyMainVideoSuggestVideoList: createAction('MessageForm/applyMainVideoSuggestVideoList', $$state => $$state.suggestVideoList.applyMainVideoSuggestVideoList($$state)),
  applyMainContentVideoSuggestVideoList: createAction('MessageForm/applyMainContentVideoSuggestVideoList', ($$state, payload: number) => $$state.suggestVideoList.applyMainContentVideoSuggestVideoList($$state, payload)),
  toggleSuggestVideoListLanguageStatus: createAction('MessageForm/toggleSuggestVideoListLanguageStatus', ($$state, payload: string) => $$state.suggestVideoList.toggleLanguageStatus(payload, $$state)),
  updateSuggestVideoList: createAction('MessageForm/updateSuggestVideoList', ($$state, ...payload: Parameters<typeof $$state.suggestVideoList.updateList>) => $$state.update('suggestVideoList', suggestVideoList => suggestVideoList.updateList(...payload))),
  updateProductDetail: createAction('MessageForm/updateProductDetail', ($$state, ...payload: Parameters<typeof $$state.updateProductDetail>) => $$state.updateProductDetail(...payload)),
  toggleProductDiscount: createAction('MessageForm/toggleProductDiscount', ($$state, ...payload: Parameters<typeof $$state.toggleProductDiscount>) => $$state.toggleProductDiscount(...payload)),
  updateComment: createAction('MessageForm/updateComment', ($$state, ...payload: Parameters<typeof $$state.updateComment>) => $$state.updateComment(...payload)),
  selectRelatedProduct: createAction('MessageForm/selectRelatedProduct', ($$state, ...payload: Parameters<typeof $$state.selectRelatedProduct>) => $$state.selectRelatedProduct(...payload)),
  unselectRelatedProduct: createAction('MessageForm/unselectRelatedProduct', ($$state, ...payload: Parameters<typeof $$state.unselectRelatedProduct>) => $$state.unselectRelatedProduct(...payload)),
  sortRelatedProducts: createAction('MessageForm/sortRelatedProducts', ($$state, ...payload: Parameters<typeof $$state.sortRelatedProducts>) => $$state.sortRelatedProducts(...payload)),
  selectFeaturedProduct: createAction('MessageForm/selectFeaturedProduct', ($$state, ...payload: Parameters<typeof $$state.selectFeaturedProduct>) => $$state.selectFeaturedProduct(...payload)),
  unselectFeaturedProduct: createAction('MessageForm/unselectFeaturedProduct', ($$state, ...payload: Parameters<typeof $$state.unselectFeaturedProduct>) => $$state.unselectFeaturedProduct(...payload)),
  sortFeaturedProducts: createAction('MessageForm/sortFeaturedProducts', ($$state, ...payload: Parameters<typeof $$state.sortFeaturedProducts>) => $$state.sortFeaturedProducts(...payload)),
  selectShopList: createAction('MessageForm/selectShopList', ($$state, ...payload: Parameters<typeof $$state.selectShopList>) => $$state.selectShopList(...payload)),
  unselectShopList: createAction('MessageForm/unselectShopList', ($$state, ...payload: Parameters<typeof $$state.unselectShopList>) => $$state.unselectShopList(...payload)),
  sortShopLists: createAction('MessageForm/sortShopLists', ($$state, ...payload: Parameters<typeof $$state.sortShopLists>) => $$state.sortShopLists(...payload)),
  copyRating: createAction('MessageForm/copyRating', ($$state, ...payload: Parameters<typeof $$state.copyRating>) => $$state.copyRating(...payload)),
  setDefaultExpirationTime: createAction('MessageForm/setDefaultExpirationTime', $$state => $$state.setDefaultExpirationTime()),
  setDefaultPickupLimit: createAction('MessageForm/setDefaultPickupLimit', $$state => $$state.setDefaultPickupLimit()),
  clearPickupLimit: createAction('MessageForm/clearPickupLimit', $$state => $$state.clearPickupLimit()),
  clearEssentialPickupLimit: createAction('MessageForm/clearEssentialPickupLimit', $$state => $$state.clearEssentialPickupLimit()),
  addMovie: createAction('MessageForm/addMovie', ($$state, ...payload: Parameters<typeof $$state.addMovie>) => $$state.addMovie(...payload)),
  updateMovie: createAction('MessageForm/updateMovie', ($$state, ...payload: Parameters<typeof $$state.updateMovie>) => $$state.updateMovie(...payload)),
  deleteMovie: createAction('MessageForm/deleteMovie', ($$state, ...payload: Parameters<typeof $$state.deleteMovie>) => $$state.deleteMovie(...payload)),
  switchMovies: createAction('MessageForm/switchMovie', ($$state, ...payload: Parameters<typeof $$state.switchMovies>) => $$state.switchMovies(...payload)),
  notifyAction: createAction('MessageForm/notifyAction', ($$state, payload: Parameters<typeof $$state.notifyAction>[0]) => $$state.notifyAction(payload)),
  selectTopic: createAction('MessageForm/selectTopic', ($$state, ...payload: Parameters<typeof $$state.selectTopic>) => $$state.selectTopic(...payload)),
  unselectTopic: createAction('MessageForm/unselectTopic', ($$state, ...payload: Parameters<typeof $$state.unselectTopic>) => $$state.unselectTopic(...payload)),
  sortTopics: createAction('MessageForm/sortTopics', ($$state, ...payload: Parameters<typeof $$state.sortTopics>) => $$state.sortTopics(...payload)),
  setMoreShopText: createAction('MessageForm/setMoreShopText', ($$state, ...payload: Parameters<typeof $$state.setMoreShopText>) => $$state.setMoreShopText(...payload)),
  setMoreApplicationText: createAction('MessageForm/setMoreApplicationText', ($$state, ...payload: Parameters<typeof $$state.setMoreApplicationText>) => $$state.setMoreApplicationText(...payload)),
  setMoreNsoText: createAction('MessageForm/setMoreNsoText', ($$state, ...payload: Parameters<typeof $$state.setMoreNsoText>) => $$state.setMoreNsoText(...payload)),
  removeMainContent: createAction('MessageForm/removeMainContent', ($$state, ...payload: Parameters<typeof $$state.removeMainContent>) => $$state.removeMainContent(...payload)),
  validateMainContentsBody: createAction('MessageForm/validateMainContentsBody', ($$state, ...payload: Parameters<typeof $$state.validateMainContentsBody>) => $$state.validateMainContentsBody(...payload)),
  checkAllCountries: createAction('MessageForm/checkAllCountries', $$state => $$state.checkAllCountries()),
  uncheckAllCountries: createAction('MessageForm/uncheckAllCountries', $$state => $$state.uncheckAllCountries()),
  copyFeaturedProductsToShopLinkList: createAction('MessageForm/', ($$state, ...payload: Parameters<typeof $$state.copyFeaturedProductsToShopLinkList>) => $$state.copyFeaturedProductsToShopLinkList(...payload)),
  // spec用にexport
  fetchCountriesSucceeded: createAction('MessageForm/fetchCountriesSucceeded', ($$state, ...payload: Parameters<typeof $$state.setCountries>) => $$state.setCountries(...payload)),
  fetchCountriesFailed: createAction('MessageForm/fetchCountriesFailed', ($$state, ...payload: Parameters<typeof $$state.setError>) => $$state.setError(...payload)),
  applySuggestRatings: createAction(
    'MessageForm/applySuggestRatings',
    ($$state, payload: { language: string, ratings: List<Rating> }) => {
      const index = $$state.messageDetails.findIndex(messageDetail => messageDetail.language === payload.language);
      return $$state.updateIn(
        ['messageDetails', index],
        (messageDetail: MessageDetail) => messageDetail.applySuggestRatings(payload.ratings, $$state.ratingList.channelRatings),
      );
    }
  ),
  updateFooterTextValidator: createAction(
    'MessageForm/updateValidator',
    ($$state, index: number ) =>
      $$state.updateIn(['messageDetails', index], (messageDetail: MessageDetail) => messageDetail.updateFooterTextValidator())
  ),
};
const fetchRelatedChannelsSucceeded = createAction('MessageForm/fetchRelatedChannelsSucceeded', ($$state, ...payload: Parameters<typeof $$state.setRelatedChannels>) => $$state.setRelatedChannels(...payload));
const fetchRelatedChannelsFailed = createAction('MessageForm/fetchRelatedChannelsFailed', ($$state, ...payload: Parameters<typeof $$state.setError>) => $$state.setError(...payload));
const fetchProductsSucceeded = createAction('MessageForm/fetchProductsSucceeded', ($$state, ...payload: Parameters<typeof $$state.setProducts>) => $$state.setProducts(...payload));
const fetchProductsFailed = createAction('MessageForm/fetchProductsFailed', ($$state, payload: string) => $$state.asImmutable());
const fetchSuggestVideoListFailed = createAction('MessageForm/fetchSuggestVideoListFailed', ($$state, ...payload: Parameters<typeof $$state.setError>) => $$state.setError(...payload));
const fetchNewMainContentSucceded = createAction('MessageForm/fetchNewMainContentSucceeded', ($$state, ...payload: Parameters<typeof $$state.addNewMainContent>) => $$state.addNewMainContent(...payload));
const fetchNewMainContentFailed = createAction('MessageForm/fetchNewMainContentFailed', ($$state, ...payload: Parameters<typeof $$state.setError>) => $$state.setError(...payload));
/* eslint-disable @typescript-eslint/no-unused-vars */

// Saga
function* fetchMovieImage(action: PayloadAction<typeof actions.fetchMovieImage>) {
  try {
    const { prefix, index, digest } = action.payload;
    const url = `/pms/image/${digest}`;
    const { image: value, error, status } = yield call(get, url);
    if (error) {
      const title = '';
      let message = null;
      if (status === 404) {
        message = I18n.t('activerecord.errors.movie.not_found');
      } else {
        // status的には503が多いハズ
        message = I18n.t('activerecord.errors.message_detail.invalid_movie_url');
      }
      yield put(actions.notifyAction({ title, message }));
      yield put(actions.deleteMovie({ key: prefix, index }));
    } else {
      const key = 'image';
      yield put(actions.updateMovie({ prefix, index, key, value }));
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }
}

function* fetchMovieSchedule(action: PayloadAction<typeof actions.fetchMovieSchedule>) {
  try {
    const { prefix, index, digest } = action.payload;
    const channelPath: string = yield select((state: IRootState) => state.$$formStore.channelPath);
    const url = `${channelPath}/ncms/videos/${digest}`;
    const { video, error, status } = yield call(get, url);
    if (error) {
      const title = '';
      let message = null;
      if (status === 404) {
        message = I18n.t('activerecord.errors.movie.not_found');
      } else {
        // status的には503が多いハズ
        message = I18n.t('activerecord.errors.message_detail.invalid_movie_url');
      }
      yield put(actions.notifyAction({ title, message }));
    } else {
      yield put(actions.updateMovie({ prefix, index, key: 'ncms_video_id', value: video.video_id }));
      yield put(actions.updateMovie({ prefix, index, key: 'distribution_start_datetime', value: video.distribution_start_datetime }));
      yield put(actions.updateMovie({ prefix, index, key: 'distribution_end_datetime', value: video.distribution_end_datetime }));
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }
}

function* fetchCountries(action: PayloadAction<typeof actions.fetchCountriesRequest>) {
  try {
    const { countryGroupId } = action.payload;
    const url = `/country_groups/${countryGroupId}.json`;
    const json = yield call(get, url);
    yield put(actions.fetchCountriesSucceeded(json.countries));
    yield put(actions.updateEditableLanguages());
    yield put(actions.fetchProductsRequest());
    yield put(actions.updateCanSubmit());
    yield put(actions.updateFormStore({ key: ['suggestVideoList'], value: new SuggestVideoList() }));
  } catch (e) {
    yield put(actions.fetchCountriesFailed(e.message));
  }
}

function* fetchPrice(action: PayloadAction<typeof actions.fetchPriceRequest>) {
  try {
    if (typeof((window as any).Alps) === 'undefined') { return null; }
    const { language, country, nsUid, _this } = action.payload;

    // 体験版情報の取得
    const { demo } = yield call(get, `/pms/demo/${nsUid}?country=${country}`);
    if (demo) {
      yield put(actions.updateComment({ nsUid, language, key: 'demo_info', value: I18n.t('nx.suggest_product_text.demo', { locale: language }) }));
    }

    // 価格情報の取得
    const result = yield call((window as any).Alps.Api.getCommonPrice, FeaturedProductDetail.convertLanguageISO(language), country, nsUid);
    const price = result.prices[0];

    // 見つからなかったらfetch statusを戻して何もしない
    if (price.sales_status === 'not_found') {
      _this.setState({ isFetching: false });
      return null;
    }

    // 期間割引があれば反映する
    if (price.discount_price) {
      yield put(actions.updateProductDetail({ nsUid, key: 'discount', value: true }));
      yield put(actions.updateProductDetail({ nsUid, key: 'discount_price', value: price.discount_price.amount }));
      yield put(actions.updateProductDetail({ nsUid, key: 'regular_price', value: price.regular_price.amount }));
      // https://spdlybra.nintendo.co.jp/jira/browse/STORE-1274 参照
      const discountPercent = Math.floor(100 - (price.discount_price.raw_value / price.regular_price.raw_value) * 100 + 0.00000000000001);
      const saleInfo = `${discountPercent}% OFF`;
      const timezone: string = yield select((state: IRootState) => state.$$formStore.timezone);
      yield put(actions.updateComment({ nsUid, language, key: 'sale_info', value: saleInfo }));
      yield put(actions.updateComment({
        nsUid,
        language,
        key: 'supplement',
        value: moment(price.discount_price.end_datetime).tz(timezone).format('Y-M-D H:m Z') }));
    } else {
      yield put(actions.updateProductDetail({ nsUid, key: 'discount', value: false }));
      yield put(actions.updateProductDetail({ nsUid, key: 'regular_price', value: '' }));
      yield put(actions.updateProductDetail({ nsUid, key: 'discount_price', value: price.regular_price.amount }));
    }
    // あらかじめDLがあれば反映する
    if (price.sales_status === 'preorder') {
      yield put(actions.updateProductDetail({ nsUid, key: 'additional_labels', value: ['pre_purchase'] }));
    }
    _this.setState({ isFetching: false });
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  }
}

function* fetchProducts() {
  try {
    const countries: List<ICountry> = yield select((state: IRootState) => state.$$formStore.selectableCountries);
    const channelPath: string = yield select((state: IRootState) => state.$$formStore.channelPath);
    const query = Product.buildCountrySearchQuery(countries.map(e => e.iso2).toArray());
    const products = yield call(get, `${channelPath}/products.json?${query}`);
    if (!Array.isArray(products)) {
      const title = I18n.t('activerecord.models.product');
      const message = I18n.t('messages.error.failed_to_fetch_products');
      yield put(actions.notifyAction({ title, message }));
      return;
    }
    yield put(fetchProductsSucceeded(products));
    const nsUids: List<string> = yield select((state: IRootState) => state.$$formStore.featuredProductIds);
    yield all(
      nsUids
        .filter(nsUid => Product.isSoftDetail(nsUid))
        .toArray()
        .map(nsUid => put(actions.fetchRelatedChannelsRequest({ nsUid })))
    );
  } catch (e) {
    yield put(fetchProductsFailed(e.message));
  }
}

function* fetchRelatedChannels(action: PayloadAction<typeof actions.fetchRelatedChannelsRequest>) {
  try {
    const { nsUid } = action.payload;
    const channelPath: string = yield select((state: IRootState) => state.$$formStore.channelPath);
    const url = `${channelPath}/products/${nsUid}/channels.json`;
    const relatedChannels = yield call(get, url);
    yield put(fetchRelatedChannelsSucceeded({ relatedChannels, nsUid }));
  } catch (e) {
    yield put(fetchRelatedChannelsFailed(e));
  }
}

function* fetchSuggestVideoList() {
  try {
    const channelPath: string = yield select((state: IRootState) => state.$$formStore.channelPath);
    const nsUid: string | undefined = yield select((state: IRootState) => state.$$formStore.suggestVideoList.selectedProduct.value);
    const countries: List<ICountry> = yield select((state: IRootState) => state.$$formStore.message.countries);
    const query = SuggestVideoList.buildCountrySearchQuery(countries.map(e => e.iso2).toArray());
    const url = `${channelPath}/ncms/video_lists/${nsUid}?${query}`;
    yield put(actions.updateSuggestVideoList({ key: 'isFetching', value: true }));
    const { status, video_list: videoList } = yield call(get, url);
    if (status === 500) {
      const title = I18n.t('activerecord.attributes.message_detail.related_movies');
      const message = I18n.t('activerecord.errors.message_detail.ncms_internal_server_error');
      yield put(actions.notifyAction({ title, message }));
    }
    yield put(actions.updateSuggestVideoList({ key: 'list', value: videoList }));
    yield put(actions.updateSuggestVideoList({ key: 'isFetching', value: false }));
  } catch (e) {
    yield put(fetchSuggestVideoListFailed(e));
  }
}

export function* fetchNewMainContent(action: PayloadAction<typeof actions.fetchNewMainContentRequest>) {
  try {
    const { index } = action.payload;
    const mainContents: MainContent[] =
      yield select((state: any) => state.$$formStore.messageDetails.getIn([index, 'main_contents_attributes']));
    const destroyedContentsIndex = mainContents.findIndex((e) => e._destroy);

    if (destroyedContentsIndex === -1) {
      const url = '/main_contents/new';
      const newMainContent = yield call(get, url);
      yield put(fetchNewMainContentSucceded({ index, newMainContent, destroyedContentsIndex }));
    } else {
      const newMainContent = null;
      yield put(fetchNewMainContentSucceded({ index, newMainContent, destroyedContentsIndex }));
    }
  } catch (e) {
    yield put(fetchNewMainContentFailed(e));
  }
}

export function* messageFormSaga() {
  yield takeEvery(FETCH_MOVIE_IMAGE, fetchMovieImage);
  yield takeEvery(FETCH_MOVIE_SCHEDULE, fetchMovieSchedule);
  yield takeEvery(FETCH_COUNTRIES_REQUESTED, fetchCountries);
  yield takeEvery(FETCH_PRODUCTS_REQUESTED, fetchProducts);
  yield takeEvery(FETCH_RELATED_CHANNELS_REQUEST, fetchRelatedChannels);
  yield takeEvery(FETCH_SUGGEST_VIDEO_LIST_REQUEST, fetchSuggestVideoList);
  yield takeEvery(FETCH_PRICE_REQUESTED, fetchPrice);
  yield takeEvery(FETCH_NEW_MAIN_CONTENT_REQUEST, fetchNewMainContent);
}

import * as React from 'react';
import { connect } from 'react-redux';
import Select, { Option } from 'react-select';

import { IRootState } from 'app/store/MessageForm';
import { actions } from '../../../ducks/MessageForm';
import GlyphPopover from '../../../lib/components/GlyphPopover';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import SelectSuggestMovieList from './SelectSuggestMovieList';

function mapStateToProps(state: IRootState) {
  return {
    suggestVideoList: state.$$formStore.suggestVideoList,
    byCountryGroup: state.$$formStore.byCountryGroup,
    relatedProducts: state.$$formStore.relatedProducts,
    countries: state.$$formStore.message.countries,
    timezone: state.$$formStore.timezone,
  };
}

type Props = ReturnType<typeof mapStateToProps>;

class SuggestVideoListForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleProductChange = (e: Option<string>) => {
    const nsUid = e.value;
    if (nsUid === undefined) { return; }
    const { relatedProducts } = this.props;
    const {
      updateSuggestVideoList,
      fetchSuggestVideoListRequest,
    } = actions;

    const product = relatedProducts.items.get(nsUid);
    if (product === undefined) { return; }

    this.dispatch(updateSuggestVideoList({
      key: 'selectedProduct',
      value: product.toSelectOption(),
    }));
    this.dispatch(fetchSuggestVideoListRequest());
  }

  public render() {
    const { relatedProducts, countries, suggestVideoList, byCountryGroup, timezone } = this.props;
    if (!byCountryGroup) { return null; }

    return (
      <div style={{ marginBottom: '5px' }}>
        <label htmlFor="suggest-video-list" className="control-label">
          {I18n.t('message_details.form.suggest_video_list.title')}
        </label>
        <GlyphPopover
          title={I18n.t('message_details.form.suggest_video_list.title')}
          body={I18n.t('message_details.tooltips.suggest_video_list.main')}
        />
        <br />
        <div className="row">
          <div className="col-md-10">
            <Select
              name="suggest-video-list-select"
              options={relatedProducts.filterAvailableCountriesOnlySoftDetail(countries.map((e: any) => e.iso2).toArray())}
              isLoading={relatedProducts.isFetching}
              disabled={relatedProducts.isFetching}
              value={suggestVideoList.selectedProduct}
              onChange={this.handleProductChange}
              clearable={false}
              menuContainerStyle={{ position: 'relative' }}
            />
          </div>
        </div>
        <SelectSuggestMovieList { ...{ suggestVideoList, timezone }} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(SuggestVideoListForm);

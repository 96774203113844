import I18n from './i18n';

let unsaved;
const msg = I18n.t('common.confirm.leave');

$(document).on('turbolinks:load', () => {
  unsaved = false;

  // 変更の認識
  $('form.warning-before-move')
  /* eslint-disable no-return-assign */
    .on('change', () => unsaved = true);
  /* eslint-enable no-return-assign */

  // aタグクリック時の警告
  $('a').click(function (e) {
    if (this.className.includes('no-warning')) return true;
    const href = $(this).attr('href');
    if (typeof (href) !== 'undefined' && href !== '#' && unsaved) {
      if (confirm(msg) === false) e.preventDefault();
    }
  });

  // submitの場合は警告を出さないようにする
  $("input[type=submit]").on('click', () => {
    $(window).off('beforeunload');
  });

  // timezone, locale変更時の警告
  $('form.header__select').on('change', (e) => {
    if (unsaved && confirm(msg) === false) return false;
    e.target.form.submit();
    return true;
  });
});

// タブ閉じ、リロード対策
$(window).on('beforeunload', function(){
  if (unsaved) return(true);
});

import { List, Map, Record } from 'immutable';

const defaultValue: {
  name: string;
  countries: List<string>;
  categories: Map<string, number>;
} = {
  name: '',
  countries: List(),
  categories: Map(),
};

export default class Rating extends Record(defaultValue) {
  public static isClassindUnratedAnticipated(rating: string | number) {
    return 7 <= Number(rating) && Number(rating) <= 12;
  }

  public static isHkClass2(rating: string | number) {
    return Number(rating) === 2;
  }

  public getOptionValues() {
    const optionValues: Array<[string, number]> = [];
    this.categories.forEach((categoryId, categoryName) => {
      optionValues.push([categoryName, categoryId]);
    });
    if (this.name === 'CERO') {
      optionValues.sort((a, b) => a[0] > b[0] ? 1 : -1);
    }
    return optionValues;
  }
}

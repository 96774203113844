import React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessagePreview';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import ListImagePreviews from '../ListImagePreviews';
import LoadingIndicator from '../LoadingIndicator';
import RelatedProductList from '../sharedUnpackeNewsComponents/RelatedProductList';
import NewsBody from './NewsBody';
import NewsHeader from './NewsHeader';

function mapStateToProps(state: any) {
  return {
    isFetching: state.$$previewStore.get('isFetching'),
    semanticsVersion: state.$$previewStore.get('semanticsVersion'),
    languagesAndIds: state.$$previewStore.get('languagesAndIds'),
    activeLanguage: state.$$previewStore.get('activeLanguage'),
    unpackedNews: state.$$previewStore.get('unpackedNews'),
    isPrintPreview: state.$$previewStore.get('isPrintPreview'),
  };
}

type Props = ReturnType<typeof mapStateToProps>;

class UnpackedNewsV1View extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public componentWillMount() {
    const { languagesAndIds, activeLanguage } = this.props;
    this.dispatch(actions.fetchUnpackedNewsRequest(languagesAndIds[activeLanguage]));
  }

  public render() {
    const { unpackedNews, isFetching, isPrintPreview } = this.props;
    if (isFetching) {
      return <LoadingIndicator />;
    }
    if (!unpackedNews) {
      return null;
    }

    const displayClassName =
      isPrintPreview ?
      'display-box-print-preview' :
      'display-box';

    return (
      <div className="message-preview">
        <ListImagePreviews
          listImage={unpackedNews.list_image}
          subjectText={unpackedNews.subject.text}
          iconImage={unpackedNews.news_icon}
          isCaptionDisplay={unpackedNews.isCaptionDisplay()}
        />
        <div className="nx-display">
          <div className={displayClassName}>
            <div className="scroll">
              <div className="main-area">
                <div className="main-contents">
                  <NewsHeader
                    newsIcon={unpackedNews.news_icon}
                    topicName={unpackedNews.topic_name}
                  />
                  <NewsBody
                    subject={unpackedNews.subject.text}
                    mainImage={unpackedNews.body.main_image}
                    mainText={unpackedNews.body.text}
                    more={unpackedNews.more}
                    footerText={unpackedNews.footer.text}
                    ratingIcons={unpackedNews.rating_icons}
                    contentsDescriptors={unpackedNews.contents_descriptors}
                    interactiveElements={unpackedNews.interactive_elements}
                    movieUrl={unpackedNews.body.movieUrl}
                  />
                </div>
              </div>
              <div className="side-area">
                <RelatedProductList relatedProducts={unpackedNews.related_products} />
              </div>
            </div>
          </div>
          <div className="news-viewer-footer-area" />
        </div>
        <p className="hints warning">
          {I18n.t('messages.hints.only_support_chrome')}
        </p>
        <p className="hints">
          {I18n.t('messages.hints.preview_area_emulate_nx_screen_size')}
        </p>
        <p className="hints">
          {I18n.t('messages.hints.preview_area_is_scrollable')}
        </p>
        <p className="hints">
          {I18n.t('messages.hints.line_feed_position_may_not_be_accurate')}
        </p>
      </div>
    );
  }
}

export const UnpackedNewsV1ViewContainer = connect(mapStateToProps)(UnpackedNewsV1View);

import React from 'react';

import I18n from '../../../lib/i18n';
import { IUnpackedRelatedProduct } from '../../../models/MessagePreview/UnpackedRelatedProduct';
import RelatedProduct from './RelatedProduct';

interface IProps {
  relatedProducts: IUnpackedRelatedProduct[];
}

const RelatedProductList = (props: IProps) => {
  const { relatedProducts } = props;
  if (relatedProducts && relatedProducts.length !== 0) {
    const relatedProcuctsNodes = relatedProducts.map((relatedProduct, index) => (
      <RelatedProduct relatedProduct={relatedProduct} key={index} />
    ));

    return (
      <div className="related-products">
        <div className="related-products-title">
          <i className="nxicon nxicon-shop" />
          {I18n.t('activerecord.attributes.message.related_products')}
        </div>
        <div>
          {relatedProcuctsNodes}
        </div>
      </div>
    );
  }

  return null;
};

export default RelatedProductList;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, IRootState } from '../../ducks/Calendar';
import { SimpleFormCheckbox } from '../../lib/components/SimpleForm';

export const CalendarFilter: React.SFC = () => {
  const regions = useSelector(
    ($$state: IRootState) => $$state.$$calendarList.regions,
  );
  const hideRegions = useSelector(($$state: IRootState) => $$state.hideRegions);

  const dispatch = useDispatch();

  const handleChangeCalendarFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(actions.toggleHideRegions(e.target.value));
    },
    [dispatch],
  );

  return (
    <div>
      {regions.map(region => (
        <div key={`calendar-filter-${region}`} className="col-md-1">
          <SimpleFormCheckbox
            controlId={`calendar_filter_${region}`}
            label={region}
            value={region}
            checked={!hideRegions.includes(region)}
            collection={true}
            onChange={handleChangeCalendarFilter}
          />
        </div>
      ))}
    </div>
  );
};

import PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import { mapContains } from 'react-immutable-proptypes';

export default class SelectableList {
  static PropTypes = PropTypes.objectOf(mapContains({ id: PropTypes.number }));
  // objectsはArray<Immutable.Record>を期待する
  // OrderedMapは継承できない仕様のようだったのでメンバに持っている
  constructor(objects = [], Klass = undefined) {
    this.inner_map = new OrderedMap(
      objects.map(object => (Klass === undefined ? [object.id, object] : [object.id, new Klass(object)]))
    );
  }
  get(id) {
    return this.inner_map.get(id);
  }
  set(id, object) {
    const newList = new SelectableList();
    newList.inner_map = this.inner_map.set(id, object);
    return newList;
  }
  getSelected() {
    return this.inner_map.filter(object => object.selected);
  }
  toggle(id) {
    return this.set(id, this.get(id).toggle());
  }
  select(id) {
    return this.set(id, this.get(id).select());
  }
  unselect(id) {
    return this.set(id, this.get(id).unselect());
  }
  selectExclusive(id) {
    return new SelectableList(
      this.inner_map.map(
        object => (object.id === id ? object.select() : object.unselect())
      ).valueSeq()
    );
  }
  clear() {
    const newList = new SelectableList();
    newList.inner_map = this.inner_map.map(object => object.unselect());
    return newList;
  }
  // K,Vが入ったSeqが返っても不便なのでListに変換する
  map(func) {
    return this.inner_map.map(func).toList();
  }
  filter(func) {
    return this.inner_map.filter(func).toList();
  }
  valueSeq() {
    return this.inner_map.valueSeq();
  }
  first() {
    return this.inner_map.first();
  }
}

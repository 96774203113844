import React from 'react';
import NewsText from './NewsText';

interface IProps {
  contentsDescriptors: string;
}

const ContentsDescriptors = (props: IProps) => {
  if (props.contentsDescriptors) {
    return (
      <NewsText text={props.contentsDescriptors} className="contents-descriptors" />
    );
  }

  return null;
};

export default ContentsDescriptors;
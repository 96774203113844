import React from 'react';
import PropTypes from 'prop-types';

import {
  SimpleFormString,
} from '../../lib/components/SimpleForm';
import I18n from '../../lib/i18n';

export default class ListNameForm extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    errorMessage: PropTypes.arrayOf(PropTypes.string),
    updateName: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className="testDataCheck_title">
        <SimpleFormString
          name="data_list[name]"
          required
          label={I18n.t('data_lists.form.name')}
          value={this.props.name}
          errorMessage={this.props.errorMessage}
          onChange={(e) => this.props.updateName(e.target.value)}
        />
      </div>
    );
  }
}

import * as React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import MessageForm from '../components/MessageForm';
import { setDispatch } from '../lib/dispatchExporter';
import { ICountry } from '../models/Message';
import { Descriptor } from '../models/MessageFormState';
import { IChannelRatings, IRatings, IRatingSystems } from '../models/RatingList';
import { ITopicRecord } from '../models/Topic';
import createStore from '../store/MessageForm';

export interface IMessageFormAppProps {
  message: any;
  messageDetails: any[];
  featuredProducts: {
    details: any;
    selectedIds: any;
  };
  defaultActiveLanguage: string;
  countryGroupsOptions: Array<[string, number]>;
  byCountryGroup: boolean;
  selectableCountries: ICountry[];
  availableLanguages: string[];
  channelPath: string;
  subscribeTitles: Array<{ label: string, value: string }>;
  ratingSystems: IRatingSystems;
  ratings: IRatings;
  channelRatings: IChannelRatings;
  contentsDescriptors: { [ratingName: string]: Descriptor };
  interactiveElements: { [ratingName: string]: Descriptor };
  isChina: boolean;
  timezone: string;
  topics: ITopicRecord[];
  copyingDetails: boolean;
}
const MessageFormApp = (props: IMessageFormAppProps, railsContext: RailsContext) => {
  (window as any).Constants = railsContext.Constants;
  const store = createStore(props, railsContext);
  setDispatch(store.dispatch);
  const {
    countryGroupsOptions,
    byCountryGroup,
  } = props;

  return (
    <Provider {...{ store }}>
      <MessageForm {...{ countryGroupsOptions, byCountryGroup }} />
    </Provider>
  );
};

ReactOnRails.register({ MessageFormApp });

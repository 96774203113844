import React from 'react';

interface IProps {
  newsIcon: string;
}

const NewsIcon = (props: IProps) => {
  const { newsIcon } = props;

  if (newsIcon === undefined) {
    return (
      <div className="news-icon-box">
        <div className="news-icon-not-found" />
      </div>
    );
  }

  return (
    <div className="news-icon-box">
      <img
        role="presentation"
        className="news-icon"
        src={`data:image/jpeg;base64,${newsIcon}`}
      />
    </div>
  );
};

export default NewsIcon;

import React from 'react';
import { connect } from 'react-redux';

import I18n from '../../lib/i18n';
import { LanguageSelectorContainer } from './LanguageSelector';
import { UnpackedNewsV1ViewContainer } from './UnpackedNewsV1View';
import { UnpackedNewsV2ViewContainer } from './UnpackedNewsV2View';
import { UnpackedNewsV3ViewContainer } from './UnpackedNewsV3View';
import { UnpackedNewsV4ViewContainer } from './UnpackedNewsV4View';
import { UnpackedNewsV5ViewContainer } from './UnpackedNewsV5View';
import { UnpackedNewsV6ViewContainer } from './UnpackedNewsV6View';
import { UnpackedNewsV7ViewContainer } from './UnpackedNewsV7View';
import { UnpackedNewsV8ViewContainer } from './UnpackedNewsV8View';
import { UnpackedNewsV9ViewContainer } from './UnpackedNewsV9View';

// stateはまだtsしてないのでいったんanyにおく
function mapStateToProps(state: any) {
  return ({
    semanticsVersion: state.$$previewStore.get('semanticsVersion'),
    messageId: state.$$previewStore.get('messageId'),
    isPrintPreview: state.$$previewStore.get('isPrintPreview'),
  });
}

interface IProps {
  semanticsVersion: number;
  showModal?: boolean;
  isFetching?: boolean;
  messageId: number;
  isPrintPreview: boolean;
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

class MessagePreview extends React.PureComponent<Props> {
  public previewComponent() {
    switch ( this.props.semanticsVersion) {
      case 1:
        return <UnpackedNewsV1ViewContainer />;
      case 2:
        return <UnpackedNewsV2ViewContainer />;
      case 3:
        return <UnpackedNewsV3ViewContainer />;
      case 4:
        return <UnpackedNewsV4ViewContainer />;
      case 5:
        return <UnpackedNewsV5ViewContainer />;
      case 6:
        return <UnpackedNewsV6ViewContainer />;
      case 7:
        return <UnpackedNewsV7ViewContainer />;
      case 8:
        return <UnpackedNewsV8ViewContainer />;
      case 9:
        return <UnpackedNewsV9ViewContainer />;
      default:
        return null;
    }
  }

  public printPreviewLink() {
    const { messageId, isPrintPreview } = this.props;

    if (isPrintPreview) { return null; }

    return(
      <a
        className="btn btn-default"
        href={`/messages/${messageId}/print_preview`}
        target="_blank" rel="noreferrer"
      >
        {I18n.t('messages.preview.print_preview')}
      </a>
    );
  }

  public render() {
    return (
      <div>
        <div className="flex_column_full">
          <LanguageSelectorContainer />
          { this.printPreviewLink() }
        </div>
        { this.previewComponent() }
      </div>
    );
  }
}

export const MessagePreviewContainer = connect(mapStateToProps)(MessagePreview);

import * as React from 'react';
import { Glyphicon, OverlayTrigger, Popover } from 'react-bootstrap';

interface IProps {
  id?: string;
  title?: string;
  body?: string;
  glyph: string;
  placement?: string;
  dangerouslySetInnerHTML?: boolean;
}
export default class GlyphPopover extends React.PureComponent<IProps, {}> {
  public static defaultProps = {
    glyph: 'question-sign',
    placement: 'top',
  };

  public render() {
    const { id, title, body, glyph, placement, dangerouslySetInnerHTML } = this.props;
    const popOverId = id ? id : title;
    const popover = (
      <Popover id={popOverId} title={title}>
        {
          dangerouslySetInnerHTML && body
            ? <div dangerouslySetInnerHTML={{ __html: body }} />
            : body
        }
      </Popover>
    );
    if (body === undefined || body.length === 0) {
      return null;
    }
    return (
      <OverlayTrigger overlay={popover} placement={placement}>
        <Glyphicon glyph={glyph} />
      </OverlayTrigger>
    );
  }
}

import React from 'react';
import Plot from 'react-plotly.js';

interface IProps {
  chartProps: any;
}

export const DataSizeChart = (props: IProps) => {
  const { data, layout, config } = props.chartProps;
  return <Plot {...{data, layout, config}} />;
};

import { List, Record } from 'immutable';
import applySortAction from '../lib/applySortActionList';
import lengthValidator from '../lib/lengthValidator';
import universalNewline from '../lib/universalNewline';

export interface ICountry {
  id: number;
  iso2: string;
  languages: string[];
}

export interface IDateTimeOptions {
  defaultTime: string;
  allowTimes?: string[];
}

export const optionsForStartTime: IDateTimeOptions = {
  defaultTime: '00:00',
};

export const optionsForEndTime: IDateTimeOptions = {
  defaultTime: '23:59',
  allowTimes: [
    '00:59', '01:59', '02:59', '03:59', '04:59', '05:59', '06:59', '07:59', '08:59', '09:59', '10:59', '11:59',
    '12:59', '13:59', '14:59', '15:59', '16:59', '17:59', '18:59', '19:59', '20:59', '21:59', '22:59', '23:59',
  ],
};

const defaultValue: {
  id: number,
  summary: string,
  country_group_id: string | number,
  countries: List<ICountry>,
  option_news_form_level: { [s: string]: number },
  news_form_level: number,
  note: string,
  status: string,
  publishing_time: string,
  expiration_time: string,
  priority: string | number,
  essential_priority?: string | number,
  bashotorya: number,
  surprise: string | number,
  caption: string | number,
  semantics_version: number,
  related_products: List<string>,
  related_topics: List<string>,
  searchable_application_ids: List<string>,
  deletion_priority: string | number,
  default_language?: string,
  display_type: string,
  pickup_limit: string,
  essential_pickup_limit: string,
  age_limit: string,
  display_limit: string,
  no_photography: string | number,
  published: boolean,
  decoration_type?: string,
  validators: { [s: string]: { maximum: number } },
  errors: { [s: string]: string[] },
} = {
  id: 0,
  summary: '',
  country_group_id: 0,
  countries: List(),
  option_news_form_level: {},
  news_form_level: 0,
  note: '',
  status: '',
  publishing_time: '',
  expiration_time: '',
  priority: 0,
  essential_priority: undefined,
  bashotorya: 0,
  surprise: 0,
  caption: 0,
  semantics_version: 0,
  related_products: List(),
  related_topics: List(),
  searchable_application_ids: List(),
  deletion_priority: 0,
  default_language: undefined,
  display_type: '',
  pickup_limit: '',
  essential_pickup_limit: '',
  age_limit: '',
  display_limit: '',
  no_photography: 0,
  published: false,
  decoration_type: undefined,
  validators: {},
  errors: {},
};

export default class Message extends Record(defaultValue) {
  public static LANUNCH_NEWS_VERSION = 1;
  public static REQUIRED_DEFAULT_LANGUAGE_NEWS_VERSION = 5;

  constructor(object: any = defaultValue) {
    const newObject = object;
    newObject.countries = List(object.countries);
    newObject.related_products = List(object.related_products);
    newObject.related_topics = List(object.related_topics);
    newObject.searchable_application_ids = List(object.searchable_application_ids);
    newObject.note = universalNewline(object.note);
    super(newObject);
  }

  // mutations
  public setDefaultExpirationTime(): this {
    return this.setDefaultTimeFromPublishingTime('expiration_time', 30);
  }
  public setDefaultPickupLimit(): this {
    return this.setDefaultTimeFromPublishingTime('pickup_limit', 7);
  }
  public selectRelatedProduct(nsUid: string): this {
    return this.update('related_products', list => list.push(nsUid));
  }
  public unselectRelatedProduct(nsUid: string): this {
    const index = this.related_products.keyOf(nsUid);
    if (index === undefined) { throw Error('Message: unselectRelatedProduct index undefined'); }
    return this.update('related_products', list => list.delete(index));
  }
  public sortRelatedProducts(oldIndex: number, newIndex: number) {
    return this.update('related_products', list => applySortAction(list, oldIndex, newIndex));
  }

  public valid(key: string): boolean {
    return lengthValidator(key, this);
  }

  public hasDebuggerNewsLevel(): boolean {
    return this.news_form_level >= this.option_news_form_level.DEBUGGER_NEWS;
  }

  public hasNxNewsLevel(): boolean {
    return this.news_form_level >= this.option_news_form_level.NX_NEWS;
  }

  public hasFirstNewsLevel(): boolean {
    return this.news_form_level >= this.option_news_form_level.FIRST_NEWS;
  }

  public hasThirdNewsLevel(): boolean {
    return this.news_form_level >= this.option_news_form_level.THIRD_NEWS;
  }

  public hasDefaultLanguage(): boolean {
    return this.default_language !== '' && this.default_language !== undefined;
  }

  public isNormalNews(): boolean {
    return this.display_type === (window as any).Constants.message.displayTypes.NORMAL;
  }

  public isSystemNews(): boolean {
    return !this.country_group_id;
  }

  public canInputListNews(): boolean {
    return this.hasNxNewsLevel() && !this.isNormalNews() && +this.semantics_version > Message.LANUNCH_NEWS_VERSION;
  }

  public canInputMovieNews(): boolean {
    return +this.semantics_version > Message.LANUNCH_NEWS_VERSION;
  }

  public requiredDefaultLanguage(byCountryGroup: boolean): boolean {
    return this.semantics_version >= Message.REQUIRED_DEFAULT_LANGUAGE_NEWS_VERSION ||
      (this.semantics_version > Message.LANUNCH_NEWS_VERSION && byCountryGroup);
  }

  public isFixedPickuped(): boolean {
    return this.essential_priority + '' === '2000';
  }

  public isJapan(): boolean {
    return this.countries.filter( country => country.iso2 === 'JP').size === 1;
  }

  private setDefaultTimeFromPublishingTime(param: keyof typeof defaultValue, offsetDate: number): this {
    if (!this.publishing_time || this[param]) { return this; }
    const publishingTimeObj = new Date(this.publishing_time);

    if (publishingTimeObj.toString() === 'Invalid Date') { return this; }

    const defaultTimeObj = new Date(this.publishing_time);
    defaultTimeObj.setDate(publishingTimeObj.getDate() + offsetDate);

    // Date#getMonth は 0始まりなので +1 して月をとれます。
    const year = defaultTimeObj.getFullYear();
    const month = `0${defaultTimeObj.getMonth() + 1}`.slice(-2);
    const day = `0${defaultTimeObj.getDate()}`.slice(-2);
    const defaultTimeStr = `${year}-${month}-${day} 23:59`;
    return this.set(param, defaultTimeStr);
  }
}

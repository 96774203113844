import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import I18n from '../../lib/i18n';
import {
  SimpleFormString,
} from '../../lib/components/SimpleForm';

export default class DeviceRow extends React.PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    device: PropTypes.instanceOf(Map),
    removeForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
  }

  handleClick() {
    const { index, removeForm } = this.props;
    removeForm(index);
  }

  handleChange(e) {
    const { index, updateForm } = this.props;
    // device__name から nameを取り出す
    const column = e.target.id.split('__')[1];
    const value = e.target.value;

    updateForm(index, column, value);
  }

  renderDestroyButton() {
    const { index } = this.props;
    if (index === 0) {
      return (
        <div
          className="btn btn-danger"
          style={{ marginTop: '30px' }}
          onClick={() => this.handleClick()}
        >
          -
        </div>
      );
    }

    return (
      <div
        className="btn btn-danger"
        onClick={() => this.handleClick()}
      >
        -
      </div>
    );
  }

  render() {
    const { index, device } = this.props;

    return (
      <div className="row">
        <div className="col-md-1">
          { this.renderDestroyButton() }
        </div>
        <div className="col-md-3">
          <SimpleFormString
            name="device[devices][][name]"
            required
            label={index === 0 ? I18n.t('activerecord.attributes.device.name') : ''}
            value={device.get('name') || ''}
            placeholder={I18n.t('activerecord.attributes.device.name')}
            errorMessage={device.getIn(['errors', 'name'])}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className="col-md-4">
          <SimpleFormString
            name="device[devices][][serial]"
            required
            label={index === 0 ? I18n.t('activerecord.attributes.device.serial') : ''}
            value={device.get('serial') || ''}
            placeholder={I18n.t('activerecord.attributes.device.serial')}
            errorMessage={device.getIn(['errors', 'serial'])}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className="col-md-4">
          <SimpleFormString
            name="device[devices][][note]"
            label={index === 0 ? I18n.t('activerecord.attributes.device.note') : ''}
            value={device.get('note') || ''}
            placeholder={I18n.t('activerecord.attributes.device.note')}
            errorMessage={device.getIn(['errors', 'note'])}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
      </div>
    );
  }
}

import { List, Record } from 'immutable';
import lengthValidator from '../lib/lengthValidator';
import universalNewline from '../lib/universalNewline';
import Attachment from './Attachment';
import Movie from './Movie';
import MovieList from './MovieList';

const defaultValue: {
  _destroy: boolean,
  id: number,
  body: string,
  main_movie_attributes: MovieList,
  main_image_attributes: Attachment,
  errors: { [s: string]: string[] },
  validators: any,
} = {
  _destroy: false,
  id: 0,
  body: '',
  main_movie_attributes: new MovieList(),
  main_image_attributes: new Attachment(),
  errors: {},
  validators: {}
};

export default class MainContent extends Record(defaultValue) {
  constructor(object: any = defaultValue) {
    const newObject = object;
    newObject.main_movie_attributes = new MovieList ({
      movies: object.main_movie_attributes ? List([new Movie(object.main_movie_attributes)]) : List([]),
      validators: object.validators.main_movie,
      errors: object.errors.main_movie_attributes,
    });
    newObject.main_image_attributes = new Attachment(object.main_image_attributes);
    newObject.body = universalNewline(object.body);
    super(newObject);
  }

  public valid(key: string): boolean {
    return lengthValidator(key, this);
  }

  public destroy(): this {
    return this.set('_destroy', true);
  }

  public recover(): this {
    return this.set('_destroy', false);
  }
}

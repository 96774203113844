/*
  * Bootstrap3 のalert componentを扱うためのreact component
  */

import * as React from 'react';

interface IProps {
  message: string;
  alertType: 'success' | 'info' | 'warning' | 'danger';
}

const Alert = (props: IProps) => (
  <div className={`alert alert-${props.alertType}`} role="alert">
    {props.message}
  </div>
);

export default Alert;

import universalNewline from './universalNewline';

export default function lengthValidator<T>(key: any, self: any, length: any = null, validator: any = null): T {
  let $$state = self;
  const currentValidator = validator || $$state.validators[key];
  if (!currentValidator || !currentValidator.maximum) { return $$state; }

  // 既にエラーが存在し、nullのエラーを除いて他にエラーがなかったらエラーを消す
  if ($$state.errors[key] && $$state.errors[key].filter((e: any) => e !== null).length === 0) {
    const newErrors = $$state.errors;
    delete newErrors[key];
    $$state = $$state.set('errors', newErrors);
  }
  // 指定された要素が空ならvalidationしない
  if (!$$state[key]) { return $$state; }
  // \r\n => \n
  $$state = $$state.set(key, universalNewline($$state[key]));

  const currentLength = length || $$state[key].length;
  if (currentValidator.maximum >= currentLength) { return $$state; }
  // すでにエラーメッセージが存在する場合はnullで上書きしない
  if ($$state.errors[key]) { return $$state; }
  // 特にメッセージを付与しないためnullを送ってエラー枠を発生させている
  const errors = Object.assign(
    $$state.errors,
    { [key]: [null] }
  );
  return $$state.set('errors', errors);
}

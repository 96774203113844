import React from 'react';

interface IProps {
  text: string;
}
interface IState {
  fontSize: number;
  className: string;
  whiteSpace: string;
  visibility: string;
}

/*
 * FeaturedProductの商品名を扱うコンポーネント
 * 商品名は通常22pxで、一行で収まらない文字量の場合に最小16pxまで自動縮退する
 * 16pxでも一行に収まらない場合はテキストの末尾を「…」にして省略する
 *
 */
export default class FeaturedProductName extends React.PureComponent<IProps, IState> {
  public state = {
    fontSize: 22,
    className: '',
    whiteSpace: 'nowrap', // 折返しなしのテキスト幅を取得するため
    visibility: 'hidden' // 画面のちらつきを防ぐため処理完了まで非表示
  };

  private ref = React.createRef<HTMLInputElement>();

  public componentDidMount() {
    // マウント直後だとfontの読み込みが完了しておらず
    // ブラウザ標準のfontでテキスト幅を計測してしまうため
    // document.fonts.readyでfontの読み込み完了を待つ
    (document as any).fonts.ready.then(() => {
      this.textAdjust();
    });
  }

  public componentDidUpdate() {
    this.textAdjust();
  }

  public render() {
    const { text } = this.props;
    const { fontSize, className, whiteSpace, visibility } = this.state;
    const style = {
      fontSize: `${fontSize}px`,
      whiteSpace,
      visibility,
    } as React.CSSProperties;

    return (
      <div className={className}>
        <span ref={this.ref} style={style}>{text}</span>
      </div>
    );
  }

  private textAdjust() {
    const minFontSize = 16;
    const displayWidth = 480;

    const name = this.ref.current;

    // BCAT-3910
    // 文字縮退処理が完了する前に別画面へ遷移した場合に
    // nameが取得できずエラーが発生するケースがあるのでケア
    if (!name) { return; }

    // プレビュー画面非表示だとwidthが取得できないので一時的にdisplay:'block'を追加
    const preview = document.getElementById('preview');
    let originalDisplay = '';
    if (preview) {
      originalDisplay = preview.style.display;
      preview.style.display = 'block';
    }

    const width = name.getBoundingClientRect().width;
    if (width > displayWidth) {
      if (this.state.fontSize === minFontSize) {
        this.setState({
          className: 'word-ellipsis',
          whiteSpace: '',
          visibility: ''
        });
      } else {
        this.setState({ fontSize: this.state.fontSize - 1 });
      }
    } else {
      this.setState({
        whiteSpace: '',
        visibility: ''
      });
    }

    if (preview) {
      preview.style.display = originalDisplay;
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import I18n from '../../lib/i18n';
import { ExclusiveCheckBoxes, CheckBox } from '../../models';

export default class DataTreeSelector extends React.PureComponent {
  static propTypes = {
    directories: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
    checkedId: PropTypes.number,
  }

  constructor(props) {
    super(props);
    const deliverables = props.directories.map(dir => dir.deliverables).reduce((acc, d) => acc.concat(d));
    const checkboxes = new List(deliverables).map(
      deliverable => new CheckBox({ id: deliverable.id, name: deliverable.summary, checked: deliverable.id === props.checkedId })
    );
    this.state = { checkboxes: new ExclusiveCheckBoxes({ checkboxes }) };
  }

  onCheck(id) {
    this.setState({ checkboxes: this.state.checkboxes.check(id) });
  }

  renderCheckboxes(ids) {
    return this.state.checkboxes.getItems(ids).map(
      checkbox => (
        <div key={checkbox.id}>
          <label className="control-label checkbox__item" htmlFor={this.controlId}>
            <input type="checkbox" checked={checkbox.checked} onChange={() => this.onCheck(checkbox.id)} />
            {checkbox.name}
          </label>
        </div>
      )
    );
  }

  render() {
    const { directories, hasError } = this.props;
    const rootClass = hasError ? 'form-group has-error' : 'form-group';
    return (
      <div className={rootClass}>
        <input name="aging[base_deliverable_id]" defaultValue={this.state.checkboxes.getCheckedId()} type="hidden" />
        <label className="control-label" htmlFor="base_deliverable_id">
          <span title="required">*</span>
          {I18n.t('activerecord.attributes.aging.base_deliverable_id')}
        </label>
        { directories.map(
          directory => (
            <div key={directory.id}>
              {directory.name}
              {this.renderCheckboxes(directory.deliverables.map(d => d.id))}
            </div>
          ))
        }
        <span className="help-block">
          { hasError ? 'どれかを選択してください' : null }
        </span>
      </div>
    );
  }
}

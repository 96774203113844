import { Record } from 'immutable';
import { IUnpackedFeaturedProduct } from './UnpackedFeaturedProduct';
import { IUnpackedRelatedChannel } from './UnpackedRelatedChannel';
import { IUnpackedRelatedMovie } from './UnpackedRelatedMovie';
import { IUnpackedRelatedProduct } from './UnpackedRelatedProduct';

// newsのmessagepackと同じ構造
const defaultValue: {
  version: {
    format: number,
    semantic: number,
  },
  news_id: number,
  published_at: number,
  expire_at: number,
  pickup_limit: number,
  priority: number,
  deletion_priority: number,
  age_limit: number,
  language: string,
  supported_languages: string[],
  application_ids: string[],
  display_type: string,
  topic_id: string,
  no_photography: number,
  surprise: number,
  bashotorya: number,
  movie: number,
  subject: {
    caption: number,
    text: string,
  },
  topic_name: string,
  topic_image: string,
  topic_publisher: string,
  topic_description: string,
  list_image: string,
  footer: {
    text: string,
  },
  allow_domains: string,
  more: {
    shop: {
      query: string,
      text: string,
    },
    browser: {
      url: string,
      text: string,
    },
    game: {
      application_id: string,
      application_arg: string,
      text: string,
      query: string,
    },
    system_applet: {
      type: string,
      text: string,
    },
  },
  body: {
    text: string,
    main_image_height: number,
    movie_url: string,
    main_image: string,
  },
  rating_information: Array<{
    rating_icon: string,
    contents_descriptors: string,
    interactive_elements: string,
  }>,
  related_products: IUnpackedRelatedProduct[],
  related_channels: IUnpackedRelatedChannel[],
  related_movies: IUnpackedRelatedMovie[],
  featured_products: IUnpackedFeaturedProduct[],
} = {
  version: {
    format: 0,
    semantic: 0,
  },
  news_id: 0,
  published_at: 0,
  expire_at: 0,
  pickup_limit: 0,
  priority: 0,
  deletion_priority: 0,
  age_limit: 0,
  language: '',
  supported_languages: [],
  application_ids: [],
  display_type: '',
  topic_id: '',
  no_photography: 0,
  surprise: 0,
  bashotorya: 0,
  movie: 0,
  subject: {
    caption: 0,
    text: '',
  },
  topic_name: '',
  topic_image: '',
  topic_publisher: '',
  topic_description: '',
  list_image: '',
  footer: {
    text: '',
  },
  allow_domains: '',
  more: {
    shop: {
      query: '',
      text: '',
    },
    browser: {
      url: '',
      text: '',
    },
    game: {
      application_id: '',
      application_arg: '',
      text: '',
      query: '',
    },
    system_applet: {
      type: '',
      text: '',
    },
  },
  body: {
    text: '',
    main_image_height: 0,
    movie_url: '',
    main_image: '',
  },
  rating_information: [],
  related_products: [],
  related_channels: [],
  related_movies: [],
  featured_products: [],
};

export default class UnpackedNewsV4 extends Record(defaultValue) {
  // moreがundefinedの場合、moreが勝手に
  // more = {
  //   shop: {
  //     text: undefined,
  //     query: undefined,
  //   },
  //   ...
  // }
  // という感じになって都合が悪いので、undefinedの場合は、明示的に{}とします。
  constructor(object: any = defaultValue) {
    const newObject = object;
    if (newObject.more === undefined) {
      newObject.more = {};
    }
    super(newObject);
  }

  public isCaptionDisplay() {
    return (this.subject.caption === 1);
  }
}

/*
 * handleResponse
 * --------------
 *
 * 主にredux-sagaの中でfetch apiでとってきたresponseを扱う関数
 * fetch api経由なのでレスポンスはpromiseを期待します。
 * またこの関数で扱えるレスポンスは基本的にjsonのみを想定しています。
 */
function handleResponse(promise: Promise<any>) {
  return (
    promise
      .then(response => response.json())
      .catch(error => ({ error }))
  );
}

export function get(path: string) {
  return handleResponse(fetch(path, { credentials: 'include' }));
}

export function post(path: string, body: any, csrfToken: string | undefined) {
  const requestOptions: any = {
    body,
    credentials: 'include',
    method: 'POST',
  };

  if (csrfToken) {
    requestOptions.headers = {
      'X-CSRF-Token': csrfToken,
    };
  }
  return handleResponse(fetch(path, requestOptions));
}

import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import createStore from '../store/BelongsCountry';
import BelongsCountryContainer from '../components/BelongsCountry/BelongsCountry';

const BelongsCountryApp = (props, railsContext) => {
  window.Constants = railsContext.Constants;
  const store = createStore(props);
  const reactComponent = (
    <Provider store={store}>
      <BelongsCountryContainer
        errorMessage={props.errorMessage}
      />
    </Provider>
  );
  return reactComponent;
};

ReactOnRails.register({ BelongsCountryApp });

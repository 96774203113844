import * as React from 'react';
import {
  SortableContainer,
} from 'react-sortable-hoc';

import { actions } from '../../../ducks/MessageForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import Topic from '../../../models/Topic';
import TopicList from '../../../models/TopicList';
import TopicRow from './TopicRow';

const SortableList = SortableContainer(({
  $$topics, parentFormName, draggedIndex,
}) => {
  let maxLengthStyle = $$topics.getSelectedMaxLength();
  const { maxLength } = Topic;
  maxLengthStyle = maxLengthStyle + 1 <= maxLength ? maxLengthStyle + 1 : maxLength;

  return (
    <div className="topic-list">
      {$$topics.filterSelectedWithOrder().map(({ index, topic }: { index: number, topic: Topic }) => (
        <TopicRow
          key={topic.id}
          topic={topic}
          parentFormName={parentFormName}
          maxLengthStyle={maxLengthStyle}
          draggedIndex={draggedIndex}
          index={index}
          topicIndex={index}
        />
      ))}
    </div>
  );
});

interface IProps {
  $$topics: TopicList;
  parentFormName: string;
}
interface IState {
  draggedIndex: number;
}
export default class TopicDragContainer extends React.PureComponent<IProps, IState> {
  public state = {
    draggedIndex: -1,
  };
  private dispatch = getDispatch();

  public onSortStart = ({ index }: { index: number }) => {
    this.setState({ draggedIndex: index });
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    this.dispatch(actions.sortTopics({ oldIndex, newIndex }));
    this.setState({ draggedIndex: -1 });
  }

  public render() {
    const { $$topics, parentFormName } = this.props;
    const { draggedIndex } = this.state;
    const { onSortStart, onSortEnd } = this;

    return (
      <SortableList
        {...{ $$topics, parentFormName, draggedIndex, onSortStart, onSortEnd }}
        useDragHandle={true}
      />
    );
  }
}

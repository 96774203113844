import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
// @ts-ignore
import ravenMiddleware from 'redux-raven-middleware';

import reducer from '../ducks/NewsSettingForm';
import NewsSettingFormState from '../models/NewsSettingFormState';
import { INewsSettingFormAppProps } from '../startup/NewsSettingFormApp';

export interface IRootState {
  $$formStore: NewsSettingFormState;
}
export default (props: INewsSettingFormAppProps) => {
  // reducer
  const combinedReducer = combineReducers<IRootState>({
    $$formStore: reducer,
  });

  const middlewares: Middleware[] = [ravenMiddleware((window as any).raven)];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
  }
  const devtools: any = process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;

  const store = createStore(
    combinedReducer,
    {
      $$formStore: new NewsSettingFormState(props),
    },
    compose(
      applyMiddleware(...middlewares),
      devtools,
    )
  );

  return store;
};

import { Record } from 'immutable';

const defaultValue: {
  id: number,
  contents_file_name: string,
  contents_file_size: number,
  contents_fingerprint: string,
  contents_type: string,
  errors: { [s: string]: string },
} = {
  id: 0,
  contents_file_name: '',
  contents_file_size: 0,
  contents_fingerprint: '',
  contents_type: '',
  errors: {},
};

export default class Attachment extends Record(defaultValue) {
  public isImage(): boolean {
    const regExp = /image$/;
    return regExp.test(this.contents_type);
  }

  public imageUrl(): string {
    return `/images/${this.id}`;
  }
}

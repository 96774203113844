import React from 'react';
import NewsText from './NewsText';

interface IProps {
  subject: string;
}

const Subject = (props: IProps) => (
  <NewsText text={props.subject} className="subject" />
);

export default Subject;

import * as React from 'react';
import { actions } from '../../../../ducks/MessageForm';
import { SimpleFormSelect } from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';
import RatingList from '../../../../models/RatingList';
import CopyRating from './CopyRating';
import Descriptors from './Descriptors';

interface IProps {
  parentFormName: string;
  $$messageDetail: MessageDetail;
  index: number;
  ratingList: RatingList;
  ratingSystem: string;
}
export default class HasDescriptorRatingForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleRatingChange = (e: any) => {
    const { index, ratingSystem } = this.props;
    const key = ['messageDetails', index, 'ratings', ratingSystem];
    this.dispatch(actions.updateFormStore({ key, value: e.target.value }));
  }

  public render() {
    const { index, parentFormName, $$messageDetail, ratingSystem, ratingList } = this.props;
    const rating = ratingList.getRating(ratingSystem);
    const optionValues = rating.getOptionValues();

    return (
      <div className="row">
        <div className="col-md-6">
          <SimpleFormSelect
            label={I18n.t('activerecord.attributes.message_detail.ratings')}
            name={`${parentFormName}[ratings][${ratingSystem}]`}
            required={false}
            values={optionValues}
            value={$$messageDetail.ratings.get(ratingSystem) || ''}
            includeBlank={true}
            onChange={this.handleRatingChange}
          />
        </div>
        <div className="col-md-6">
          <CopyRating {...{ $$messageDetail, ratingSystem }} />
        </div>
        <div className="col-md-12">
          <Descriptors {...{ index, parentFormName, $$messageDetail, ratingSystem }} />
        </div>
      </div>
    );
  }
}

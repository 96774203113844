import { List, Map, Record } from 'immutable';
import { intersection } from 'lodash';

import I18n from '../lib/i18n';
import { ICountry } from './Message';
import MessageDetail from './MessageDetail';
import Rating from './Rating';

const defaultValue: {
  items: Map<string, Rating>;
  targetRatings: Map<string, string>;
  channelRatings: Map<string, number>;
} = {
  items: Map(),
  targetRatings: Map(),
  channelRatings: Map(),
};

export interface IRatingSystems {
  [ratingName: string]: string;
}
export interface IRatings {
  [ratingName: string]: {
    [rating: string]: {
      id: number;
      countries: string[];
    }
  };
}

export interface IChannelRatings {
  [ratingName: string]: number;
}

export default class RatingList extends Record(defaultValue) {
  constructor(ratingSystems: IRatingSystems = {}, ratings: IRatings = {}, channelRatings: IChannelRatings = {}) {
    let items: Map<string, Rating> = Map();
    Object.keys(ratings).forEach(ratingName => {
      const rating = ratings[ratingName];
      let countries: string[] = [];
      const categories: { [category: string]: number } = {};
      Object.keys(rating).forEach(category => {
        countries = rating[category].countries;
        categories[category] = rating[category].id;
      });
      items = items.set(
        ratingName,
        new Rating({
          name: ratingName,
          countries: List(countries),
          categories: Map(categories),
        }),
      );
    });
    const convertedChannelRatings: IChannelRatings = {};
    Object.keys(channelRatings).forEach(key => {
      let newKey: string = '';
      Object.keys(ratingSystems).forEach(k => {
        if (ratingSystems[k] === key) {
          newKey = k;
        }
      });
      convertedChannelRatings[newKey] = channelRatings[key];
    });
    super({ items, targetRatings: Map(ratingSystems), channelRatings: Map(convertedChannelRatings) });
  }

  public getRating(name: string) {
    const rating = this.items.get(name);
    if (rating === undefined) {
      throw Error(`RatingList#getRating: unknown rating(${name})`);
    }
    return rating;
  }

  public getRatingSystemOption(semanticsVersion: number, countries: List<string>, messageDetail: MessageDetail) {
    const selectedRatingSystem = messageDetail.ratings.keySeq().toArray();
    const ratingSystemOption = this.targetRatings.keySeq().toArray()
      .filter((ratingSystem: string) => !selectedRatingSystem.includes(ratingSystem))
      .filter((ratingSystem: string) => MessageDetail.filterRatingSystemBySemanticsVersion(ratingSystem, semanticsVersion))
      .filter((ratingSystem: string) => MessageDetail.isSelectableRatingSystem(countries, this.getRating(ratingSystem)))
      .map((e: string) => [I18n.t(`activerecord.attributes.rating_system.name_items.${e}`), e])
      .map((option: string[]) => ({ label: option[0], value: option[1] }));
    // SMECはMX向けes-419のニュースのみ選択可能
    if (countries.includes('MX') && messageDetail.language === 'es-419') {
      return ratingSystemOption;
    }
    return ratingSystemOption.filter((rating: { label: string, value: string }) => rating.value !== 'SMEC');
  }

  public getSuggestedRatings(selectedCountries: List<ICountry>, language: string) {
    // 選択された国の中で指定された言語を利用している国だけ抽出
    const targetCountries: string[] = [];
    selectedCountries.forEach(country => {
      if (country.languages.includes(language)) {
        targetCountries.push(country.iso2);
      }
    });

    // 国と言語の情報からサジェストするレーティングを選出する
    // レーティングの対象国とニュースの対象国の積を取り、1つでも国が見つかればサジェストする
    // ただし、NONEに関してはサジェストしない
    let ratings: List<Rating> = List();
    this.items.valueSeq().forEach(rating => {
      if (rating.name === 'NONE') { return; }
      if (intersection(rating.countries.toArray(), targetCountries).length === 0) { return; }

      ratings = ratings.push(rating);
    });
    return ratings;
  }

  public isAbleToSuggest(countries: List<ICountry>, languages: List<string>, semanticsVersion: number ) {
    if (semanticsVersion < 5) { return false; }
    if (languages.includes('en-US') || languages.includes('fr-CA') || languages.includes('es-419')) { return false; }

    let enabled: boolean = false;
    this.channelRatings.keySeq().forEach(ratingName => {
      const rating = this.items.get(ratingName);
      if (rating !== undefined && intersection(rating.countries.toArray(), countries.map(c => c.iso2).toArray()).length > 0) {
        enabled = true;
      }
    });

    return enabled;
  }
}

$(document).on('turbolinks:load', function(){
  $('.side_accordionMenu.collapse').on('show.bs.collapse', function(e){
    // glyphiconのエレメント
    const toggleTarget = $(e.target.previousSibling.lastChild);

    toggleTarget.addClass('glyphicon-chevron-down');
    toggleTarget.removeClass('glyphicon-chevron-right');
  });

  $('.side_accordionMenu.collapse').on('hide.bs.collapse', function(e){
    // glyphiconのエレメント
    const toggleTarget = $(e.target.previousSibling.lastChild);

    toggleTarget.addClass('glyphicon-chevron-right');
    toggleTarget.removeClass('glyphicon-chevron-down');
  });
});

import { createAction, handleActions } from 'redux-actions';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { fromJS, List } from 'immutable';
import ReactOnRails from 'react-on-rails';

import { post } from '../lib/Api';

const IMPORT_CSV_REQUESTED = 'Device/IMPORT_CSV_REQUESTED';
const IMPORT_CSV_SUCCEEDED = 'Device/IMPORT_CSV_SUCCEEDED';
const IMPORT_CSV_FAILED = 'Device/IMPORT_CSV_FAILED';
const ADD_FORM = 'Device/ADD_FORM';
const REMOVE_FORM = 'Device/REMOVE_FORM';
const UPDATE_FORM = 'Device/UPDATE_FORM';

export const actions = {
  importCsvRequested: createAction('Device/IMPORT_CSV_REQUESTED', (body) => ({ body })),
  importCsvSucceeded: createAction('Device/IMPORT_CSV_SUCCEEDED', (devices) => ({ devices })),
  importCsvFailed: createAction('Device/IMPORT_CSV_FAILED', (message) => ({ message })),
  addForm: createAction('Device/ADD_FORM'),
  removeForm: createAction('Device/REMOVE_FORM', (index) => ({ index })),
  updateForm: createAction('Device/UPDATE_FORM', (index, key, value) => ({ index, key, value })),
};

export const $$initialState = fromJS({
  requesting: false,
  errors: '',
  devices: new List(),
  import_size: null,
});

const reducer = handleActions({
  [IMPORT_CSV_REQUESTED]: ($$state) => $$state.set('requesting', true),
  [IMPORT_CSV_SUCCEEDED]: ($$state, action) => $$state.merge({
    requesting: false,
    devices: fromJS(action.payload.devices),
    errors: '',
  }),
  [IMPORT_CSV_FAILED]: ($$state, action) => $$state.merge({
    requesting: false,
    errors: action.payload.message,
  }),
  [ADD_FORM]: ($$state) => $$state.update('devices', (e) => e.push(fromJS({ name: '', serial: '', note: '' }))),
  [REMOVE_FORM]: ($$state, action) => $$state.deleteIn(['devices', action.payload.index]),
  [UPDATE_FORM]: ($$state, action) => {
    const { index, key, value } = action.payload;
    return $$state.setIn(['devices', index, key], value);
  },
}, $$initialState);

export default reducer;

function* importCsv(action) {
  // /(general_news|titles|)/xx/devices/new
  // もしくは
  // /(general_news|titles|)/xx/devices
  // から叩かれるので、このパスから
  // /(general_news|titles)/xx/devices/import_csvのパスをつくる
  const url = `${location.pathname.split('/new')[0]}/import_csv`;
  const csrfToken = ReactOnRails.authenticityToken();
  const body = action.payload.body;

  try {
    const devices = yield call(post, url, body, csrfToken);

    if (devices.error) {
      yield put(actions.importCsvFailed(devices.error));
    } else {
      yield put(actions.importCsvSucceeded(devices));
    }
  } catch (exception) {
    yield put(actions.importCsvFailed(exception.message));
  }
}

function* importCsvSaga() {
  yield takeEvery(IMPORT_CSV_REQUESTED, importCsv);
}

export function* deviceFormSaga() {
  yield fork(importCsvSaga);
}

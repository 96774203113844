import React from 'react';
import I18n from '../../../lib/i18n';

const LikeButton = () => (
  <div className="like-buttons">
    <div className="bookmark">{I18n.t('messages.show.bookmark')}</div>
    <div className="bar-line" />
    <div className="like">{I18n.t('messages.show.like')}</div>
    <div className="unlike">{I18n.t('messages.show.unlike')}</div>
  </div>
);

export default LikeButton;

import { Map, Record } from 'immutable';
import { compact } from 'lodash';

const defaultValue: {
  id?: number;
  language: string;
  comment: string;
  sale_info: string;
  demo_info: string;
  supplement: string;
  errors: Map<string, string[] | undefined>;
  validators: { [s: string]: number };
} = {
  id: undefined,
  language: '',
  comment: '',
  sale_info: '',
  demo_info: '',
  supplement: '',
  errors: Map(),
  validators: {},
};

export default class FeaturedComment extends Record(defaultValue) {
  constructor(object: any = defaultValue) {
    const newObject = object;
    if (object.errors) { newObject.errors = Map(object.errors); }
    super(newObject);
  }
  // mutations
  public updateComment(key: 'language' | 'comment' | 'sale_info' | 'demo_info' | 'supplement', value: string): this {
    return this.set(key, value).validate(key);
  }
  public validate(key: 'language' | 'comment' | 'sale_info' | 'demo_info' | 'supplement'): this {
    // FIXME: 本当はメンバ変数に長さの制限情報を持ちたかったが、FeaturedProductは様々な場所で初期化されるので苦肉の策
    // $FlowFixMe
    const maxLength = (window as any).Constants.validators.message.featuredComment[key];
    if (maxLength === undefined) { return this; }
    // 長さオーバーしているときにはnullを突っ込み、解消されたらcompactでnullだけ消す処理
    if (this.get(key, '').length > maxLength) {
      return this.updateIn(['errors', key], messages => {
        if (messages === undefined) { return ['']; }
        return messages.concat('');
      });
    }
    return this.updateIn(['errors', key], messages => compact(messages));
  }
  public getError(column: string): string[] | undefined {
    return this.errors.get(column);
  }
  public getLength(column: 'language' | 'comment' | 'sale_info' | 'demo_info' | 'supplement'): string {
    return `${this.get(column, '').length}/${(window as any).Constants.validators.message.featuredComment[column]}`;
  }
}

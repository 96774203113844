import { List, Map, Record } from 'immutable';
import { intersection } from 'lodash';
import I18n from '../lib/i18n';
import lengthValidator from '../lib/lengthValidator';
import universalNewline from '../lib/universalNewline';
import Attachment from './Attachment';
import MainContent from './MainContent';
import MoreShopQuery from './MoreShopQuery';
import Movie from './Movie';
import MovieList from './MovieList';
import Product from './Product';
import Rating from './Rating';

const defaultValue: {
  body: string,
  more_link_text: string,
  more_shop_query: MoreShopQuery,
  errors: { [s: string]: string[] },
  more_type: string,
  more_shop_text: string,
  more_system_applet_text: string,
  more_nso_text: string,
  subject: string,
  footer_text: string,
  more_application_text: string,
  more_application_ids: List<string>,
  more_application_arg: string,
  application_arg_attributes: Attachment,
  more_link_url: string,
  more_nso_relative_url: string,
  ratings: Map<string, string>,
  language: string,
  allow_domains: string,
  more_system_applet_type: string | number,
  id: number,
  show_distribution_area: boolean,
  show_nso_precaution: boolean,
  show_contents_excuse: boolean,
  contents_descriptors: Map<string, number>,
  interactive_elements: Map<string, number>,
  featured_list_name: string,
  main_movie_attributes: MovieList,
  related_movies: MovieList,
  main_image_attributes: Attachment,
  list_image_attributes: Attachment,
  movie_image_attributes: Attachment,
  main_contents_attributes: List<MainContent>,
  originalFooterTextLimit: number,
  decoration_text: string,
  validators: { [s: string]: { maximum: number } },
  _destroy: string,
} = {
  body: '',
  more_link_text: '',
  more_shop_query: new MoreShopQuery({}),
  errors: {},
  more_type: '',
  more_shop_text: '',
  more_system_applet_text: '',
  more_nso_text: '',
  subject: '',
  footer_text: '',
  more_application_text: '',
  more_application_ids: List(),
  more_application_arg: '',
  application_arg_attributes: new Attachment(),
  more_link_url: '',
  more_nso_relative_url: '',
  ratings: Map(),
  language: '',
  allow_domains: '',
  more_system_applet_type: '',
  id: 0,
  show_distribution_area: true,
  show_nso_precaution: false,
  show_contents_excuse: false,
  contents_descriptors: Map(),
  interactive_elements: Map(),
  featured_list_name: '',
  main_movie_attributes: new MovieList(),
  related_movies: new MovieList(),
  main_image_attributes: new Attachment(),
  list_image_attributes: new Attachment(),
  movie_image_attributes: new Attachment(),
  main_contents_attributes: List(),
  decoration_text: '',
  originalFooterTextLimit: 0,
  validators: {},
  _destroy: '',
};

export default class MessageDetail extends Record(defaultValue) {

  public static filterRatingSystemBySemanticsVersion(ratingSystem: string, semanticsVersion: number): boolean {
    // semanticsVersionが3以上ならCEROとESRB以外もOK
    if (semanticsVersion >= 3) { return true; }

    // semanticsVersionが2以下のとき、CEROかESRBであればOK
    if (ratingSystem === 'CERO' || ratingSystem === 'ESRB') { return true; }
    return false;
  }

  public static isSelectableRatingSystem($$countries: List<string>, rating: Rating): boolean {
    return intersection($$countries.toArray(), rating.countries.toArray()).length >= 1;
  }
  constructor(object: any) {
    const newObject = object;
    const mainImageAttributes = new Attachment(object.main_image_attributes);
    const listImageAttributes = new Attachment(object.list_image_attributes);
    const movieImageAttributes = new Attachment(object.movie_image_attributes);
    let mainContentsAttributes = null;
    if (object.main_contents_attributes) {
      mainContentsAttributes = object.main_contents_attributes.map((mainContent: any) => new MainContent(mainContent));
      newObject.main_contents_attributes = List(mainContentsAttributes);
    }
    newObject.main_movie_attributes = new MovieList ({
      movies: object.main_movie_attributes ? List([new Movie(object.main_movie_attributes)]) : List([]),
      validators: object.validators.main_movie,
      errors: object.errors.main_movie_attributes,
    });
    const applicationArgAttributes = new Attachment(object.application_arg_attributes);
    newObject.main_image_attributes = mainImageAttributes;
    newObject.list_image_attributes = listImageAttributes;
    newObject.movie_image_attributes = movieImageAttributes;
    newObject.application_arg_attributes = applicationArgAttributes;
    newObject.ratings = Map(object.ratings);
    newObject.contents_descriptors = Map(object.contents_descriptors);
    newObject.interactive_elements = Map(object.interactive_elements);
    newObject.more_shop_query = new MoreShopQuery(object.more_shop_query);
    newObject.related_movies = new MovieList({
      movies: List(object.related_movies.map((movie: any) => new Movie(movie))),
      validators: object.validators.related_movies,
      errors: object.errors.related_movies,
    });
    newObject.more_application_ids = List(object.more_application_ids);
    newObject.subject = universalNewline(object.subject);
    newObject.body = universalNewline(object.body);
    newObject.footer_text = universalNewline(object.footer_text);
    newObject.originalFooterTextLimit = object.validators.footer_text.maximum;
    super(newObject);
    this.updateFooterTextValidator();
  }

  public valid(key: string): boolean {
    if (key === 'footer_text') {
      return lengthValidator(key, this, null, {maximum: this.calcFooterTextLimit()});
    }

    return lengthValidator(key, this);
  }

  public ratingSystem() {
    return this.ratings.keySeq().toArray()[0];
  }

  public canAddRatingSystem(semanticsVersion: number): boolean {
    const ratingKeys = this.ratings.keySeq().toArray();
    // semanticsVersionが2以下のときはratingを持っていたら追加できない
    if (semanticsVersion <= 2 && ratingKeys.length >= 1) { return false; }
    // semanticsVersionが3以降のときはratingを8個持っていたら追加できない
    if (semanticsVersion >= 3 && ratingKeys.length >= 8) { return false; }
    return true;
  }

  public defaultRatingSystem(countries: List<string>): List<string> {
    switch (this.language) {
      case 'ja':
        return List(['CERO']);
      case 'en-US':
      case 'fr-CA':
        return List(['ESRB']);
      case 'es-419':
        if (countries.includes('MX')){
          return List(['ESRB', 'SMEC']);
        }
        return List(['ESRB']);
      default:
        return List([]);
    }
  }

  public defaultMoreShopText(scene: string, product?: Product) {
    const productName = product ? product.product_names[this.language] : '';
    const isPreticket = product ? Product.isPreticketDetail(product.ns_uid) : false;

    // MoreShopQuery.fromDetailSceneToSceneのどちらでも来るケースがあるので両方に対応したswitchにしておきます
    switch (scene) {
      case 'top':
        return I18n.t('nx.default_link_text.eshop.top', {locale: this.language});
      case 'sale':
        return I18n.t('nx.default_link_text.eshop.sale', {locale: this.language});
      case 'aocList':
      case 'aocs':
        return I18n.t('nx.default_link_text.eshop.aoc_list', {locale: this.language});
      case 'softDetail':
      case 'aocDetail':
      case 'bundleDetail':
      case 'product_detail':
        if (!productName) { return ''; }
        if (isPreticket) {
          return I18n.t('nx.default_link_text.eshop.preticket_detail', {locale: this.language, product_name: productName});
        }
        return I18n.t('nx.default_link_text.eshop.product_detail', {locale: this.language, product_name: productName});
      case 'list':
        return I18n.t('nx.default_link_text.eshop.product_list', {locale: this.language});
      case 'membership_service':
        return I18n.t('nx.default_link_text.eshop.membership_service', { locale: this.language});
      case 'membership_top':
        return I18n.t('nx.default_link_text.eshop.membership_top', { locale: this.language});
      default:
        return '';
    }
  }

  public setMoreShopText(defaultText: string) {
    return this.set('more_shop_text', defaultText);
  }

  public defaultMoreApplicationText() {
    return I18n.t('nx.default_link_text.game', {locale: this.language});
  }

  public setMoreApplicationText() {
    return this.set('more_application_text', this.defaultMoreApplicationText());
  }

  public setMoreNsoText(defaultText: string) {
    return this.set('more_nso_text', defaultText);
  }

  public applySuggestRatings(ratings: List<Rating>, channelRatings: Map<string, number>) {
    let newRatings: Map<string, string> = Map();
    ratings.forEach(rating => {
      const categoryId = channelRatings.get(rating.name);
      if (categoryId) {
        newRatings = newRatings.set(rating.name, categoryId.toString());
      }
    });
    return this.set('ratings', newRatings);
  }

  public updateFooterTextValidator() {
    return this.update(
      'validators',
      validators => {
        validators.footer_text.maximum = this.calcFooterTextLimit();
        return validators;
      }
    );
  }

  private calcFooterTextLimit() {
    let observerd = 0;
    if (this.show_contents_excuse) {
      observerd += I18n.t('nx.description_of_contents_excuse', {locale: this.language}).length;
    }
    if (this.show_nso_precaution) {
      observerd += I18n.t('nx.description_of_nso_precaution', {locale: this.language}).length;
    }
    if (this.show_contents_excuse && this.show_nso_precaution) {
      observerd += 1; // 改行の1文字
    }

    const ratingKey = this.ratings.valueSeq().toArray()[0];
    if (this.ratingSystem() === 'CLASSIND' && Rating.isClassindUnratedAnticipated(ratingKey)) {
      observerd += I18n.t('nx.description_of_rating_excuse', {locale: 'pt-BR'}).length;
      if (this.show_contents_excuse || this.show_nso_precaution) {
        observerd += 1; // 改行の1文字
      }
    }
    if (this.ratingSystem() === 'HK' && Rating.isHkClass2(ratingKey)) {
      observerd += I18n.t('nx.description_of_rating_excuse', {locale: 'zh-Hant'}).length;
      if (this.show_contents_excuse || this.show_nso_precaution) {
        observerd += 2; // 改行の2文字
      }
    }

    const ratingSystems = this.ratings.keySeq().toArray();
    if (ratingSystems.includes('ESRB') && ratingSystems.includes('SMEC')) {
      observerd += I18n.t('nx.description_of_rating_excuse', {locale: 'es-419'}).length;
      if (this.show_contents_excuse || this.show_nso_precaution) {
        observerd += 1; // 改行の1文字
      }
    }
    return this.originalFooterTextLimit - observerd;
  }

}

import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';

const TitleRecord = new Record({
  name: undefined,
  application_id: undefined,
});

export default class Title extends TitleRecord {
  static PropTypes = recordOf({
    name: PropTypes.string.isRequired,
    application_id: PropTypes.string.isRequired,
  })
}

import { Map, Record } from 'immutable';
import { compact } from 'lodash';

export interface INewsDescription {
  id: number;
  name: string;
  language: string;
  description: string;
  publisher_name: string;
  persisted: boolean;
  length: { [s: string]: number };
  errors: Map<string, string[] | undefined>;
}
const defaultValue: INewsDescription = {
  id: 0,
  name: '',
  language: '',
  description: '',
  publisher_name: '',
  persisted: false,
  length: {},
  errors: Map(),
};

export type newsDescriptionStringProperties = 'name' | 'language' | 'description' | 'publisher_name';

export default class NewsDescription extends Record(defaultValue) {
  constructor(object: any = defaultValue) {
    const newObject = object;
    newObject.errors = Map(object.errors);
    super(newObject);
  }
  // mutations
  public updateDescription({ key, value }: { key: keyof INewsDescription, value: any }): NewsDescription {
    return this.set(key, value).validate(key);
  }
  public validate(key: keyof INewsDescription): NewsDescription {
    const value = this.get(key);
    if (typeof value !== 'string') { return this; }
    const maxLength = this.length[key];
    if (maxLength === undefined) { return this; }
    // 長さオーバーしているときにはnullを突っ込み、解消されたらcompactでnullだけ消す処理
    // this.errors[key]がundefinedなケースがあるのでその場合は[null]を代入します
    if (value.length > maxLength) {
      return this.updateIn(['errors', key], messages => messages ? messages.concat(null) : [null]);
    }
    return this.updateIn(['errors', key], messages => compact(messages));
  }

  public isValid(): boolean {
    // validateのelse節でcompactにより[]が入るため
    return this.errors.reduce((sum, v) => sum + (v || []).length, 0) === 0;
  }
  public getError(column: string): string[] | undefined {
    return this.errors.get(column);
  }
  public getLength(column: newsDescriptionStringProperties): string {
    return `${this.get(column).length}/${this.length[column]}`;
  }
}

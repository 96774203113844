import * as React from 'react';
import { actions } from '../../../ducks/MessageForm';
import {
  SimpleFormCheckbox,
  SimpleFormDateTime,
  SimpleFormHidden,
  SimpleFormLabel,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import Message, { optionsForEndTime } from '../../../models/Message';

interface IProps {
  $$message: Message;
}

interface IState {
  essentialPickup: boolean;
}

export default class EssentialPickupForm extends React.PureComponent<IProps, IState> {
  private dispatch = getDispatch();

  constructor(props: IProps) {
    super(props);
    const essentialPickup = !!props.$$message.essential_priority;
    this.state = { essentialPickup };
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // name = message[summary]から
    // key = ["message", "summary"]を作る必要がある
    const key = e.target.name.replace(/]/g, '').split('[');
    const value = e.target.value;
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.validateForm({ key }));
  }

  public toggleEssentialPickup = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ essentialPickup: !this.state.essentialPickup });

    // 必須ピックアップを解除された場合は、必須ピックアップ期限をリセット
    if (!e.target.checked) {
      this.dispatch(actions.clearEssentialPickupLimit());
    }
  }

  public toggleFixedPickup = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.dispatch(actions.updateFormStore({
      key: ['message', 'essential_priority'],
      value: e.target.checked ? 2000 : 1000,
    }));
  }

  public toggleDecorationType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.dispatch(actions.updateFormStore({
      key: ['message', 'decoration_type'],
      value: e.target.checked ? 'simple' : null,
    }));
  }

  public fixedPickupForm = () => {
    const { $$message } = this.props;
    const { essentialPickup } = this.state;

    if ($$message.semantics_version >= 6 && essentialPickup) {
      return(
        <SimpleFormLabel
          label={I18n.t('activerecord.attributes.message.fixed_pickup_flag')}
        >
          <SimpleFormCheckbox
            controlId="fixed_pickup_control"
            label={I18n.t('messages.form.pickup_function_on')}
            checked={$$message.isFixedPickuped()}
            onChange={this.toggleFixedPickup}
          />
          <SimpleFormHidden
            name="message[essential_priority]"
            value={ $$message.essential_priority || 1000 }
          />
        </SimpleFormLabel>
      );
    }

    return null;
  }

  public decorationTypeForm = () => {
    const { $$message } = this.props;

    if ($$message.semantics_version >= 7 ) {
      return(
        <SimpleFormLabel
          label={I18n.t('activerecord.attributes.message.decoration_type')}
        >
          <SimpleFormCheckbox
            name="message[decoration_type]"
            label={I18n.t('messages.form.decoration_on')}
            checked={$$message.decoration_type === 'simple'}
            onChange={this.toggleDecorationType}
          />
          <SimpleFormHidden
            name="message[decoration_type]"
            value={ $$message.decoration_type || '' }
          />
        </SimpleFormLabel>
      );
    }

    return null;
  }

  public essentialPickupForm = () => {
    const { $$message } = this.props;
    const { essentialPickup } = this.state;

    if (!essentialPickup) {
      return null;
    }

    return(
      <>
        <SimpleFormDateTime
          name="message[essential_pickup_limit]"
          label={I18n.t('activerecord.attributes.message.essential_pickup_limit')}
          value={$$message.essential_pickup_limit || ''}
          errorMessage={$$message.errors.essential_pickup_limit}
          popoverText={I18n.t('messages.tooltips.essential_pickup_limit') + I18n.t('messages.hints.essential_pickup_limit_should_be_up_to_1week')}
          options={optionsForEndTime}
          onChange={this.handleChange}
        />
        { this.fixedPickupForm() }
        { this.decorationTypeForm() }
      </>
    );
  }

  public render = () => {
    const { $$message } = this.props;
    const { essentialPickup } = this.state;

    if ($$message.semantics_version < 3) {
      return null;
    }

    return (
      <div className="flex_column">
        <SimpleFormLabel
          label={I18n.t('activerecord.attributes.message.essential_pickup_flag')}
        >
          <SimpleFormCheckbox
            controlId="essential_pickup_control"
            label={I18n.t('messages.form.pickup_function_on')}
            defaultChecked={essentialPickup}
            onChange={this.toggleEssentialPickup}
          />
        </SimpleFormLabel>

        { this.essentialPickupForm() }
      </div>
    );
  }
}

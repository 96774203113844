import * as React from 'react';
import { actions } from '../../../ducks/MessageForm';
import {
  SimpleFormCheckbox,
  SimpleFormDateTime,
  SimpleFormHidden,
  SimpleFormLabel,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import Message, { optionsForEndTime } from '../../../models/Message';
import EssentialPickupForm from './EssentialPickupForm';

interface IProps {
  $$message: Message;
}

interface IState {
  pickup: boolean;
}

export default class PickupForm extends React.PureComponent<IProps, IState> {
  private dispatch = getDispatch();

  constructor(props: IProps) {
    super(props);
    const priority = props.$$message.priority;
    const lowPriority = (window as any).Constants.message.priorities.LOW;
    this.state = { pickup: (priority !== lowPriority) };
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // name = message[summary]から
    // key = ["message", "summary"]を作る必要がある
    const key = e.target.name.replace(/]/g, '').split('[');
    const value = e.target.value;
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.validateForm({ key }));
  }

  public togglePickup = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pickup = e.target.checked;
    if (pickup) {
      this.dispatch(actions.setDefaultPickupLimit());
    }
    else {
      this.dispatch(actions.clearPickupLimit());
      this.dispatch(actions.clearEssentialPickupLimit());
    }

    this.setState({pickup});
  }

  public pickupForm = () => {
    const { $$message } = this.props;

    if (!this.state.pickup) {
      return null;
    }

    return(
      <>
        <SimpleFormDateTime
          name="message[pickup_limit]"
          label={I18n.t('activerecord.attributes.message.pickup_limit')}
          value={$$message.pickup_limit || ''}
          errorMessage={$$message.errors.pickup_limit}
          popoverText={I18n.t('messages.tooltips.pickup_limit')}
          options={optionsForEndTime}
          onChange={this.handleChange}
        />
        { this.hiddenMiddlePriority() }
      </>
    );
  }

  public hiddenNoPickupForm = () => {
    const lowPriority = `${(window as any).Constants.message.priorities.LOW}`;
    return(
      <div>
        <SimpleFormHidden
          name="message[priority]"
          value={lowPriority}
        />
        <SimpleFormHidden
          name="message[pickup_limit]"
          value=""
        />
        <SimpleFormHidden
          name="message[essential_priority]"
          value=""
        />
        <SimpleFormHidden
          name="message[essential_pickup_limit]"
          value=""
        />
        <SimpleFormHidden
          name="message[decoration_type]"
          value=""
        />
      </div>
    );
  }

  public hiddenMiddlePriority = () => {
    const middlePriority = `${(window as any).Constants.message.priorities.MIDDLE}`;
    return(
      <SimpleFormHidden
        name="message[priority]"
        value={middlePriority}
      />
    );
  }

  public essentialPickupForm = () => {
    const { $$message } = this.props;
    if (this.state.pickup) {
      return <EssentialPickupForm $$message={$$message} />;
    }

    return null;
  }

  public render() {
    const { $$message } = this.props;

    if (!$$message.hasNxNewsLevel()) {
      return this.hiddenMiddlePriority();
    }

    return(
      <div>
        <div className="flex_column">
          {/* pickupしない場合の値をデフォルトの値として、ピックアップ関係の設定があった場合はそれらを上書きする形で適用する */}
          { this.hiddenNoPickupForm() }

          <SimpleFormLabel
            label={I18n.t('activerecord.attributes.message.pickup_flag')}
          >
            <SimpleFormCheckbox
              label={I18n.t('messages.form.pickup_function_on')}
              checked={this.state.pickup}
              onChange={this.togglePickup}
            />
          </SimpleFormLabel>

          { this.pickupForm() }
        </div>

        { this.essentialPickupForm() }
      </div>
    );
  }
}

import React from 'react';

import I18n from '../../../lib/i18n';
import { IUnpackedRelatedChannel } from '../../../models/MessagePreview/UnpackedRelatedChannel';
import RelatedChannel from './RelatedChannel';

interface IProps {
  relatedChannels: IUnpackedRelatedChannel[];
}

const RelatedChannelList = (props: IProps) => {
  const { relatedChannels } = props;

  if (relatedChannels && relatedChannels.length !== 0) {
    const relatedChannelNodes = relatedChannels.map((relatedChannel, index) => (
      <RelatedChannel relatedChannel={relatedChannel} key={index} />
    ));

    return (
      <div className="related-channels">
        <div className="related-channels-title">
          <i className="nxicon nxicon-news-list" />
          {I18n.t('activerecord.attributes.message.related_topics')}
        </div>
        <div>
          {relatedChannelNodes}
        </div>
      </div>
    );
  }

  return null;
};

export default RelatedChannelList;

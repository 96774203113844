import { List, Map, Record } from 'immutable';
import I18n from '../lib/i18n';
import { ITopicRecord } from './Topic';

import { difference } from 'lodash';

interface IRecord {
  id?: number;
  available_countries?: string[];
  available_languages?: string[];
  has_aocs?: boolean;
  has_tickets?: boolean;
  has_consumables?: boolean;
  name?: string;
  ns_uid: string;
  initial_code?: string;
  oldest_release_at?: string;
  images?: { [s: string]: number };
  product_names: { [s: string]: string };
  related_channels?: ITopicRecord[];
  errors?: Map<string, string>;
  valid: boolean;
}
const defaultValue: IRecord = {
  id: 0,
  available_countries: [],
  available_languages: [],
  has_aocs: false,
  has_tickets: false,
  has_consumables: false,
  name: '',
  ns_uid: '',
  initial_code: '',
  oldest_release_at: '',
  images: {},
  product_names: {},
  related_channels: [],
  errors: Map(),
  valid: true,
};

export default class Product extends Record(defaultValue) {
  public static maxLength = 80;

  // convinience read methods
  public static aocs(product: Product): boolean {
    if (product.has_aocs) { return true; }
    return false;
  }

  public static isSoftDetail(nsUid: string): boolean {
    return (nsUid === undefined) ? false : nsUid.startsWith('7001');
  }

  public static isAocDetail(nsUid: string): boolean {
    return (nsUid === undefined) ? false : nsUid.startsWith('7005');
  }

  public static isBundleDetail(nsUid: string): boolean {
    return (nsUid === undefined) ? false : nsUid.startsWith('7007');
  }

  public static isPreticketDetail(nsUid: string): boolean {
    return (nsUid === undefined) ? false : nsUid.startsWith('7002');
  }

  public static buildCountrySearchQuery(countries: string[]): string {
    return countries.map((country: string) => `q[country_products_country_in][]=${country}`).join('&');
  }
  constructor(product: IRecord) {
    const { ...another } = product;
    const errors = product.errors === undefined ? {} : product.errors;
    super({
      errors: Map(errors),
      ...another,
    });
  }

  public availableName(cut: boolean = false, length: number = 60): string {
    if (!this.valid) { return `${this.ns_uid}: ${I18n.t('products.index.invalid_product')}`; }
    const availableLanguages = this.available_languages === undefined ? '' : this.available_languages.join(',');
    const name = `[${this.initial_code}(${this.ns_uid})] ${this.name}(${availableLanguages})`;
    if (cut) {
      return name.length > length ? `${name.slice(0, length - 1)}…` : name;
    }
    return name;
  }

  public toSelectOption(): { label: string, value: string, names?: { [s: string]: string } } {
    return {
      label: this.availableName(true),
      value: this.ns_uid,
      names: this.product_names,
    };
  }

  public usableForFeatured(editableLanguages: List<string>): boolean {
    if (Product.isAocDetail(this.ns_uid)) {
      return editableLanguages.filter(lang => this.images === undefined || this.images[lang] === undefined).size === 0;
    }
    return true;
  }

  public isValidAvailableCountries(countries: string[]): boolean {
    const availableCountries = this.available_countries || [];
    return difference(countries, availableCountries).length === 0;
  }
}

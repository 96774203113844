import React from 'react';

import I18n from '../../../lib/i18n';
import ListImage from './ListImage';
import NewsText from './NewsText';

interface IProps {
  listImage: string;
  text: string;
}

const NewsListItemSearched = (props: IProps) => {
  const { listImage, text } = props;
  return (
    <div>
      <h4>{I18n.t('messages.preview.news_list_item_searched')}</h4>
      <div className="news-list-item-searched list-image-preview">
        <ListImage listImage={listImage} />
        <NewsText className="news-list-item-searched-text" text={text}/>
      </div>
    </div>
  );
};

export default NewsListItemSearched;
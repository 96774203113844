import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Country } from '../../models';

export default class CountryRow extends PureComponent {
  static propTypes = {
    initial: PropTypes.string.isRequired,
    country: Country.PropTypes.isRequired,
    countriesInitial: PropTypes.instanceOf(List).isRequired,
    countryCode: PropTypes.string.isRequired,
    updateSelectedCountry: PropTypes.func.isRequired,
  }

  handleChange(countryCode) {
    const { updateSelectedCountry } = this.props;
    updateSelectedCountry(countryCode);
  }

  renderInitial(nameInitial) {
    const { countriesInitial } = this.props;
    if (countriesInitial.indexOf(nameInitial) < 0) return null;
    return (<dt id={nameInitial}>{nameInitial}</dt>);
  }

  render() {
    const { country, initial, countryCode } = this.props;
    const idStr = (`000${country.order}`).substr(-2);
    return (
      <div>
        {initial !== country.initial ? this.renderInitial(country.initial) : null}
        <dd>
          <div className="checkbox">
            <label
              htmlFor={`sortSelect${idStr}`}
              className="checkbox__item"
              id={`label_country_group_countries_${countryCode.toLowerCase()}`}
              onClick={() => this.handleChange(countryCode)}
            >
              <input
                type="checkbox"
                name="country_group[countries][]"
                value={countryCode}
                checked={country.get('checked')}
                id={`country_group_countries_${countryCode.toLowerCase()}`}
                readOnly
              />
              <span>{`${country.get('name')} (${country.get('region')})`}</span>
            </label>
          </div>
        </dd>
      </div>
    );
  }
}

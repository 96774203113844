import * as React from 'react';
import Attachment from '../../models/Attachment';
import I18n from '../i18n';
import {
  SimpleFormCheckbox,
  SimpleFormFile,
  SimpleFormFileDescription,
  SimpleFormHidden,
  SimpleFormLabel,
} from './SimpleForm';

interface IProps {
  label: string;
  required: boolean;
  disabled: boolean;
  allowDestroy: boolean;
  attachmentName: string;
  attachmentType: string;
  helpMessage: string;
  errorMessage: string[];
  popoverText: string;
  onChange?: (e: any) => void;
  $$attachment: Attachment;
}

interface IState {
  uploadedWillDestroy: boolean;
}

export default class AttachmentForm extends React.PureComponent<IProps, IState> {
  public static defaultProps = {
    allowDestroy: true,
    attachmentName: '',
    attachmentType: '',
    disabled: false,
    errorMessage: [],
    helpMessage: '',
    popoverText: '',
    required: false,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      uploadedWillDestroy: false
    };
  }

  public bodyComponent() {
    const {
      label,
      required,
      disabled,
      allowDestroy,
      attachmentName,
      attachmentType,
      helpMessage,
      errorMessage,
      popoverText,
      onChange,
      $$attachment,
    } = this.props;

    let mergedErrorMessage: any[] = [];

    const onChangeDestroyCheckbox = (e) => {
      this.setState({ uploadedWillDestroy: e.target.checked });
    };

    if (errorMessage) {
      mergedErrorMessage = mergedErrorMessage.concat(errorMessage);
    }

    if ($$attachment.errors.contents) {
      mergedErrorMessage = mergedErrorMessage.concat($$attachment.errors.contents);
    }

    if ($$attachment && $$attachment.id) {
      // 添付ファイルが正しく登録されて、DBに永続化されている場合
      return (
        <div>
          { this.state.uploadedWillDestroy ?
            <SimpleFormLabel
              required={required}
              label={label}
              popoverText={popoverText}
            >
              <></>
            </SimpleFormLabel> :
            <SimpleFormFile
              name={`${attachmentName}[contents]`}
              required={required}
              label={label}
              disabled={disabled}
              errorMessage={mergedErrorMessage}
              helpMessage={helpMessage}
              popoverText={popoverText}
              onChange={onChange}
            />
          }
          <SimpleFormFileDescription
            src={$$attachment.isImage() ? $$attachment.imageUrl() : ''}
            contentsFileName={$$attachment.contents_file_name}
            contentsFileSize={$$attachment.contents_file_size.toString()}
            contentsFingerprint={$$attachment.contents_fingerprint}
          />
          { allowDestroy ? <SimpleFormCheckbox
            name={`${attachmentName}[_destroy]`}
            label={I18n.t('common.action.destroy_uploaded')}
            checked={this.state.uploadedWillDestroy}
            onChange={onChangeDestroyCheckbox}
          /> : null }
          <SimpleFormHidden
            name={`${attachmentName}[id]`}
            defaultValue={$$attachment.id.toString()}
          />
          <SimpleFormHidden
            name={`${attachmentName}[contents_type]`}
            defaultValue={attachmentType}
          />
        </div>
      );
    } else if ($$attachment) {
      // 添付ファイルがバリデーションエラーで引っかかった場合
      return (
        <div>
          <SimpleFormFile
            name={`${attachmentName}[contents]`}
            required={required}
            disabled={disabled}
            label={label}
            errorMessage={mergedErrorMessage}
            helpMessage={helpMessage}
            popoverText={popoverText}
            onChange={onChange}
          />
          <SimpleFormHidden
            name={`${attachmentName}[contents_type]`}
            defaultValue={attachmentType}
          />
        </div>
      );
    }

    // 添付ファイルがない状態
    return (
      <div>
        <SimpleFormFile
          name={`${attachmentName}[contents]`}
          required={required}
          disabled={disabled}
          label={label}
          helpMessage={helpMessage}
          popoverText={popoverText}
          onChange={onChange}
        />
        <SimpleFormHidden
          name={`${attachmentName}[contents_type]`}
          defaultValue={attachmentType}
        />
      </div>
    );
  }

  public render() {
    return (this.bodyComponent());
  }
}

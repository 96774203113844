import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import CountryRow from './CountryRow';
import SelectedCountries from './SelectedCountries';

export default class BelongsCountryWidget extends React.PureComponent {
  static propTypes = {
    updateSelectedCountry: PropTypes.func.isRequired,
    countries: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
    countriesInitial: PropTypes.instanceOf(Immutable.List).isRequired,
  }

  renderCountries() {
    const { countries, countriesInitial, updateSelectedCountry } = this.props;
    let initial = '';

    return countries.entrySeq().map(([countryCode, country]) => {
      const dom = (
        <CountryRow
          key={countryCode}
          {...{ country, countryCode, countriesInitial, updateSelectedCountry, initial }}
        />
      );
      if (initial !== country.initial) initial = country.initial;
      return dom;
    });
  }

  render() {
    const { countries } = this.props;
    return (
      <div className="form-group">
        <div className="clearfix sortSelect">
          <div className="pull-left">
            <dl className="sortSelect__list">{this.renderCountries()}</dl>
          </div>
          <div className="pull-left sortSelect__icon">
            <i className="glyphicon glyphicon-arrow-right" />
          </div>
          <SelectedCountries countries={countries} />
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import Select, { Option } from 'react-select';

import { actions } from '../../../ducks/MessageForm';
import GlyphPopover from '../../../lib/components/GlyphPopover';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import SuggestVideoList from '../../../models/SuggestVideoList';
import AvailableLanguagesVideo from './AvailableLanguagesVideo';

interface IProps {
  suggestVideoList: SuggestVideoList;
  timezone: string;
}

export default class SelectSuggestVideoList extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleVideoChange = (e: Option<string>) => {
    const videoIndex = e.value;
    if (videoIndex === undefined) { return; }
    this.dispatch(actions.updateSuggestVideoList({ key: 'selectedVideoIndex', value: videoIndex }));
  }

  public render() {
    const { suggestVideoList, timezone } = this.props;
    if (!suggestVideoList.selectedProductExists()) { return null; }

    return (
      <div>
        <label htmlFor="label-suggest-video-list" className="control-label">
          {I18n.t('message_details.form.suggest_video_list.video_list_title')}
        </label>
        <GlyphPopover
          title={I18n.t('message_details.form.suggest_video_list.video_list_title')}
          body={I18n.t('message_details.tooltips.suggest_video_list.video_list')}
        />
        <br />
        <div className="row">
          <div className="col-md-10">
            <Select
              name="select-suggest-video-list"
              options={suggestVideoList.toSelectOption(timezone)}
              value={suggestVideoList.selectedVideoIndex}
              isLoading={suggestVideoList.isFetching}
              disabled={suggestVideoList.isFetching}
              onChange={this.handleVideoChange}
              clearable={false}
              menuContainerStyle={{ position: 'relative' }}
            />
          </div>
          <AvailableLanguagesVideo {...{ suggestVideoList }} />
        </div>
      </div>
    );
  }
}

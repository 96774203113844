import PropTypes from 'prop-types';
import { Record, List } from 'immutable';
import { recordOf, list } from 'react-immutable-proptypes';
import { get } from 'lodash';

const AgingRecrod = new Record({
  start_time: undefined,
  end_time: undefined,
  interval: undefined,
  base_deliverable_id: undefined,
  errors: {},
});

class Aging extends AgingRecrod {
  static PropTypes = recordOf({
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    interval: PropTypes.number,
    base_deliverable_id: PropTypes.number,
    errors: PropTypes.object,
  })
}

const CheckBoxRecord = new Record({
  id: undefined,
  name: undefined,
  checked: false,
});

class CheckBox extends CheckBoxRecord {
  static PropTypes = recordOf({
    id: PropTypes.number,
    name: PropTypes.string,
    checked: PropTypes.bool,
  })
  check() {
    return this.set('checked', true);
  }
  uncheck() {
    return this.set('checked', false);
  }
  is(id) {
    return this.id === id;
  }
}

const ExclusiveCheckBoxesRecord = new Record({
  checkboxes: new List(),
});

class ExclusiveCheckBoxes extends ExclusiveCheckBoxesRecord {
  static PropTypes = recordOf({
    checkboxes: list.isRequired,
  })
  check(id) {
    const newList = this.checkboxes.map(
      checkbox => (checkbox.is(id) ? checkbox.check() : checkbox.uncheck())
    );
    return this.set('checkboxes', newList);
  }
  getCheckedId() {
    const checkboxOrNull = this.checkboxes.filter(
      checkbox => checkbox.checked
    ).first();
    return get(checkboxOrNull, 'id');
  }
  getItems(ids) {
    return this.checkboxes.filter(
      checkbox => ids.includes(checkbox.id)
    );
  }
}

function duplicateNum(startTime, endTime, interval) {
  let currentTime = new Date(startTime).getTime() / 1000;
  const till = new Date(endTime).getTime() / 1000;
  const delta = parseInt(interval, 10);
  let count = 0;
  while (currentTime <= till) {
    count++;
    currentTime += delta;
  }
  return count;
}


export { Aging, CheckBox, ExclusiveCheckBoxes, duplicateNum };

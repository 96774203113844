import * as React from 'react';
import { connect } from 'react-redux';

import Alert from '../../lib/components/Alert';
import { SimpleFormTab, SimpleFormTabs } from '../../lib/components/SimpleForm';
import I18n from '../../lib/i18n';
import MessageDetail from '../../models/MessageDetail';
import { IRootState } from '../../store/MessageForm';
import MessageDetailForm from './MessageDetail';

function mapStateToProps(state: IRootState) {
  return {
    $$message: state.$$formStore.message,
    $$messageDetails: state.$$formStore.messageDetails,
    $$editableLanguages: state.$$formStore.editableLanguages,
    featuredProductDetails: state.$$formStore.getFeaturedProducts(),
    byCountryGroup: state.$$formStore.byCountryGroup,
    defaultActiveLanguage: state.$$formStore.defaultActiveLanguage,
  };
}
type Props = ReturnType<typeof mapStateToProps>;
class MessageDetailListForm extends React.PureComponent<Props> {
  public messageDetailForm($$messageDetail: MessageDetail, index: number) {
    const { $$message, $$editableLanguages, featuredProductDetails, byCountryGroup } = this.props;
    const language = $$messageDetail.language;

    // formを表示するための条件
    // - editableLanguagesに含まれている言語のみを表示
    // - DBに永続化されているmessage_detailに関しても対象外の言語であれば非表示にする
    const hidden = !$$editableLanguages.includes($$messageDetail.language);
    const errorMessage = Object.keys($$messageDetail.errors).length > 0 ? 'error' : '';

    return (
      <SimpleFormTab
        key={language}
        eventKey={language}
        title={language}
        errorMessage={errorMessage}
        hidden={hidden}
      >
        <MessageDetailForm
          {...{
            $$message,
            $$messageDetail,
            featuredProductDetails,
            index,
            byCountryGroup,
            hidden,
          }}
        />
      </SimpleFormTab>
    );
  }

  public render() {
    const {
      defaultActiveLanguage,
      $$messageDetails,
    } = this.props;

    if (defaultActiveLanguage) {
      return (
        <SimpleFormTabs aClassName="no-warning" defaultActiveKey={defaultActiveLanguage}>
          {$$messageDetails.map(($$messageDetail, index) => this.messageDetailForm($$messageDetail, index)).toArray()}
        </SimpleFormTabs>
      );
    }

    return (
      <Alert
        message={I18n.t('messages.form.must_be_selected_at_least_one_country')}
        alertType="danger"
      />
    );
  }
}

export default connect(mapStateToProps)(MessageDetailListForm);

import * as React from 'react';
import { connect } from 'react-redux';

import I18n from '../../lib/i18n';
import { IRootState } from '../../store/NewsSettingForm';
import NewsSettingForm from './NewsSettingForm';

function mapStateToProps(state: IRootState) {
  return { state: state.$$formStore };
}
type Props = ReturnType<typeof mapStateToProps>;

class NewsSettingFormApp extends React.PureComponent<Props> {
  public render() {
    const { state } = this.props;

    return (
      <div>
        <NewsSettingForm state={this.props.state} />
        <div className="form-action">
          <div className="button_column">
            <div className="button_left_area">
              <input
                type="submit"
                name="commit"
                value={state.submitButtonText()}
                className="btn btn-primary"
                data-disable-with={I18n.t('common.action.sending')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default connect(mapStateToProps)(NewsSettingFormApp);

import { List } from 'immutable';
import * as React from 'react';
import Select from 'react-select';

import { actions } from '../../ducks/MessageForm';
import GlyphPopover from '../../lib/components/GlyphPopover';
import {
  SimpleFormTextarea,
} from '../../lib/components/SimpleForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import notify from '../../lib/notify';

interface IProps {
  value: List<string>;
  options: Array<{
    label: string;
    value: string;
    name: string;
  }>;
  maximumSize: number;
  hasNxNewsFormLevel?: boolean;
  errorMessage?: string[];
}
export default class SearchableApplicationIdsForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const key = ['message', 'searchable_application_ids'];
    const value = List(e.target.value.split('\n'));
    this.dispatch(actions.updateFormStore({ key, value }));
  }

  public updateSelectedApplicationId = (e: any) => {
    const { maximumSize } = this.props;

    const key = ['message', 'searchable_application_ids'];
    const newValue = List(e.split(','));

    if (newValue.size > maximumSize) {
      notify({
        title: `${I18n.t('activerecord.attributes.message_detail.searchable_application_ids')}:`,
        message: I18n.t('activerecord.errors.message_detail.searchable_application_ids_are_over_maximum_size', { maximum_size: maximumSize }),
        type: 'danger',
      });
      return;
    }

    this.dispatch(actions.updateFormStore({ key, value: newValue }));
  }

  public render() {
    const {
      value,
      options,
      maximumSize,
      hasNxNewsFormLevel,
      errorMessage,
    } = this.props;

    if (hasNxNewsFormLevel) {
      return (
        <SimpleFormTextarea
          name="message[searchable_application_ids][]"
          label={I18n.t('activerecord.attributes.message.searchable_application_ids')}
          value={value ? value.join('\n') : ''}
          errorMessage={errorMessage}
          helpMessage={I18n.t('activerecord.hints.message.searchable_application_ids')}
          popoverText={I18n.t('messages.tooltips.searchable_application_ids')}
          rows={maximumSize}
          onChange={this.handleChange}
        />
      );
    }

    return (
      <div>
        <label htmlFor="searchable-application-ids" className="control-label">
          {I18n.t('activerecord.attributes.message.searchable_application_ids')}
        </label>
        <GlyphPopover
          title={I18n.t('activerecord.attributes.message.searchable_application_ids')}
          body={I18n.t('messages.tooltips.searchable_application_ids')}
        />
        <Select
          multi={true}
          simpleValue={true}
          name="message[searchable_application_ids][]"
          value={value.toJS()}
          options={options}
          onChange={this.updateSelectedApplicationId}
          menuContainerStyle={{ zIndex: 10 }}
        />
      </div>
    );
  }
}

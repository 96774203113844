import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
Rails.start();
Turbolinks.start();

import 'app/lib/raven_client';

import 'jquery-datetimepicker';

import 'app/lib/common';
import 'app/startup/AgingFormApp';
import 'app/startup/BelongsCountryApp';
import 'app/startup/CalendarApp';
import 'app/startup/DataSizeChartApp';
import 'app/startup/DeviceFormApp';
import 'app/startup/DistributionEditorApp';
import 'app/startup/GlyphPopoverApp';
import 'app/startup/MessageFormApp';
import 'app/startup/MessagePreviewApp';
import 'app/startup/NewsSettingFormApp';
import 'app/startup/TestDeliveryFormApp';

import React from 'react';

import I18n from '../../../lib/i18n';
import { IUnpackedRelatedMovie } from '../../../models/MessagePreview/UnpackedRelatedMovie';
import RelatedMovie from './RelatedMovie';

interface IProps {
  relatedMovies: IUnpackedRelatedMovie[];
}

const RelatedMovieList = (props: IProps) => {
  const { relatedMovies } = props;

  if (relatedMovies && relatedMovies.length !== 0) {
    return (
      <div className="related-movie-list">
        <div className="related-movie-list-header">
          {I18n.t('activerecord.attributes.message_detail.related_movies')}
        </div>
        <div className="related-movie-list-body">
          {
            relatedMovies.map((relatedMovie, index) => <RelatedMovie relatedMovie={relatedMovie} key={index} />)
          }
        </div>
      </div>
    );
  }

  return null;
};

export default RelatedMovieList;

import * as React from 'react';
import HeaderSimulator from './HeaderSimulator';

interface IProps {
  headerStrings: string[];
}

export default class HeaderValidator extends React.PureComponent<IProps> {
  public render() {
    return(
      <div>
        {this.props.headerStrings.map((headerString, i) => {
          return (<HeaderSimulator key={i} {...{ headerString }}/>);
        })}
      </div>
    );
  }
}

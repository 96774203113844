import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';

const TestDataRecord = new Record({
  id: undefined,
  filename: undefined,
  virtual_file_id: undefined,
  summary: undefined,
  status: undefined,
  publishing_time: undefined,
  binary_file_size: undefined,
  selected: false,
});

export default class TestData extends TestDataRecord {
  static PropTypes = recordOf({
    id: PropTypes.number.isRequired,
    filename: PropTypes.string,
    virtual_file_id: PropTypes.number.isRequired,
    summary: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    publishing_time: PropTypes.string.isRequired,
    binary_file_size: PropTypes.number.isRequired,
    selected: PropTypes.bool,
  })
  toggle() {
    return this.set('selected', !this.selected);
  }
  select() {
    return this.set('selected', true);
  }
  unselect() {
    return this.set('selected', false);
  }
  label() {
    return this.summary;
  }
  detailLabel() {
    if (this.virtual_file_id === undefined) {
      return this.summary;
    }
    return `${this.filename} (${this.summary})`;
  }
}

import * as React from 'react';
import Select from 'react-select';

interface ISelectOption {
  label: string;
  value: string;
  name: string;
}
interface IProps {
  products: ISelectOption[];
  dstNsuid: string;
  updateShopJumpQuery: (s: string, v: string) => void;
  parentFormName: string;
}
export default class ProductSelect extends React.PureComponent<IProps> {
  public handleChange = (selectedOption: ISelectOption) => {
    if (selectedOption === undefined) { return; }
    this.props.updateShopJumpQuery('dst_nsuid', selectedOption ? selectedOption.value : '');
  }

  public render() {
    const { dstNsuid, products } = this.props;

    return (
      <Select
        name="select-product-args"
        value={dstNsuid}
        options={products}
        onChange={this.handleChange}
      />
    );
  }
}

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
// @ts-ignore
import ravenMiddleware from 'redux-raven-middleware';
import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';

import messageFormReducer, { messageFormSaga } from '../ducks/MessageForm';
import MessageFormState from '../models/MessageFormState';
import { IMessageFormAppProps } from '../startup/MessageFormApp';

export interface IRootState {
  $$formStore: MessageFormState;
}
export default (props: IMessageFormAppProps, railsContext: RailsContext) => { // eslint-disable-line
  // Redux expects to initialize the store using an Object, not an Immutable.Map
  props.timezone = railsContext.timezone;

  const initialState = {
    $$formStore: new MessageFormState(props),
  };

  // reducer
  const combinedReducer = combineReducers<IRootState>({
    $$formStore: messageFormReducer,
  });

  const sagaMiddlewares: Array<SagaMiddlewareOptions<{}>> = [];

  const middlewares =
    process.env.NODE_ENV === 'production' ?
    [ravenMiddleware((window as any).raven)] :
    [createLogger(), ravenMiddleware((window as any).raven)];

  const sagaMiddleware = createSagaMiddleware(...sagaMiddlewares);
  const devtools = process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f;
  const store = createStore(
    combinedReducer,
    initialState,
    compose(
      applyMiddleware(sagaMiddleware, ...middlewares),
      devtools,
    ),
  );

  // Sagaの起動
  sagaMiddleware.run(messageFormSaga);

  return store;
};

import { createAction, handleActions } from 'redux-actions';
import Immutable from 'immutable';

const UPDATE_SELECTED_COUNTRY = 'countryGroups/updateSelectedCountry';
const updateSelectedCountry = createAction(UPDATE_SELECTED_COUNTRY);
export const actions = {
  updateSelectedCountry,
};

const $$initialState = Immutable.fromJS({
  countries: {},
  countriesInitial: [],
});

const belongsCountryReducer = handleActions({
  [UPDATE_SELECTED_COUNTRY]: ($$state, action) => {
    const targetCountry = $$state.getIn(['countries', action.payload]);
    const newCountry = {
      [action.payload]: targetCountry.set('checked', !targetCountry.checked),
    };
    return $$state.mergeDeep({ countries: newCountry });
  },
}, $$initialState);

export default belongsCountryReducer;

import React from 'react';
import NewsText from './NewsText';

interface IProps {
  mainText: string;
  tagSupported?: boolean;
}

const MainText = (props: IProps) => {
  const { mainText, tagSupported } = props;
  if (mainText) {
    return (
      <NewsText text={ mainText.replace(/<\/header>\n/g, '</header>') } className="news-main-text" tagSupported={tagSupported} />
    );
  }

  return null;
};

export default MainText;

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import AttachmentForm from '../../../lib/components/AttachmentForm';
import { SimpleFormString, } from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import MessageDetail from '../../../models/MessageDetail';
import { IRootState } from '../../../store/MessageForm';
import MoreApplicationIdsForm from './MoreApplicationIdsForm';

function mapStateToProps(state: IRootState) {
  return {
    $$subscribeTitles: state.$$formStore.subscribeTitles,
    semanticsVersion: state.$$formStore.message.semantics_version,
  };
}
interface IProps {
  index: number;
  $$messageDetail: MessageDetail;
  hasNxNewsFormLevel?: boolean;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
class GameParametersForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // name = message[message_details_attributes][1][more_link_text]から
    // key = ["messageDetails", 1,  "more_link_text"]を作る必要がある
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.value;
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.validateForm({ key }));
  }

  public setDefaultText = () => {
    this.dispatch(actions.setMoreApplicationText(this.props.index));
  }

  public renderDefaultTextButton() {
    const { $$messageDetail } = this.props;
    if ($$messageDetail.defaultMoreApplicationText() === '') { return null; }
    return (
      <Button onClick={this.setDefaultText}>
        {I18n.t('message_details.form.set_default_text')}
      </Button>
    );
  }

  public render() {
    const { index, $$messageDetail, $$subscribeTitles, hasNxNewsFormLevel } = this.props;
    const lengths: { [s: string]: string } = {};
    Object.keys($$messageDetail.validators).forEach(key => {
      const length = $$messageDetail[key] ? $$messageDetail[key].length : 0;
      lengths[key] = `${length}/${$$messageDetail.validators[key].maximum}`;
    });

    const options = $$subscribeTitles.toJS();
    const applicationArgPopoverText = this.props.semanticsVersion >= 4 ?
      I18n.t('message_details.tooltips.application_arg_from_v4_on') : I18n.t('message_details.tooltips.application_arg');

    return (
      <fieldset>
        <MoreApplicationIdsForm
          index={index}
          options={options}
          value={$$messageDetail.more_application_ids}
          hasNxNewsFormLevel={hasNxNewsFormLevel}
          errorMessage={$$messageDetail.errors.more_application_ids}
        />
        <AttachmentForm
          label={I18n.t('activerecord.attributes.message_detail.application_arg')}
          attachmentName={`message[message_details_attributes][${index}][application_arg_attributes]`}
          attachmentType="message_application_arg"
          popoverText={applicationArgPopoverText}
          $$attachment={$$messageDetail.application_arg_attributes}
          errorMessage={$$messageDetail.errors.more_application_query}
        />
        <div className="row">
          <div className="col-md-9">
            <SimpleFormString
              name={`message[message_details_attributes][${index}][more_application_text]`}
              required={true}
              label={I18n.t('activerecord.attributes.message_detail.more_application_text')}
              value={$$messageDetail.more_application_text || ''}
              errorMessage={$$messageDetail.errors.more_application_text}
              helpMessage={lengths.more_application_text}
              popoverText={I18n.t('message_details.tooltips.more_application_text')}
              onChange={this.handleChange}
              deviceFont={true}
            />
          </div>
          <div className="col-md-3" style={{ marginTop: '30px' }}>
            { this.renderDefaultTextButton() }
          </div>
        </div>
      </fieldset>
    );
  }
}

export default connect(mapStateToProps)(GameParametersForm);

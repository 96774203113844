import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../ducks/MessageForm';
import { SimpleFormSelect } from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';

interface IProps {
  $$messageDetail: MessageDetail;
  ratingSystem: string;
}
interface IState {
  selectLanguage: string;
}
class CopyRating extends React.PureComponent<Props, IState> {
  public state = {
    selectLanguage: '',
  };
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectLanguage: e.target.value });
  }

  public handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { $$messageDetail } = this.props;
    const { selectLanguage } = this.state;
    if (this.disableClick()) { return; }
    this.dispatch(actions.copyRating({ base: selectLanguage, target: $$messageDetail.language }));
  }

  public selectableLanguages() {
    const { $$editableLanguages, $$messageDetail, $$messageDetails, ratingSystem } = this.props;
    return $$editableLanguages
      .filter(lang => lang !== $$messageDetail.language)
      .filter(lang => {
        const $$target = $$messageDetails.find($$md => $$md.language === lang);
        if ($$target === undefined) { return false; }
        return $$target.ratings.keySeq().toArray().includes(ratingSystem);
      })
      .toArray()
      .map<[string, string]>(lang => [lang, lang]);
  }

  public disableClick() {
    const { selectLanguage } = this.state;
    return !this.selectableLanguages().map(array => array[0]).includes(selectLanguage);
  }

  public render() {
    const { $$editableLanguages } = this.props;
    if ($$editableLanguages.size === 0) { return null; }
    const { selectLanguage } = this.state;

    return (
      <div className="col-md-12">
        <div className="col-md-8">
          <SimpleFormSelect
            label={I18n.t('message_details.form.copy_rating')}
            popoverText={I18n.t('message_details.tooltips.copy_rating')}
            name="copy_rating"
            values={this.selectableLanguages()}
            value={selectLanguage}
            onChange={this.handleChange}
            includeBlank={true}
          />
        </div>
        <div className="col-md-4" style={{ marginTop: '28px' }}>
          <button
            className="btn btn-primary"
            onClick={this.handleClick}
            disabled={this.disableClick()}
          >
            Copy
          </button>
        </div>
      </div>
    );
  }
}

interface IMapStateToProps {
  $$editableLanguages: List<string>;
  $$messageDetails: MessageDetail[];
}
function mapStateToProps(state: any): IMapStateToProps {
  return {
    $$editableLanguages: state.$$formStore.get('editableLanguages'),
    $$messageDetails: state.$$formStore.get('messageDetails'),
  };
}
type Props = IProps & IMapStateToProps;
export default connect(mapStateToProps, {})(CopyRating);

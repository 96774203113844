import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

import {
  SimpleFormSelect,
} from '../../lib/components/SimpleForm';
import I18n from '../../lib/i18n';
import { SelectableList } from '../../models';
import MessageSelector from './MessageSelector';
import VirtualFileSelector from './VirtualFileSelector';

export default class DataExplorer extends React.PureComponent {
  static propTypes = {
    directories: SelectableList.PropTypes.isRequired,
    selectDirectory: PropTypes.func.isRequired,
    toggleVirtualFile: PropTypes.func.isRequired,
    toggleTestData: PropTypes.func.isRequired,
    unselectTestData: PropTypes.func.isRequired,
    selectDataExclusive: PropTypes.func.isRequired,
  }
  directorySelector() {
    const { directories, selectDirectory } = this.props;
    const selectOptions = directories.map((dir) => [dir.name, dir.id]).toArray();
    return (
      <SimpleFormSelect
        label=""
        value={directories.filter(dir => dir.selected).first().id}
        values={selectOptions}
        onChange={(e) => selectDirectory(+e.target.value)}
      />
    );
  }
  dataSelector() {
    const { directories, toggleTestData, toggleVirtualFile, selectDataExclusive } = this.props;
    const selectedDirectory = directories.getSelected().first();
    if (selectedDirectory === undefined) { return null; }
    if (selectedDirectory.isMessage()) {
      return <MessageSelector directories={directories} toggleTestData={toggleTestData} />;
    }
    return (
      <VirtualFileSelector
        directories={directories}
        selectDataExclusive={selectDataExclusive}
        toggleVirtualFile={toggleVirtualFile}
      />
    );
  }
  selectedDataViewer() {
    const selectedData = this.props.directories.map(dir => {
      const dataLists = dir.deliverables.valueSeq().filter(file => file.selected).map(file =>
        (
          <li key={file.id}>
            {file.detailLabel()}
            <span
              className="sortSelect_remove"
              onClick={() => this.props.unselectTestData(file.id)}
            />
          </li>
        )
      );
      return (
        <div key={dir.id}>
          <ul className="testData_select">
            <li className="testData_selectHeading">{dir.name}</li>
          </ul>
          <ul className="testData_select">
            {dataLists}
          </ul>
        </div>
      );
    });
    return (
      <div className="testData_selectDataBox">
        <p className="bg-primary">{I18n.t('data_lists.form.selected_data')}</p>
        <div className="testData_selectDataBox_scroll">
          {selectedData}
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="testData_container">
        <div className="testData_tableBox pull-left">
          <div className="testData_searchBox row searchBox">
            <div className="testData_selectBox">
              {this.directorySelector()}
            </div>
            {/*
            <div id="testData_search_btn">
              <div className="btn btn-default testData_btn">
                <Glyphicon glyph="search" />
              </div>
            </div>
            */}
          </div>
          <div className="testData_tableScroll">
            <div className="testDataSearch">
              {/* TODO: 検索機能の実装 */}
            </div>
            {this.dataSelector()}
          </div>
        </div>
        <div className="pull-left testData_icon">
          <Glyphicon glyph="arrow-right" />
        </div>
        <div className="testData_container_right">
          {this.selectedDataViewer()}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import {
  SimpleFormCheckbox,
  SimpleFormString,
  SimpleFormTextarea,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import MessageDetail from '../../../models/MessageDetail';
import { IRootState } from '../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    $$message: state.$$formStore.message,
  };
}
interface IProps {
  index: number;
  $$messageDetail: MessageDetail;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
interface IState {
  showAllowDomainForm: boolean;
}

class BrowserParametersForm extends React.PureComponent<Props, IState> {
  private dispatch = getDispatch();

  public componentWillMount() {
    const { $$messageDetail } = this.props;
    this.setState({ showAllowDomainForm: !!$$messageDetail.allow_domains });
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // name = message[message_details_attributes][1][more_link_text]から
    // key = ["messageDetails", 1,  "more_link_text"]を作る必要がある
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.value;
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.validateForm({ key }));
  }

  public handleCheckShowDomainWhitelist = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    this.setState({ showAllowDomainForm: checked });
  }

  public renderAllowDomainForm() {
    const { index, $$messageDetail, $$message } = this.props;
    if (!$$message.hasNxNewsLevel()) { return null; }
    const dom = [];

    dom.push(
      <SimpleFormCheckbox
        key="domain-checkbox"
        name={`message[message_details_attributes][${index}][allow_domains]`}
        controlId={`message_message_details_attributes_${index}_allow_domains_show`}
        label={I18n.t('messages.form.enable_to_access_outer_domains')}
        checked={this.state.showAllowDomainForm}
        onChange={this.handleCheckShowDomainWhitelist}
        hiddenValue=""
      />
    );

    if (this.state.showAllowDomainForm) {
      dom.push(
        <SimpleFormTextarea
          key="whitelist-textbox"
          name={`message[message_details_attributes][${index}][allow_domains]`}
          label={I18n.t('activerecord.attributes.message_detail.allow_domains')}
          value={$$messageDetail.allow_domains || ''}
          errorMessage={$$messageDetail.errors.allow_domains}
          popoverText={I18n.t('message_details.tooltips.allow_domains')}
          onChange={this.handleChange}
        />
      );
    }

    return dom;
  }

  public render() {
    const { index, $$messageDetail } = this.props;
    const lengths: { [s: string]: string } = {};
    Object.keys($$messageDetail.validators).forEach(key => {
      const length = $$messageDetail[key] ? $$messageDetail[key].length : 0;
      lengths[key] = `${length}/${$$messageDetail.validators[key].maximum}`;
    });

    return (
      <fieldset>
        <SimpleFormString
          name={`message[message_details_attributes][${index}][more_link_url]`}
          required={true}
          label={I18n.t('activerecord.attributes.message_detail.more_link_url')}
          value={$$messageDetail.more_link_url || ''}
          errorMessage={$$messageDetail.errors.more_link_url}
          helpMessage={lengths.more_link_url}
          popoverText={I18n.t('message_details.tooltips.more_link_url')}
          onChange={this.handleChange}
        />
        <SimpleFormString
          name={`message[message_details_attributes][${index}][more_link_text]`}
          required={true}
          label={I18n.t('activerecord.attributes.message_detail.more_link_text')}
          value={$$messageDetail.more_link_text || ''}
          errorMessage={$$messageDetail.errors.more_link_text}
          helpMessage={lengths.more_link_text}
          popoverText={I18n.t('message_details.tooltips.more_link_text')}
          onChange={this.handleChange}
          deviceFont={true}
        />
        {this.renderAllowDomainForm()}
      </fieldset>
    );
  }
}

export default connect(mapStateToProps)(BrowserParametersForm);

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { actions } from '../../../../ducks/MessageForm';
import {
  SimpleFormHidden,
  SimpleFormSelect,
  SimpleFormString,
} from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import Message from '../../../../models/Message';
import MessageDetail from '../../../../models/MessageDetail';
import MoreShopQuery from '../../../../models/MoreShopQuery';
import Product from '../../../../models/Product';
import ProductList from '../../../../models/ProductList';
import { IRootState } from '../../../../store/MessageForm';
import ProductListForm from './ProductListForm';
import ProductSelect from './ProductSelect';
import SceneSelector from './SceneSelector';

interface IProps {
  index: number;
  $$message: Message;
  $$messageDetail: MessageDetail;
}
function mapStateToProps(state: IRootState) {
  return {
    shopProductLists: state.$$formStore.createShopProductLists(),
    featuredProductIds: state.$$formStore.featuredProductIds,
  };
}
type Props = ReturnType<typeof mapStateToProps> & IProps;

class ShopParametersForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // name = message[message_details_attributes][1][more_link_text]から
    // key = ["messageDetails", 1,  "more_link_text"]を作る必要がある
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.value;
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.validateForm({ key }));
  }

  public updateShopJumpQuery = (lastKey: string, value: string) => {
    const { index } = this.props;
    const key = ['messageDetails', index, 'more_shop_query', lastKey];
    let newValue = value;
    if (lastKey === 'scene') {
      newValue = MoreShopQuery.fromDetailSceneToScene(value);
    }
    this.dispatch(actions.updateFormStore({ key, value: newValue }));
    this.dispatch(actions.validateForm({ key }));
  }

  public renderProductSelect(parentFormName: string) {
    const { $$messageDetail, shopProductLists } = this.props;
    const { more_shop_query: $$moreShopQuery, language } = $$messageDetail;
    const { dst_nsuid: dstNsuid } = $$moreShopQuery;
    const { updateShopJumpQuery } = this;

    const products: Array<{ label: string, value: string, name: string }> = [];
    shopProductLists.get(language, new ProductList()).items.forEach((product: Product) => {
      // サジェスト候補をステートレスにするためにrender時にフィルタリングします
      // ステートレスでない場合、componentのinitialize時とupdate時にフィルタリング処理を書かなくてはいけないためです
      if ($$moreShopQuery.filteringList(product)) { return null; }
      products.push({
        label: product.availableName(),
        value: product.ns_uid,
        name: product.product_names[language],
      });
      return null;
    });

    return (
      <div className="col-md-10 shop-query-sub-form">
        <ProductSelect
          {...{ parentFormName, products, dstNsuid, updateShopJumpQuery }}
        />
        <SimpleFormHidden
          name={`${parentFormName}[dst_nsuid]`}
          value={dstNsuid || ''}
        />
      </div>
    );
  }

  public renderList(parentFormName: string) {
    const { index, $$message, $$messageDetail, shopProductLists, featuredProductIds } = this.props;
    const { list_name: listName, list_type: listType } = $$messageDetail.more_shop_query;
    const { updateShopJumpQuery, handleChange } = this;
    const products: ProductList = shopProductLists.get($$messageDetail.language, new ProductList());
    const { countries, display_type: displayType } = $$message;
    return (
      <ProductListForm
        {...{
          index,
          parentFormName,
          listName,
          listType,
          updateShopJumpQuery,
          products,
          handleChange,
          countries,
          featuredProductIds,
          displayType,
        }}
      />
    );
  }

  public handleSearchType = (key: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.updateShopJumpQuery(key, e.target.value);
  }

  public renderSearchSelect(parentFormName: string) {
    const { $$messageDetail } = this.props;
    const { more_shop_query: $$moreShopQuery } = $$messageDetail;
    const selectList: Array<[string, string]> = [
      [I18n.t('activerecord.hints.message_detail.more_shop_query.search_type.having_trial'), 'having_trial'],
    ];

    return (
      <div className="col-md-6">
        <SimpleFormSelect
          name={`${parentFormName}[search_type]`}
          label={I18n.t('activerecord.attributes.message_detail.search_type')}
          values={selectList}
          value={$$moreShopQuery.search_type}
          onChange={this.handleSearchType('search_type')}
          includeBlank={true}
          required={true}
        />
      </div>
    );
  }

  public renderSubForm($$moreShopQuery: MoreShopQuery, parentFormName: string) {
    switch ($$moreShopQuery.scene) {
      case 'list':
        return this.renderList(parentFormName);
      case 'search':
        return this.renderSearchSelect(parentFormName);
      case 'product_detail':
      case 'aocs':
      case 'subscriptions':
      case 'consumption':
        return this.renderProductSelect(parentFormName);
      default:
        return null;
    }
  }

  public setDefaultText = (defaultText: string) => () => {
    const { index } = this.props;
    this.dispatch(actions.setMoreShopText({ index, defaultText }));
  }

  public renderDefaultTextButton() {
    const { $$messageDetail, shopProductLists } = this.props;
    const { language, more_shop_query: $$moreShopQuery } = $$messageDetail;
    const { scene, dst_nsuid } = $$moreShopQuery;
    const products = shopProductLists.get(language, undefined);
    const product = dst_nsuid && products !== undefined ? products.items.get(dst_nsuid) : null;
    const defaultText = $$messageDetail.defaultMoreShopText(scene, product);

    if (defaultText === '') { return null; }
    return(
      <Button onClick={this.setDefaultText(defaultText)}>
        {I18n.t('message_details.form.set_default_text')}
      </Button>
    );
  }

  public renderQueryError(errorMessage) {
    if (errorMessage) {
      return(
        <div className="col-md-12">
          {errorMessage}
        </div>
      );
    }

    return null;
  }

  public render() {
    const { index, $$messageDetail, $$message } = this.props;
    const { updateShopJumpQuery } = this;
    const { more_shop_query: $$moreShopQuery } = $$messageDetail;
    const lengths: { [s: string]: string } = {};
    Object.keys($$messageDetail.validators).forEach(key => {
      const length = $$messageDetail[key] ? $$messageDetail[key].length : 0;
      lengths[key] = `${length}/${$$messageDetail.validators[key].maximum}`;
    });
    const parentFormName = `message[message_details_attributes][${index}][more_shop_query]`;
    const hasNxNewsLevel = $$message.hasNxNewsLevel();
    const semanticsVersion = $$message.semantics_version;

    const queryErrorMessage =
      $$messageDetail.errors.more_shop_query ?
      $$messageDetail.errors.more_shop_query.join(' ') :
      null;

    return (
      <fieldset>
        <div className={`${queryErrorMessage ? 'row alert alert-danger' : 'row'}`}>
          <SceneSelector
            {...{ $$messageDetail, $$moreShopQuery, index, parentFormName, updateShopJumpQuery, hasNxNewsLevel, semanticsVersion }}
          />
          {this.renderSubForm($$moreShopQuery, parentFormName)}
          {this.renderQueryError(queryErrorMessage)}
        </div>
        <div className="row">
          <div className="col-md-9">
            <SimpleFormString
              name={`message[message_details_attributes][${index}][more_shop_text]`}
              required={true}
              label={I18n.t('activerecord.attributes.message_detail.more_shop_text')}
              value={$$messageDetail.more_shop_text || ''}
              errorMessage={$$messageDetail.errors.more_shop_text}
              helpMessage={lengths.more_shop_text}
              popoverText={I18n.t('message_details.tooltips.more_shop_text')}
              onChange={this.handleChange}
              deviceFont={true}
            />
          </div>
          <div className="col-md-3" style={{'marginTop': '30px'}}>
            { this.renderDefaultTextButton() }
          </div>
        </div>
      </fieldset>
    );
  }
}

export default connect(mapStateToProps)(ShopParametersForm);

import React from 'react';
import Base64Image from '../../../lib/components/Base64Image';
import { IUnpackedFeaturedProduct } from '../../../models/MessagePreview/UnpackedFeaturedProduct';
import FeaturedProductName from './FeaturedProductName';
import NewsText from './NewsText';

interface IProps {
  featuredProduct: IUnpackedFeaturedProduct;
  rankingIndex?: number;
}

const FeaturedProduct = (props: IProps) => {
  const { featuredProduct, rankingIndex } = props;
  const rankingIndexNode =
    rankingIndex ?
      <div className="ranking-index">{rankingIndex}</div> :
      null;

  const priceClass =
    featuredProduct.discount_price ?
      'featured-product-price-line-through' :
      'featured-product-price';

  const saleInfo =
    featuredProduct.sale_info ?
      <div className="featured-product-label featured-product-sale-info">{featuredProduct.sale_info}</div> :
      null;

  const demoInfo =
    featuredProduct.demo_info ?
      <div className="featured-product-label featured-product-demo-info">{featuredProduct.demo_info}</div> :
      null;

  const supplement =
    featuredProduct.supplement ?
      <NewsText text={featuredProduct.supplement} className="featured-product-label featured-product-supplement" tagSupported={true} /> :
      null;

  const newLabel =
    featuredProduct.new ?
      <div className="featured-product-label featured-product-additional-label">{featuredProduct.new}</div> :
      null;

  const prePurchase =
    featuredProduct.pre_purchase ?
      <div className="featured-product-label featured-product-additional-label">{featuredProduct.pre_purchase}</div> :
      null;

  return (
    <div className="featured-product">
      <div className="featured-product-card">
        { rankingIndexNode }
        <Base64Image className="featured-product-image" image={featuredProduct.image} />
        <div className="featured-product-description">
          <div className="featured-product-content-type">
            <p>{featuredProduct.content_type}</p>
          </div>
          <div className="featured-product-name">
            <FeaturedProductName text={featuredProduct.name} />
          </div>
          <div className="featured-product-price-info">
            <p className={priceClass}>{featuredProduct.regular_price}</p>
            <p className="featured-product-discount-price">{featuredProduct.discount_price}</p>
          </div>
          <p className="featured-product-publisher">{featuredProduct.publisher}</p>
          <div className="featured-product-labels">
            {newLabel}
            {prePurchase}
            {saleInfo}
            {demoInfo}
          </div>
        </div>
      </div>
      <div className="featured-product-footer">
        <NewsText text={featuredProduct.comment} className="featured-product-comment" tagSupported={true} />
        {supplement}
      </div>
    </div>
  );
};

export default FeaturedProduct;

import React from 'react';

interface IProps {
  text: string;
  className?: string;
  tagSupported?: boolean;
}

// ニュースのテキストを扱うコンポーネント
const NewsText = (props: IProps) => {
  const {text, className, tagSupported} = props;

  if (text === '' || text === undefined) {
    return null;
  }

  if (tagSupported) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }

  return (
    <div className={className}>
      { text }
    </div>
  );
};

export default NewsText;

import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import { Calendar } from '../components/Calendar';
import rootReducer, { $$initialState, calendarSaga } from '../ducks/Calendar';
import createStore from '../store/store';

const CalendarApp = (props: {}, railsContext: RailsContext) => {
  (window as any).Constants = railsContext.Constants;

  const store = createStore(
    railsContext,
    rootReducer,
    $$initialState
      .set('timezone', railsContext.timezone)
      .set('locale', railsContext.i18nLocale),
    calendarSaga,
  );

  return (
    <Provider {...{ store }}>
      <Calendar />
    </Provider>
  );
};

ReactOnRails.register({ CalendarApp });

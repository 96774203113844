import { List } from 'immutable';
import * as React from 'react';
import Select from 'react-select';

import { actions } from '../../../ducks/MessageForm';
import GlyphPopover from '../../../lib/components/GlyphPopover';
import {
  SimpleFormTextarea,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import notify from '../../../lib/notify';

interface ISelectOption {
  label: string;
  value: string;
  name: string;
}
interface IProps {
  index: number;
  value: List<string>;
  options: ISelectOption[];
  hasNxNewsFormLevel?: boolean;
  errorMessage?: string[];
}
export default class MoreApplicationIdsFrom extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { index } = this.props;

    const key = ['messageDetails', index, 'more_application_ids'];
    const value = List(e.target.value.split('\n'));
    this.dispatch(actions.updateFormStore({ key, value }));
  }

  public updateSelectedApplicationId = (e: any) => {
    const { index } = this.props;

    const key = ['messageDetails', index, 'more_application_ids'];
    const newValue = List(e.split(','));
    const maximumSize = (window as any).Constants.validators.message.moreApplicationIdsSize;

    if (newValue.size > maximumSize) {
      notify({
        title: `${I18n.t('activerecord.attributes.message_detail.more_application_ids')}:`,
        message: I18n.t('activerecord.errors.message_detail.more_application_ids_are_over_maximum_size', { maximum_size: maximumSize }),
        type: 'danger',
      });
      return;
    }

    this.dispatch(actions.updateFormStore({ key, value: newValue }));
  }

  public formComponent() {
    const {
      index,
      value,
      options,
      hasNxNewsFormLevel,
      errorMessage,
    } = this.props;

    if (hasNxNewsFormLevel) {
      return (
        <>
          <span id={`message_message_details_attributes_${index}_more_application_ids`}/>
          <SimpleFormTextarea
            name={`message[message_details_attributes][${index}][more_application_ids][]`}
            required={true}
            label={I18n.t('activerecord.attributes.message_detail.more_application_ids')}
            value={value ? value.join('\n') : ''}
            popoverText={I18n.t('message_details.tooltips.more_application_ids')}
            errorMessage={errorMessage}
            onChange={this.handleChange}
            rows={5}
          />
        </>
      );
    }

    return (
      <div>
        <label htmlFor="more-applicatino-id" className="control-label">
          {I18n.t('activerecord.attributes.message_detail.more_applications')}
        </label>
        <GlyphPopover
          title={I18n.t('activerecord.attributes.message_detail.more_applications')}
          body={I18n.t('message_details.tooltips.more_applications')}
        />
        <span id={`message_message_details_attributes_${index}_more_application_ids`}/>
        <Select
          multi={true}
          simpleValue={true}
          name={`message[message_details_attributes][${index}][more_application_ids][]`}
          value={value.toJS()}
          options={options}
          onChange={this.updateSelectedApplicationId}
        />
      </div>
    );
  }

  public render() {
    return this.formComponent();
  }
}

import React from 'react';

const Base64Image = ({ image, className }) => {
  if (image) {
    return <img className={className} src={`data:image/jpeg;base64,${image}`} role="presentation" />;
  }
  return null;
};

export default Base64Image;

import { List, OrderedMap, Record } from 'immutable';
import { difference } from 'lodash';

import Product from './Product';

const defaultValue: {
  items: OrderedMap<string, Product>; // nsuidがキー
  selectedIds: List<string>;
  isFetching: boolean;
} = {
  items: OrderedMap(),
  selectedIds: List(),
  isFetching: true,
};

export default class ProductList extends Record(defaultValue) {
  public validProduct(product: Product, countries: string[]): boolean {
    const availableCountries = product.available_countries === undefined ? [] : product.available_countries;
    return difference(countries, availableCountries).length === 0;
  }
  public selectLimitExceeded(maxProductsLength: number): boolean {
    return this.selectedIds.size >= maxProductsLength;
  }
  public getName(nsUid: string): string {
    const product = this.items.get(nsUid);
    if (product === undefined) { return ''; }
    return product.availableName();
  }
  public getItem(id: string) {
    return this.items.get(id, new Product({ ns_uid: id, valid: false, product_names: {} }));
  }
  public filterSelected() {
    // Productが非同期にセットされるため
    if (this.items.size === 0) { return List(); }
    return this.selectedIds.map(id => this.getItem(id));
  }
  public filterSelectedWithOrder() {
    // Productが非同期にセットされるため
    if (this.items.size === 0) { return List(); }
    return this.selectedIds.map(id => Object({ index: this.selectedIds.keyOf(id), product: this.getItem(id) }));
  }
  public filterNotSelected() {
    return this.items.filter(product => !this.selectedIds.includes(product.ns_uid)).valueSeq();
  }
  public filterAvailableCountries(countries: string[]) {
    return this
      .filterNotSelected()
      .filter(product => this.validProduct(product, countries))
      .map(product => product.toSelectOption()).toJS();
  }
  public filterAvailableCountriesOnlySoftDetail(countries: string[]) {
    return this.items
      .filter(product => Product.isSoftDetail(product.ns_uid)).valueSeq()
      .filter(product => this.validProduct(product, countries))
      .map(product => product.toSelectOption()).toJS();
  }
  public getSelectedMaxLength() {
    return this.filterSelected().map(product => product.availableName().length).max();
  }
}

import * as React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';
import DistributionEditorContainer from '../components/DistributionEditor';
import createStore from '../store/DistributionEditor';

interface IParcel {
  id: number;
  contents_file_name: string;
  contents_file_size: number;
  contents_fingerprint: string;
  contents_type: string;
  errors: {};
}

export interface IDataFile {
  id: number;
  summary: string;
  status: string;
  publishing_time: string;
  expiration_time: string | null;
  binary: IParcel;
  archive: { id: number };
  available_actions: string[];
}

export interface IVirtualFile {
  id: number;
  directory_id: number;
  filename: string;
  note: string;
  warn_blank_schedule: boolean;
  can_change_filename: boolean;
  can_destroy: boolean;
  can_edit_virtual_file: boolean;
  datafiles: IDataFile[];
}

export interface IDistributionEditorProps extends IVirtualFile {
  permittedActions: string[];
  totalPages: number;
  page: number;
  selected?: number;
}

const DistributionEditorApp = (props: IDistributionEditorProps, railsContext: RailsContext) => {
  (window as any).Constants = railsContext.Constants;
  const store = createStore(props, railsContext);
  return (
    <Provider store={store}>
      <DistributionEditorContainer />
    </Provider>
  );
};

ReactOnRails.register({ DistributionEditorApp });

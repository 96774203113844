import * as React from 'react';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import MovieList from '../../../../models/MovieList';
import InputForm, { IMovieInputFormProps } from './InputForm';

const SortableItem = SortableElement(({ movie, formName, updateKey, movieIndex, allNullOrFilledNames }: IMovieInputFormProps) => (
  <InputForm {...{ movie, updateKey, movieIndex, formName, allNullOrFilledNames }} />
));

interface IProps {
  relatedMovies: MovieList;
  updateKey: string[];
  parentFormName: string;
}
const SortableList = SortableContainer(({ relatedMovies, updateKey, parentFormName }: IProps) => (
  <div className="col-md-12">
    {
      relatedMovies.movies.map((movie, index) => {
        const formName = `${parentFormName}[${index}]`;
        return (
          <SortableItem
            key={`item-${index}`}
            movieIndex={index}
            allNullOrFilledNames={relatedMovies.validateName()}
            {...{ index, movie, updateKey, formName }}
          />
        );
      })
    }
  </div>
));

export default SortableList;

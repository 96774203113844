// @ts-ignore
import Pagination from '@react-bootstrap/pagination/lib/Pagination';
import { OrderedMap } from 'immutable';
import { capitalize } from 'lodash';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import GlyphPopover from '../../lib/components/GlyphPopover';
import I18n from '../../lib/i18n';
import Attachment from '../../models/Attachment';
import Data from '../../models/DistributionFile/Data';
import { DistributionEditorProps } from './index';
import { SimpleFormCheckbox } from '../../lib/components/SimpleForm';

const StatusLabel = (props: { status: string }) => {
  const { status } = props;
  const capitalized = status.split('_').map(capitalize);
  const roleIconClassMapping = {
    draft: 'role_icon_draft',
    review_requesting: 'role_icon_review',
    tencent_censor_requesting: 'role_icon_review',
    publishing_approved: 'role_icon_approved',
    publishing: 'role_icon_publishing',
    expired: 'role_icon_expired',
  };
  const roleClass = `role_icon ${roleIconClassMapping[status]}`;
  if (capitalized.length === 2) {
    return (
      <div className={roleClass}>
        {capitalized[0]}
        <br />
        {capitalized[1]}
      </div>
    );
  }
  if (capitalized.length === 3) {
    return (
      <div className={roleClass}>
        {capitalized[1]}
        <br />
        {capitalized[2]}
      </div>
    );
  }
  return <div className={roleClass}>{capitalized[0]}</div>;
};

const ActionButton = (props: {
  published: boolean;
  action: string;
  onClick: DataRow['handleActionClick'];
}) => {
  const { published, action, onClick } = props;
  const someAction = () => onClick(action);
  switch (action) {
    case 'request_review':
    case 'approve_review':
    case 'request_tencent_censor':
    case 'approve_tencent_censor':
    case 'approve_publishing':
    case 'publish':
      return (
        <button className="btn btn-info datalistTable_btn" onClick={someAction}>
          {I18n.t(`deliverables.action.${action}`)}
        </button>
      );
    case 'cancel_review':
    case 'reject_review':
    case 'cancel_tencent_censor':
    case 'reject_tencent_censor':
    case 'unapprove':
      return (
        <button
          className="btn btn-warning datalistTable_btn"
          onClick={someAction}
        >
          {I18n.t(`deliverables.action.${action}`)}
        </button>
      );
    case 'expire': {
      const expireActionWithConfirmation = () => {
        if (confirm(I18n.t('deliverables.confirm.expire'))) {
          onClick(action);
        }
      };
      return (
        <button
          className="btn btn-danger"
          onClick={expireActionWithConfirmation}
        >
          {I18n.t('deliverables.action.expire')}
        </button>
      );
    }
    case 'destroy': {
      const warningMessage = published
        ? I18n.t('virtual_files.confirm.destroy_warning_if_published')
        : I18n.t('virtual_files.confirm.destroy_warning');
      const destroyActionWithConfirmation = () => {
        if (confirm(warningMessage)) {
          onClick(action);
        }
      };

      return (
        <button
          className="btn btn-danger datalistTable_btn"
          onClick={destroyActionWithConfirmation}
        >
          {I18n.t('common.action.destroy')}
        </button>
      );
    }
    default:
      return null;
  }
};

interface IPopoverProps extends React.Props<any> {
  binary: Attachment;
}

const BinaryInfoPopover = (props: IPopoverProps) => {
  const { binary, children } = props;
  const popoverContent = (
    <Popover id={binary.id.toString()}>
      <dl>
        <dt>{I18n.t('activerecord.attributes.parcel.contents_file_size')}</dt>
        <dd>{binary.contents_file_size}</dd>
        <dt>{I18n.t('activerecord.attributes.parcel.contents_fingerprint')}</dt>
        <dd>{binary.contents_fingerprint}</dd>
      </dl>
    </Popover>
  );
  return (
    <OverlayTrigger overlay={popoverContent} placement="top">
      {children}
    </OverlayTrigger>
  );
};

interface IDataRowProps {
  data: Data;
  canEditDatafile: boolean;
  timezone: string;
  selectData: DistributionEditorProps['selectData'];
  addPrevData: DistributionEditorProps['addPrevData'];
  addNextData: DistributionEditorProps['addNextData'];
  startEditData: DistributionEditorProps['startEditData'];
  changeStatus: DistributionEditorProps['changeStatus'];
}

class DataRow extends React.PureComponent<IDataRowProps> {
  public handleActionClick = (actionName: string) => {
    const { data } = this.props;
    this.props.changeStatus({ dataId: data.id, actionName });
  }
  public selectData = () => this.props.selectData(this.props.data.id);
  public addPrevData = () => this.props.addPrevData(this.props.data.id);
  public addNextData = () => this.props.addNextData(this.props.data.id);
  public startEditData = () => this.props.startEditData(this.props.data.id);

  public renderErrorMark(id: string, error?: string[]) {
    if (error) {
      return (
        <GlyphPopover id={id} glyph="exclamation-sign" body={error.join()} />
      );
    }
    return null;
  }

  public render() {
    const { data, canEditDatafile } = this.props;
    const { timezone } = this.props;
    const checked = data.selected;
    const pubDateTime = data.getPublishingDateTime(timezone);
    const expDateTime = data.getExpirationDateTime(timezone);
    const judge = data.hasScheduleError() ? 'ng' : 'ok';
    let relation = checked ? 'Current' : null;
    if (data.isNeighbor) {
      relation = 'Neighbor';
    }
    const rowClass = `datalistTable_${judge}${relation}`;
    return (
      <tr className={rowClass}>
        <td>
          <div className="customRadioBox">
            <label htmlFor="radio1" onClick={this.selectData}>
              <input
                type="radio"
                name="radio1"
                checked={checked}
                readOnly={true}
              />
              <span className="object" />
            </label>
          </div>
        </td>
        <td>{data.isNewData() ? null : data.id}</td>
        <td>
          <StatusLabel status={data.status} />
        </td>
        <td>
          <OverlayTrigger
            overlay={<Popover id={data.id}>{data.summary}</Popover>}
            placement="top"
          >
            <a>{data.compactSummary()}</a>
          </OverlayTrigger>
        </td>
        <td
          className={
            data.errors.publishing_time ? 'datalistTable_errorTxt' : undefined
          }
        >
          <div>{pubDateTime.date}</div>
          <div>
            {`${pubDateTime.time} ${pubDateTime.zone}`}
            &nbsp;
            {this.renderErrorMark(
              `publishing_time_${data.id}`,
              data.errors.publishing_time,
            )}
          </div>
        </td>
        <td
          className={
            data.errors.expiration_time ? 'datalistTable_errorTxt' : undefined
          }
        >
          <div>{expDateTime.date}</div>
          <div>
            {`${expDateTime.time} ${expDateTime.zone}`}
            &nbsp;
            {this.renderErrorMark(
              `expiration_time_${data.id}`,
              data.errors.expiration_time,
            )}
          </div>
        </td>
        <td>
          <BinaryInfoPopover binary={data.binary}>
            <a
              href={data.rawLink()}
              target="_blank"
              rel="noopener noreferrer"
              download={data.binary.contents_file_name}
            >
              {data.binary.contents_file_name}
              <i className="glyphicon glyphicon-download-alt" />
            </a>
          </BinaryInfoPopover>
        </td>
        <td>
          <div className="flex_column">
            <div className="dropdown datalistTable_btn">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                disabled={!canEditDatafile} // 追加はdataのstatus関係なくいつでもできてよい
              >
                {I18n.t('common.action.add')}
                <span className="caret" />
              </button>
              <ul className="dropdown-menu" role="menu">
                <li role="presentation" onClick={this.addPrevData}>
                  <a>{I18n.t('virtual_files.action.add_up')}</a>
                </li>
                <li role="presentation" onClick={this.addNextData}>
                  <a>{I18n.t('virtual_files.action.add_down')}</a>
                </li>
              </ul>
            </div>
            <button
              className="btn btn-default datalistTable_btn"
              onClick={this.startEditData}
              disabled={!data.actions.includes('update')}
            >
              {I18n.t('common.action.edit')}
            </button>
          </div>
        </td>
        <td>
          <div className="datalistTable_btnBox">
            {data.actions.map(action => (
              <ActionButton
                key={action}
                published={data.published}
                action={action}
                onClick={this.handleActionClick}
              />
            ))}
          </div>
        </td>
      </tr>
    );
  }
}

interface IDataViewProps {
  dataEntities: OrderedMap<string, Data | undefined>;
  page: number;
  totalPages: number;
  canEditDatafile: boolean;
  timezone: string;
  changePage: DistributionEditorProps['changePage'];
  selectData: DistributionEditorProps['selectData'];
  addPrevData: DistributionEditorProps['addPrevData'];
  addNextData: DistributionEditorProps['addNextData'];
  startEditData: DistributionEditorProps['startEditData'];
  changeStatus: DistributionEditorProps['changeStatus'];
  changeConditions: DistributionEditorProps['changeConditions'];
}

/* tslint:disable:max-classes-per-file */
export default class DataView extends React.PureComponent<IDataViewProps> {
  public toggleIncludeExpired = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.changeConditions(e.target.checked);
  }
  public handleChangePage = (page: number) => {
    if (page !== this.props.page) {
      this.props.changePage(page);
    }
  }
  public renderPagination() {
    const { page, totalPages } = this.props;
    return (
      <Pagination
        prev={true}
        next={true}
        first={true}
        last={true}
        ellipsis={true}
        boundaryLinks={true}
        items={totalPages}
        maxButtons={5}
        activePage={page}
        onSelect={this.handleChangePage}
      />
    );
  }
  public render() {
    const dataEntities = this.props.dataEntities.toList();
    return (
      <div>
        {this.renderPagination()}
        <SimpleFormCheckbox
          label={I18n.t('virtual_files.form.include_expired')}
          onChange={this.toggleIncludeExpired}
        />
        <table className="table table-striped datalistTable">
          <thead>
            <tr>
              <th />
              <th>ID</th>
              <th>{I18n.t('activerecord.attributes.datafile.status')}</th>
              <th>{I18n.t('activerecord.attributes.datafile.summary')}</th>
              <th className="datalistTable_date">
                {I18n.t('activerecord.attributes.datafile.publishing_time')}
              </th>
              <th className="datalistTable_date">
                {I18n.t('activerecord.attributes.datafile.expiration_time')}
              </th>
              <th>
                {I18n.t('activerecord.attributes.parcel.contents_file_name')}
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {dataEntities.map(data => {
              if (data === undefined) {
                return null;
              }
              return (
                <DataRow
                  key={data.id}
                  data={data}
                  canEditDatafile={this.props.canEditDatafile}
                  timezone={this.props.timezone}
                  selectData={this.props.selectData}
                  addPrevData={this.props.addPrevData}
                  addNextData={this.props.addNextData}
                  startEditData={this.props.startEditData}
                  changeStatus={this.props.changeStatus}
                />
              );
            })}
          </tbody>
        </table>
        {this.renderPagination()}
      </div>
    );
  }
}

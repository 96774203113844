function activatePicker() {
  /* http://xdsoft.net/jqplugins/datetimepicker/ */
  /* app/inputs/date_time_input.rb で作ったINPUTタグにjquery.datetimepickerを使用 */
  $('[data-role=datetimepicker]').datetimepicker({
    lang:       $('[data-role=nnProps]').data('lang'),
    format:     'Y-m-d H:i',
    formatTime: 'H:i',
    formatDate: 'Y-m-d'
  });

  /* http://xdsoft.net/jqplugins/datetimepicker/ */
  /* app/inputs/date_time_input.rb で作ったINPUTタグにjquery.datetimepickerを使用 */
  $('[data-role=datepicker]').datetimepicker({
    lang:       $('[data-role=nnProps]').data('lang'),
    format:     'Y-m-d',
    formatTime: 'H:i',
    timepicker: false,
    formatDate: 'Y-m-d'
  });

  /* INPUTの隣のアイコンをクリックした時にカレンダーが表示されるようにハンドラを設定 */
  $('[data-role=datetimepicker-icon]').click(function(){
    $(this)
      .parents('[data-role=datetimepicker-input-group]')
      .find('[data-role=datetimepicker], [data-role=datepicker]')
      .datetimepicker('show');
  });
}

$(document).on('turbolinks:load', function(){
  Date.parseDate = function(input, format) {
    return moment(input, format).toDate();
  };
  Date.prototype.dateFormat = function(format) {
    return moment(this).format(format);
  };
  activatePicker();
});

$(document).on('nested:fieldAdded', activatePicker);

import ActionReducer, { PayloadAction } from 'action-reducer';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { SagaCall } from 'redux-saga/saga-call';
import { changeDataStatus, createData, getData, updateData } from '../infrastructure/DataApiClient';
import DeserializeService from '../infrastructure/DeserializeService';
import SerializeService from '../infrastructure/SerializeService';
import DistributionEditorState from '../models/DistributionEditorState';
import Data from '../models/DistributionFile/Data';
import { IDistributionEditorStore } from '../store/DistributionEditor';

const SUBMIT_DATA = 'DistributionEditor/SUBMIT_DATA';
const CHANGE_STATUS = 'DistributionEditor/CHANGE_STATUS';
const CHANGE_PAGE = 'DistributionEditor/CHANGE_PAGE';
const CHANGE_CONDITIONS = 'DistributionEditor/CHANGE_CONDITIONS';

const { createAction, reducer } = ActionReducer(new DistributionEditorState());
export default reducer;

export const actions = {
  selectData:       createAction('DistributionEditor/SELECT_DATA', ($$state, payload: string) => $$state.selectData(payload)),
  addPrevData:      createAction('DistributionEditor/ADD_PREV_DATA', ($$state, payload: string) => $$state.addPrevData(payload)),
  addNextData:      createAction('DistributionEditor/ADD_NEXT_DATA', ($$state, payload: string) => $$state.addNextData(payload)),
  addLonelyData:    createAction('DistributionEditor/ADD_LONELY_DATA', $$state => $$state.addLonelyData()),
  updateData:       createAction('DistributionEditor/UPDATE_DATA', ($$state, payload: { dataId: string, key: string, value: string }) => $$state.updateData(payload)),
  submitData:       createAction(SUBMIT_DATA, $$state => $$state.startRequest()),
  setFile:          createAction('DistributionEditor/SET_FILE', ($$state, payload: { dataId: string, file: File, name: string }) => $$state.setFile(payload)),
  deleteFile:       createAction('DistributionEditor/DELETE_FILE', ($$state, payload: { dataId: string }) => $$state.deleteFile(payload)),
  startEditData:    createAction('DistributionEditor/START_EDIT_DATA', ($$state, payload: string) => $$state.startEditData(payload)),
  endEditData:      createAction('DistributionEditor/END_EDIT_DATA', $$state => $$state.endEditData()),
  changeStatus:     createAction(CHANGE_STATUS, ($$state, payload: { dataId: string, actionName: string }) => $$state.changeStatus({ dataId: payload.dataId })),
  changePage:       createAction(CHANGE_PAGE, ($$state, payload: number) => $$state.changePage(payload)),
  changeConditions: createAction(CHANGE_CONDITIONS, ($$state, payload: boolean ) => $$state.changeConditions(payload)),
};
const submitDataSucceeded = createAction('DistributionEditor/SUBMIT_DATA_SUCCEEDED',
  ($$state, payload: { datas: Data[], totalPages: number }) => $$state.successfullyEndEditData(payload)
);
const changeStatusSucceeded = createAction('DistributionEditor/CHANGE_STATUS_SUCCEEDED',
  ($$state, payload: { datas: Data[], totalPages: number }) => $$state.setLatestDataSet(payload)
);
const changePageSucceeded = createAction('DistributionEditor/CHANGE_PAGE_SUCCEEDED',
  ($$state, payload: { datas: Data[], totalPages: number }) => $$state.successfullyPageChanged(payload)
);
const changeConditionsSucceeded = createAction('DistributionEditor/CHANGE_CONDITIONS_SUCCEEDED',
  ($$state, payload: { datas: Data[], totalPages: number }) => $$state.successfullyConditionsChanged(payload)
);
const requestFailed = createAction('DistributionEditor/REQUEST_FAILED',
  ($$state, payload: { errors?: {}, message?: string }) => $$state.setErrors(payload)
);

function* submitDataAsync() {
  const state: DistributionEditorState = yield select((store: IDistributionEditorStore) => store.distributionEditor);
  if (state.editingDataId) {
    const editingData = state.file.dataEntities.get(state.editingDataId);
    if (editingData === undefined) { throw Error(); }
    const serializedData = SerializeService.serializeData(state.file, editingData, state.uploadedFile);
    const submitAction = serializedData.id === undefined ? createData : updateData;
    const { response }: SagaCall<typeof submitAction> = yield call(submitAction, serializedData, state.page, state.include_expired);
    if ('datafiles' in response) {
      const { datafiles, totalPages } = response;
      const datas = datafiles.map(DeserializeService.deserializeDatafile);
      yield put(submitDataSucceeded({ datas, totalPages }));
    } else {
      yield put(requestFailed(response));
    }
  }
}

function* changeStatusAsync(action: PayloadAction<typeof actions.changeStatus>) {
  const state: DistributionEditorState = yield select((store: IDistributionEditorStore) => store.distributionEditor);
  const page: number = state.page;
  const include_expired: boolean = state.include_expired;
  const payload = Object.assign({ page, include_expired }, action.payload);
  const { response }: SagaCall<typeof changeDataStatus> = yield call(changeDataStatus, payload);
  if ('datafiles' in response) {
    const { datafiles, totalPages } = response;
    const datas = datafiles.map(DeserializeService.deserializeDatafile);
    yield put(changeStatusSucceeded({ datas, totalPages }));
  } else {
    yield put(requestFailed(response));
  }
}

function* changePageAsync(action: PayloadAction<typeof actions.changePage>) {
  const state: DistributionEditorState = yield select((store: IDistributionEditorStore) => store.distributionEditor);
  const { response }: SagaCall<typeof getData> = yield call(getData, action.payload, state.include_expired, state.file.id);
  if ('datafiles' in response) {
    const { datafiles, totalPages } = response;
    const datas = datafiles.map(DeserializeService.deserializeDatafile);
    yield put(changePageSucceeded({ datas, totalPages }));
  } else {
    yield put(requestFailed(response));
  }
}

function* changeConditionsAsync(action: PayloadAction<typeof actions.changeConditions>) {
  const state: DistributionEditorState = yield select((store: IDistributionEditorStore) => store.distributionEditor);
  const { response }: SagaCall<typeof getData> = yield call(getData, state.page, action.payload, state.file.id);
  if ('datafiles' in response) {
    const { datafiles, totalPages} = response;
    const datas = datafiles.map(DeserializeService.deserializeDatafile);
    yield put(changeConditionsSucceeded({ datas, totalPages }));
  } else {
    yield put(requestFailed(response));
  }
}

export function* distributionEditorSaga() {
  yield takeEvery(CHANGE_STATUS, changeStatusAsync);
  yield takeEvery(CHANGE_PAGE, changePageAsync);
  yield takeEvery(SUBMIT_DATA, submitDataAsync);
  yield takeEvery(CHANGE_CONDITIONS, changeConditionsAsync);
}

import React from 'react';
import Select, { Option } from 'react-select';

import { actions } from '../../ducks/TestDeliveryForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import SelectableList from '../../models/SelectableList';

interface IProps {
  nintendoAccounts: SelectableList;
}

export default class NintendoAccountForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleNintendoAccountUpdate = (selectedOption: Option<string>) => {
    this.dispatch(actions.updateNintendoAccount(selectedOption.map((option: Option) => option.value)));
  }

  public nintendoAccountSelector() {
    const { nintendoAccounts } = this.props;
    const options = nintendoAccounts.map((na: any) => (
      { label: na.name, value: na.id }
    )).toArray();
    const value = nintendoAccounts.getSelected().toList().map((na: any) => (
      { label: na.name, value: na.id }
    )).toArray();

    return (
      <Select
        name="data_list[nintendo_account_ids][]"
        multi={true}
        value={value}
        options={options}
        onChange={this.handleNintendoAccountUpdate}
        closeOnSelect={false}
      />
    );
  }

  public render() {
    return (
      <div>
        <p className="subTitle_18px testDataCheck_title">{I18n.t('activerecord.attributes.data_list.nintendo_accounts')}</p>
        <div className="testDataCheck_checkContainer clearfix">
          { this.nintendoAccountSelector() }
        </div>
      </div>
    );
  }
}

import * as React from 'react';

import { actions } from '../../ducks/NewsSettingForm';
import Alert from '../../lib/components/Alert';
import {
  SimpleFormHidden,
  SimpleFormString,
  SimpleFormTextarea,
} from '../../lib/components/SimpleForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import NewsDescription, { newsDescriptionStringProperties } from '../../models/NewsDescription';

interface IProps {
  description: NewsDescription;
  index: number;
}
export default class NewsDescriptionForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public validationErrorNotification() {
    if (this.props.description.isValid()) { return null; }
    return (
      <Alert
        message={I18n.t('simple_form.error_notification.default_message')}
        alertType="danger"
      />
    );
  }
  public updateDescription = (key: newsDescriptionStringProperties) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const language = this.props.description.language;
    const { value } = e.target;
    this.dispatch(actions.updateDescription({ language, key, value }));
  }
  public render() {
    const { index, description } = this.props;
    return (
      <div>
        {this.validationErrorNotification()}

        <SimpleFormHidden
          name={`news_topic[news_descriptions][${index}][id]`}
          value={description.id || ''}
        />

        <SimpleFormHidden
          name={`news_topic[news_descriptions][${index}][language]`}
          value={description.language}
        />

        <SimpleFormString
          required={true}
          name={`news_topic[news_descriptions][${index}][name]`}
          label={I18n.t('activerecord.attributes.news_description.name')}
          value={description.name || ''}
          helpMessage={description.getLength('name')}
          errorMessage={description.getError('name')}
          onChange={this.updateDescription('name')}
        />

        <SimpleFormTextarea
          required={true}
          name={`news_topic[news_descriptions][${index}][description]`}
          label={I18n.t('activerecord.attributes.news_description.description')}
          value={description.description || ''}
          helpMessage={description.getLength('description')}
          errorMessage={description.getError('description')}
          onChange={this.updateDescription('description')}
        />

        <SimpleFormString
          required={true}
          name={`news_topic[news_descriptions][${index}][publisher_name]`}
          label={I18n.t('activerecord.attributes.news_description.publisher_name')}
          value={description.publisher_name || ''}
          helpMessage={description.getLength('publisher_name')}
          errorMessage={description.getError('publisher_name')}
          popoverText={I18n.t('general_news.tooltips.publisher_name')}
          onChange={this.updateDescription('publisher_name')}
        />
      </div>
    );
  }
}

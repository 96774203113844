import * as React from 'react';

import NewsDescription from 'app/models/NewsDescription';
import { List, OrderedMap } from 'immutable';
import Alert from '../../lib/components/Alert';
import { SimpleFormHidden, SimpleFormTab, SimpleFormTabs } from '../../lib/components/SimpleForm';
import I18n from '../../lib/i18n';
import NewsDescriptionForm from './NewsDescriptionForm';

interface IProps {
  descriptions: OrderedMap<string, NewsDescription>;
  editableLanguages: List<string>;
}
export default class NewsDescriptionList extends React.PureComponent<IProps> {
  public renderNewsDescriptionForm(description: NewsDescription, index: number) {
    const { language } = description;
    const errorMessage = description.isValid() ? '' : 'error';
    return (
      <SimpleFormTab
        key={language}
        eventKey={language}
        title={language}
        errorMessage={errorMessage}
      >
        <NewsDescriptionForm {...{ description, index }} />
      </SimpleFormTab>
    );
  }
  public renderDeleteDescriptions() {
    const { descriptions, editableLanguages } = this.props;
    const deleteDescriptions = descriptions.toList().filter(
      description => !editableLanguages.includes(description.language) && description.persisted
    );
    const descriptionCount = descriptions.count() + 1;
    return deleteDescriptions.map(
      (description, index) => (
        <div key={description.language}>
          <SimpleFormHidden
            name={`news_topic[news_descriptions][${index + descriptionCount}][id]`}
            value={description.id || ''}
          />
          <SimpleFormHidden
            name={`news_topic[news_descriptions][${index + descriptionCount}][_destroy]`}
            value="1"
          />
        </div>
      )
    );
  }

  public render() {
    const { descriptions, editableLanguages } = this.props;
    if (editableLanguages.count() === 0) { return null; }
    const defaultActiveLanguage = editableLanguages.first('');
    if (defaultActiveLanguage) {
      return (
        <div>
          <SimpleFormTabs aClassName="no-warning" defaultActiveKey={defaultActiveLanguage}>
            {
              descriptions.toList().filter(
                description => editableLanguages.includes(description.language)
              ).map(this.renderNewsDescriptionForm).toArray()
            }
          </SimpleFormTabs>
          { this.renderDeleteDescriptions() }
        </div>
      );
    }
    return (
      <Alert
        message={I18n.t('messages.form.must_be_selected_at_least_one_country')}
        alertType="danger"
      />
    );
  }
}

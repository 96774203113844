import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { actions } from '../../../../ducks/MessageForm';
import {
  SimpleFormLabel,
  SimpleFormSelect,
} from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';
import Rating from '../../../../models/Rating';
import { IRootState } from '../../../../store/MessageForm';
import HasDescriptorRatingForm from './HasDescriptorRatingForm';
import NoDescriptorRatingForm from './NoDescriptorRatingForm';

function mapStateToProps(state: IRootState) {
  return {
    $$countryISOs: state.$$formStore.countryISOs(),
    ratingList: state.$$formStore.ratingList,
    semanticsVersion: state.$$formStore.message.semantics_version,
  };
}
interface IProps {
  index: number;
  $$messageDetail: MessageDetail;
}
interface IState {
  ratingSystem: string;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
class RatingForm extends React.PureComponent<Props, IState> {
  public state = {
    ratingSystem: '',
  };
  private dispatch = getDispatch();

  public componentWillMount() {
    const { $$messageDetail, index, $$countryISOs, ratingList } = this.props;
    const { updateFormStore } = actions;

    if ($$messageDetail.ratingSystem() === undefined && $$messageDetail.id === null) {
      $$messageDetail.defaultRatingSystem($$countryISOs).forEach((rating: string) => {
        if (rating === '') { return; }

        const setDefaultRating = MessageDetail.isSelectableRatingSystem($$countryISOs, ratingList.getRating(rating));

        if (!setDefaultRating) { return; }
        this.dispatch(updateFormStore({ key: ['messageDetails', index, 'ratings', rating], value: undefined }));
      });
    };
  }

  public handleRatingSystemAdd = () => {
    const { index } = this.props;
    const { ratingSystem } = this.state;
    const { updateFormStore } = actions;
    if (ratingSystem !== '') {
      this.dispatch(updateFormStore({ key: ['messageDetails', index, 'ratings', ratingSystem], value: undefined }));
    }
    this.setState({ ratingSystem: '' });
  }

  public handleRatingSystemChange = (e: { label: string, value: string }) => {
    this.setState({ ratingSystem: e.value });
  }

  public handleRatingSystemDestroy = (ratingSystem: string) => () => {
    const { index, $$messageDetail } = this.props;
    const { updateFormStore } = actions;
    const { ratings } = $$messageDetail;
    this.dispatch(updateFormStore({ key: ['messageDetails', index, 'ratings'], value: ratings.delete(ratingSystem) }));
  }

  public renderRatingString(ratingSystem: string) {
    // includedBlank対策
    if (ratingSystem === '') { return null; }

    return (
      <SimpleFormSelect
        disabled={true}
        label={I18n.t('common.rating_system')}
        value={ratingSystem}
        values={[
          [I18n.t(`activerecord.attributes.rating_system.name_items.${ratingSystem}`), ratingSystem]
        ]}
        popoverText={I18n.t('message_details.tooltips.ratings')}
      />
    );
  }

  private hasDescriptorRatingForm(ratingSystem: string, ratingValue: string): boolean {
    if (ratingValue === undefined) { return false; }

    switch (ratingSystem) {
      case 'ESRB':
      case 'SMEC':
        return true;
      case 'GRB': {
        // 審査中レーティングでなければコンテンツディスクリプタのフォームを表示する
        // ニュース編集画面の初期状態ではratingValueの型がstringではなくnumberであるため、
        // stringにキャストしてから比較する
        if (String(ratingValue) !== '-1') {
          return true;
        }
        break;
      }
      case 'CLASSIND':
        // 審査中レーティングでなければコンテンツディスクリプタのフォームを表示する
        if (!Rating.isClassindUnratedAnticipated(ratingValue)) {
          return true;
        }
        break;
    }
    return false;
  }

  public renderRatingComponent(ratingSystem: string, ratingValue: string) {
    const { index, $$messageDetail, ratingList } = this.props;

    // includedBlank対策
    if (ratingSystem === '') { return null; }
    if (this.hasDescriptorRatingForm(ratingSystem, ratingValue)) {
      return (
        <HasDescriptorRatingForm
          parentFormName={`message[message_details_attributes][${index}]`}
          {...{ $$messageDetail, index, ratingList, ratingSystem }}
        />
      );
    } else {
      return (
        <NoDescriptorRatingForm
          parentFormName={`message[message_details_attributes][${index}]`}
          {...{ $$messageDetail, index, ratingList, ratingSystem }}
        />
      );
    }
  }

  public renderDestroyButton(ratingSystem: string) {
    // includedBlank対策
    if (ratingSystem === '') { return null; }

    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={this.handleRatingSystemDestroy(ratingSystem)}
      >
        {ratingSystem} {I18n.t('common.action.destroy')}
      </button>
    );
  }

  public render() {
    const { index, $$messageDetail, $$countryISOs, ratingList, semanticsVersion } = this.props;
    const ratingSystemOption = ratingList.getRatingSystemOption(semanticsVersion, $$countryISOs, $$messageDetail);

    return (
      <div className="form-group" id={`message_message_details_attributes_${index}_ratings`}>
        <SimpleFormLabel
          label={I18n.t('common.rating_system')}
          errorMessage={$$messageDetail.errors.ratings}
          popoverText={I18n.t('message_details.tooltips.ratings')}
          required={semanticsVersion >= 5}
        >
          <div className="row">
            <div className="col-md-6" id="select-rating-system">
              <Select
                name="ratings-select"
                value={this.state.ratingSystem}
                options={ratingSystemOption}
                onChange={this.handleRatingSystemChange}
                clearable={false}
              />
            </div>
            <div className="col-md-6" style={{ marginBottom: '10px' }}>
              <button
                id="add-rating-system"
                type="button"
                className="btn btn-primary"
                disabled={!$$messageDetail.canAddRatingSystem(semanticsVersion)}
                onClick={this.handleRatingSystemAdd}
              >
                {I18n.t('common.action.add')}
              </button>
            </div>
          </div>
        </SimpleFormLabel>
        {$$messageDetail.ratings.toArray().map(ratingArray => (
          <div key={ratingArray[0]} className="bs-callout bs-callout-info">
            {this.renderRatingString(ratingArray[0])}
            {this.renderRatingComponent(ratingArray[0], ratingArray[1])}
            {this.renderDestroyButton(ratingArray[0])}
          </div>
        ))}
      </div>
    );
  }
}

export default connect(mapStateToProps)(RatingForm);

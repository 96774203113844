import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';

import { SimpleFormFile } from '../../lib/components/SimpleForm';
import I18n from '../../lib/i18n';
import { actions } from '../../ducks/DeviceForm';
import DeviceRow from './DeviceRow';
import Alert from '../../lib/components/Alert';

class DeviceForm extends React.PureComponent {
  static propTypes = {
    errors: PropTypes.string,
    devices: PropTypes.instanceOf(List),
    addForm: PropTypes.func.isRequired,
    removeForm: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    importCsvRequested: PropTypes.func.isRequired,
    importSize: PropTypes.number.isRequired,
  }

  handleClick() {
    this.props.addForm();
  }

  handleUploadCsv() {
    const data = new FormData();
    data.append('device[csv]', this.fileForm.input.files[0]);
    this.fileForm.input.value = ''; // FIXME: ルール違反な気がする
    this.props.importCsvRequested(data);
  }

  addFormBtn() {
    const { devices, importSize } = this.props;

    if (devices.size >= importSize) {
      return null;
    }

    return (
      <div
        className="btn btn-primary device-add-btn"
        onClick={() => this.handleClick()}
      >
        +
      </div>
    );
  }

  errorDom(errors) {
    if (errors) {
      return (<Alert message={errors} alertType="danger" />);
    }

    return null;
  }

  render() {
    const { errors, devices, removeForm, updateForm } = this.props;

    return (
      <div>
        { this.errorDom(errors) }
        <div className="flex_column">
          <SimpleFormFile
            name="device[csv]"
            label={I18n.t('devices.new.import_csv')}
            popoverText={I18n.t('activerecord.hints.device.csv_format')}
            ref={(c) => { this.fileForm = c; }}
          />

          <div>
            <div
              className="btn btn-primary"
              onClick={() => this.handleUploadCsv()}
            >
              {I18n.t('common.action.import')}
            </div>
          </div>
        </div>

        <hr />

        {
          devices.map((device, index) => (
            <DeviceRow
              key={index}
              showLabel={index === 0}
              index={index}
              device={device}
              removeForm={removeForm}
              updateForm={updateForm}
            />
            )
          )
        }
        { this.addFormBtn() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.$$deviceFormStore.get('errors'),
    devices: state.$$deviceFormStore.get('devices'),
    importSize: state.$$deviceFormStore.get('import_size'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceForm);

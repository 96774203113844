import React from 'react';

import Footer from '../sharedUnpackeNewsComponents/Footer';
import LikeButton from '../sharedUnpackeNewsComponents/LikeButton';
import MainImage from '../sharedUnpackeNewsComponents/MainImage';
import MainText from '../sharedUnpackeNewsComponents/MainText';
import MoreLink from '../sharedUnpackeNewsComponents/MoreLink';
import RatingRepresentation from '../sharedUnpackeNewsComponents/RatingRepresentation';
import Subject from '../sharedUnpackeNewsComponents/Subject';

interface IProps {
  subject: string;
  mainImage: string;
  mainText: string;
  more: string;
  footerText: string;
  ratingIcons: string[];
  contentsDescriptors: string;
  interactiveElements: string;
  movieUrl: string;
}

const NewsBody = (props: IProps) => {
  const {
    subject,
    mainImage,
    movieUrl,
    mainText,
    more,
    footerText,
    ratingIcons,
    contentsDescriptors,
    interactiveElements,
  } = props;

  return(
    <div className="news-body">
      <Subject {...{ subject }} />
      <MainImage {...{ mainImage, movieUrl }} />
      <MainText {...{ mainText }} />
      <MoreLink {...{ more }} />
      <LikeButton />
      <Footer {...{ footerText }} />
      <RatingRepresentation
        {...{ ratingIcons }}
        {...{ contentsDescriptors }}
        {...{ interactiveElements }}
      />
    </div>
  );
};

export default NewsBody;

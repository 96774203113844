import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/prop-types */

export default class RailsContextWrapper extends React.PureComponent {
  static propTypes = {
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    i18nLocale: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }
  static childContextTypes = {
    href: PropTypes.string,
    pathname: PropTypes.string,
    locale: PropTypes.string,
    timezone: PropTypes.string,
  }
  getChildContext() {
    const { href, pathname, i18nLocale, timezone } = this.props;
    return {
      href,
      pathname,
      timezone,
      locale: i18nLocale,
    };
  }
  render() {
    return this.props.children;
  }
}

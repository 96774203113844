import React from 'react';
import NewsText from './NewsText';

interface IProps {
  footerText: string;
}

const Footer = (props: IProps) => {
  const { footerText } = props;
  if (footerText) {
    return (
      <NewsText text={footerText} className="news-footer"/>
    );
  }

  return null;
};

export default Footer;

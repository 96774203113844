import React from 'react';

import Base64Image from '../../../lib/components/Base64Image';

interface IProps {
  ratingIcon: string;
}

const RatingIcon = (props: IProps) => (
  <Base64Image className="rating-icon" image={props.ratingIcon} />
);

export default RatingIcon;

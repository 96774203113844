import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

export default class BelongsCountrySelector extends React.PureComponent {
  static propTypes = {
    countriesInitial: PropTypes.instanceOf(List).isRequired,
  }

  handleClick(e) {
    const th = $(`#${e.target.value}`).position();
    const sh = $('.sortSelect__list').scrollTop();
    const pos = th.top + sh + 1;
    $('.sortSelect__list').animate({ scrollTop: pos }, 'slow', 'swing');
  }

  renderButton() {
    const { countriesInitial } = this.props;
    return countriesInitial.toArray().sort().map(country => (
      <button
        key={`button-${country}`}
        type="button"
        className="btn btn-default"
        value={country}
        onClick={this.handleClick}
      >
        {country}
      </button>
    ));
  }

  render() {
    return (
      <div className="form-group">
        {this.renderButton()}
      </div>
    );
  }
}

import React from 'react';

import ContentsDescriptors from './ContentsDescriptors';
import InteractiveElements from './InteractiveElements';
import RatingIcon from './RatingIcon';

interface IProps {
  ratingIcons?: string[];
  ratingIcon?: string;
  contentsDescriptors: string;
  interactiveElements: string;
  last?: boolean;
}

const RatingRepresentation = (props: IProps) => {
  const { ratingIcons, ratingIcon, contentsDescriptors, interactiveElements, last } = props;
  let ratingIconImage = ratingIcon;
  if (!ratingIconImage && ratingIcons && ratingIcons.length) {
    ratingIconImage = Object.values(ratingIcons[0])[0];
  }
  const float = (last === undefined || last || contentsDescriptors || interactiveElements) ? 'none' : 'left';

  if (ratingIconImage) {
    return (
      <div className="rating-representation" style={{ float: float }}>
        <RatingIcon ratingIcon={ratingIconImage} />
        <div className="descriptions">
          <ContentsDescriptors contentsDescriptors={contentsDescriptors} />
          <InteractiveElements interactiveElements={interactiveElements} />
        </div>
      </div>
    );
  }

  return null;
};

export default RatingRepresentation;

import { List, Record } from 'immutable';
import applySortAction from '../lib/applySortActionList';
import Product from './Product';

interface IRecord {
  scene: string;
  search_type: string;
  dst_nsuid: string;
  list_name?: string;
  list_type?: string;
  list_ids: List<string>;
  detailScene?: string;
}
const defaultValue: IRecord = {
  scene: '',
  search_type: '',
  dst_nsuid: '',
  list_name: undefined,
  list_type: undefined,
  list_ids: List(),
  // railsモデル側には存在しない。title詳細やaoc詳細を区別するためにクライアント側だけで利用
  detailScene: undefined,
};

export default class MoreShopQuery extends Record(defaultValue) {
  public static detailScene(object: IRecord) {
    const length = Object.keys(object).length;

    if (object === null || length === 0) { return ''; }
    if (object.scene === 'new') { return 'new'; }
    if (object.scene === 'pickup') { return 'pickup'; }
    if (object.scene === 'sale') { return 'sale'; }
    if (object.scene === 'search') { return 'search'; }
    if (object.scene === 'list') { return 'list'; }
    if (object.scene === 'subscriptions') { return 'subscriptions'; }
    if (object.scene === 'consumption') { return 'consumption'; }
    if (object.scene === 'membership_service') { return 'membership_service'; }
    if (object.scene === 'membership_top') { return 'membership_top'; }
    if (length === 2) {
      const { scene, dst_nsuid: dstNsuid } = object;
      if (scene === 'aocs') { return 'aocList'; }
      if (dstNsuid !== undefined) {
        if (Product.isSoftDetail(dstNsuid)) { return 'softDetail'; }
        if (Product.isAocDetail(dstNsuid)) { return 'aocDetail'; }
        if (Product.isBundleDetail(dstNsuid)) { return 'bundleDetail'; }
        if (Product.isPreticketDetail(dstNsuid)) { return 'preticketDetail'; }
      }
    }
    return '';
  }

  public static fromDetailSceneToScene(value: string) {
    switch (value) {
      case '':
        return '';
      case 'new':
        return 'new';
      case 'pickup':
        return 'pickup';
      case 'aocList':
        return 'aocs';
      case 'aocDetail':
      case 'softDetail':
      case 'bundleDetail':
      case 'preticketDetail':
        return 'product_detail';
      case 'list':
        return 'list';
      case 'subscriptions':
        return 'subscriptions';
      case 'consumption':
        return 'consumption';
      case 'sale':
        return 'sale';
      case 'search':
        return 'search';
      case 'membership_service':
        return 'membership_service';
      case 'membership_top':
        return 'membership_top';
      default:
        return 'pickup';
    }
  }

  constructor(object: any) {
    const newObject = object;
    if (object !== undefined && object !== null) {
      newObject.detailScene = MoreShopQuery.detailScene(object);
      newObject.list_ids = List(object.list_ids);
    }
    super(newObject);
  }
  // mutations
  public select(nsUid: string) {
    return this.update('list_ids', (list: List<string>) => list.push(nsUid));
  }
  public unselect(nsUid: string) {
    if (this.list_ids === undefined) {return this; }
    const index = this.list_ids.keyOf(nsUid);
    if (index === undefined) { return this; }
    return this.update('list_ids', (list: List<string>) => list.delete(index));
  }
  public replace(nsUids: List<string>) {
    return this.set('list_ids', nsUids);
  }
  public sort(oldIndex: number, newIndex: number) {
    return this.update('list_ids', (list: List<string>) => applySortAction(list, oldIndex, newIndex));
  }
  // validations
  public valid() {
    return this;
  }

  // 選択されているシーンで候補に出すべき商品なのかを判定
  public filteringList(product: Product) {
    switch (this.detailScene) {
      case 'new':
      case 'pickup':
        return true;
      case 'softDetail':
        if (Product.isSoftDetail(product.ns_uid)) { return false; }
        return true;
      case 'aocDetail':
        if (Product.isAocDetail(product.ns_uid)) { return false; }
        return true;
      case 'bundleDetail':
        if (Product.isBundleDetail(product.ns_uid)) { return false; }
        return true;
      case 'preticketDetail':
        if (Product.isPreticketDetail(product.ns_uid)) { return false; }
        return true;
      case 'aocList':
        if (product.has_aocs) { return false; }
        return true;
      case 'subscriptions':
        if (product.has_tickets) { return false; }
        return true;
      case 'consumption':
        if (product.has_consumables) { return false; }
        return true;
      default:
        return false;
    }
  }
}

import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import ravenMiddleware from 'redux-raven-middleware';

import { createLogger } from 'redux-logger';
import Immutable from 'immutable';
import belongsCountryReducer from '../ducks/BelongsCountry';
import { Country } from '../models';

export default (props) => {
  let { countries } = props;
  let countriesInitial = [];

  Object.keys(countries).forEach(code => {
    countriesInitial.push(countries[code]['initial']);
  });
  countriesInitial = countriesInitial.filter((x, i, self) => self.indexOf(x) === i);

  const sortedCountries = [];
  Object.keys(countries).forEach((key) => {
    const value = countries[key];
    sortedCountries.push([key, value]);
  });
  sortedCountries.sort((a, b) => (a[1].order - b[1].order));

  countries = new Immutable.OrderedMap();
  sortedCountries.forEach(array => {
    const code = array[0];
    const country = array[1];
    countries = countries.set(code, new Country(country));
  });

  const initialState = {
    belongsCountryReducer: Immutable.fromJS({ countries, countriesInitial }),
  };

  const combinedReducer = combineReducers({ ...{ belongsCountryReducer } });

  const middlewares =
    process.env.NODE_ENV === 'production' ?
    [ravenMiddleware(window.raven)] :
    [createLogger(), ravenMiddleware(window.raven)];

  const composedStore = compose(
    applyMiddleware(...middlewares),
    process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  );
  const storeCreator = composedStore(createStore);
  const store = storeCreator(combinedReducer, initialState);

  return store;
};

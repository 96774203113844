import { AnyAction, Dispatch } from 'redux';

let dispatch: Dispatch<AnyAction>;

export const setDispatch = (d: Dispatch<AnyAction>) => {
  dispatch = d;
};

export const getDispatch = () => {
  if (dispatch === undefined) { throw Error('dispatch is not set'); }
  return dispatch;
};

import { Map, Record } from 'immutable';

export interface ITopicCountry {
  id?: number;
  country: string;
  publishing_time: string;
  destroy: boolean;
  errors: Map<string, string[] | undefined>;
}
const defaultValue: ITopicCountry = {
  id: undefined,
  country: '',
  publishing_time: '',
  destroy: false,
  errors: Map(),
};

export default class TopicCountry extends Record(defaultValue) {
  constructor(object: any = defaultValue) {
    const newObject = object;
    newObject.errors = Map(object.errors);
    super(newObject);
  }

  public isValid() {
    return this.errors.size === 0;
  }
}

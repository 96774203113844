import React from 'react';

import { CalendarFilter } from './CalendarFilter';
import { FullCalendarWrapper } from './FullCalendarWrapper';

export const Calendar: React.SFC = () => {
  return (
    <>
      <CalendarFilter />
      <div style={{ paddingBottom: '60px' }} />
      <FullCalendarWrapper />
    </>
  );
};

import * as React from 'react';
import {
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { actions } from '../../../ducks/MessageForm';
import GlyphPopover from '../../../lib/components/GlyphPopover';
import { getDispatch } from '../../../lib/dispatchExporter';
import Topic from '../../../models/Topic';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
};

interface IDraggableNameProps {
  topicIndex: number;
  title: string;
  maxLengthStyle: number;
}
const DraggableName = SortableHandle((props: IDraggableNameProps) => {
  const { maxLengthStyle, topicIndex, title } = props;
  return (
    <span style={{ cursor: 'move', width: `${maxLengthStyle}em` }}>
      <i className="glyphicon glyphicon-menu-hamburger" />
      { topicIndex + 1 }: { title }
    </span>
  );
});

interface IProps {
  topicIndex: number;
  topic: Topic;
  parentFormName: string;
  maxLengthStyle: number;
  draggedIndex: number;
}
class TopicRow extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public unselectTopic = (id: string) => () => {
    this.dispatch(actions.unselectTopic(id));
  }

  public renderDestroyButton(id: string) {
    return (
      <div style={{ float: 'right' }} onClick={this.unselectTopic(id)}>
        <span style={{ color: 'red' }} className="glyphicon glyphicon-remove" />
      </div>
    );
  }

  public renderTopicDetail(topic: Topic) {
    const title = topic.name;
    const body = topic.languages.join(', ');
    return (
      <div style={{ float: 'right', marginRight: '10px' }}>
        <GlyphPopover {...{ title, body }} glyph="info-sign" />
      </div>
    );
  }

  public render() {
    const { topicIndex, topic, parentFormName, maxLengthStyle, draggedIndex } = this.props;
    const { maxLength } = Topic;
    const text = topic.name;
    const title = text.length > maxLength ? `${text.slice(0, maxLength - 1)}…` : text;
    const opacity = draggedIndex === topicIndex ? 0 : 1;

    // topic-rowのdivが短すぎてxがはみ出る問題があるので
    // 対処療法としてmaxWidthを1em長めに指定します。
    return (
      <div className="topic-row" style={{ ...style, opacity, maxWidth: `${maxLengthStyle + 5}em` }}>
        <DraggableName {...{ title, topicIndex, maxLengthStyle }} />
        {this.renderDestroyButton(topic.npns_topic_id)}
        {this.renderTopicDetail(topic)}
        <input type="hidden" name={`${parentFormName}`} value={topic.npns_topic_id} />
      </div>
    );
  }
}

export default SortableElement(TopicRow);

import { OrderedMap, Record } from 'immutable';
import { intersection } from 'lodash';
import uuid from 'uuid/v1';

import Data from './DistributionFile/Data';
import VirtualFile from './DistributionFile/VirtualFile';
import TimelineDataSet from './TimelineDataSet';

const defaultValue: {
  file: VirtualFile,
  uploadedFile?: File,
  selectedDataId?: string,
  previousDataId?: string,
  nextDataId?: string,
  editingDataId?: string,
  requesting: boolean,
  fileEditing: boolean,
  page: number,
  totalPages: number,
  permittedActions: string[],
  errorMessage: string,
  beforeEditData?: Data,
  timezone: string,
  include_expired: boolean,
} = {
  file: new VirtualFile(),
  uploadedFile: undefined,
  selectedDataId: undefined,
  previousDataId: undefined,
  nextDataId: undefined,
  editingDataId: undefined,
  requesting: false,
  fileEditing: false,
  page: 1,
  totalPages: 0,
  permittedActions: [],
  errorMessage: '',
  beforeEditData: undefined,
  timezone: '',
  include_expired: false
};

export default class DistributionEditorState extends Record(defaultValue) {
  // command
  public selectData(dataId: string): DistributionEditorState {
    const prevData = this.file.getPreviousData(dataId);
    const nextData = this.file.getNextData(dataId);
    const previousDataId = prevData ? prevData.id : undefined;
    const nextDataId = nextData ? nextData.id : undefined;
    return this.merge({ selectedDataId: dataId, previousDataId, nextDataId });
  }
  public startEditData(dataId: string): DistributionEditorState {
    const newState = this.set('editingDataId', dataId).selectData(dataId);
    const beforeEditData = newState.getEditingData();
    return newState.merge({ beforeEditData });
  }
  public startEditFile(): DistributionEditorState {
    return this.merge({ fileEditing: true });
  }
  public endEditData(): DistributionEditorState {
    if (this.editingDataId === undefined) { return this; }
    const restoreDataId = this.editingDataId;
    const newState = this.merge({ editingDataId: undefined, uploadedFile: undefined });
    const currentData = this.file.dataEntities.get(this.editingDataId);
    // 新規作成で戻るボタンを押した場合は削除する
    if (currentData && currentData.isNewData()) {
      const dataId = this.editingDataId;
      return this.update(
        'file', file => file.removeData(dataId)
      ).fallbackSelected();
    }
    return newState.setIn(
      ['file', 'dataEntities', restoreDataId], this.beforeEditData
    ).set('beforeEditData', undefined);
  }
  public endEditFile(): DistributionEditorState {
    return this.merge({ fileEditing: false });
  }
  public setFile({ dataId, file, name }: { dataId: string, file: File, name: string }): DistributionEditorState {
    return this.set('uploadedFile', file).update('file', virtualFile => virtualFile.setOriginalFileName(dataId, name));
  }
  public deleteFile({ dataId }: { dataId: string }): DistributionEditorState {
    return this.delete('uploadedFile').update('file', virtualFile => virtualFile.setOriginalFileName(dataId, ''));
  }
  public updateData({ dataId, key, value }: { dataId: string, key: string, value: string }): DistributionEditorState {
    return this.update('file', file => file.updateData(dataId, key, value));
  }
  public updateFile({ key, value }: { key: string, value: string | Date }): DistributionEditorState {
    return this.setIn(['file', key], value);
  }
  public successfullyEndEditData({ datas, totalPages }: { datas: Data[], totalPages: number }): DistributionEditorState {
    const mergeParams = {
      beforeEditData: undefined,
      editingDataId: undefined,
      uploadedFile: undefined,
    };
    return this.setLatestDataSet({ datas, totalPages }).merge(mergeParams);
  }
  public successfullyEndEditFile(data: { filename: string, note: string, warn_blank_schedule : boolean }): DistributionEditorState {
    const { filename, note, warn_blank_schedule: warnBlankSchedule } = data;
    return this.update(
      'file', file => file.merge({ filename, note, warnBlankSchedule })
    ).merge({ requesting: false, fileEditing: false });
  }
  public changeStatus({ dataId } : { dataId: string }): DistributionEditorState {
    return this.selectData(dataId).startRequest();
  }
  public changePage(page: number): DistributionEditorState {
    return this.startRequest().set('page', page);
  }
  public changeConditions(include_expired: boolean): DistributionEditorState {
    return this.startRequest().set('include_expired', include_expired).set('page', 1);
  }
  public successfullyPageChanged({ datas, totalPages }: { datas: Data[], totalPages: number }): DistributionEditorState {
    const newState = this.setLatestDataSet({ datas, totalPages });
    const selectedData: Data = newState.file.dataEntities.first();
    if (selectedData && 'id' in selectedData) { return newState.selectData(selectedData.id); }
    else { return this; }
  }
  public successfullyConditionsChanged({ datas, totalPages }: { datas: Data[], totalPages: number }): DistributionEditorState {
    const newState = this.setLatestDataSet({ datas, totalPages });
    if (datas.length === 0) { return newState; }
    const selectedData: Data = newState.file.dataEntities.first();
    if (selectedData && 'id' in selectedData) { return newState.selectData(selectedData.id); }
    else { return this; }
  }
  public startRequest(): DistributionEditorState {
    return this.merge({ requesting: true, errorMessage: '' });
  }
  public setLatestDataSet({ datas, totalPages }: { datas: Data[], totalPages: number }): DistributionEditorState {
    return this.setIn(['file', 'dataEntities'],
      OrderedMap(datas.map((data: Data): [string, Data] => [data.id, data]))
    ).merge({ requesting: false, totalPages }).fallbackSelected();
  }
  public fallbackSelected(): DistributionEditorState {
    if (this.selectedDataId && this.file.dataEntities.has(this.selectedDataId)) { return this; }
    let fallbackId = this.nextDataId || this.previousDataId;
    const firstData: Data = this.file.dataEntities.first();
    if (firstData && 'id' in firstData) {
      fallbackId = fallbackId || firstData.id;
      return this.selectData(fallbackId);
    } else {
      // ここはdatafileが削除され、vf.datafilesが0個になったときに通る
      return this.selectData('-1');
    }
  }
  public setErrors(errorObject: { errors?: {}, message?: string }): DistributionEditorState {
    const { errors, message } = errorObject;
    const newState = this.merge({ requesting: false, errorMessage: message || '' });
    if (this.editingDataId) {
      const dataId = this.editingDataId;
      return newState.update('file',
        file => file.updateData(dataId, 'errors', errors || {})
      );
    } else { return newState; }
  }
  public addPrevData(dataId: string): DistributionEditorState {
    const prevData = this.file.getPreviousData(dataId);
    const currData = this.file.dataEntities.get(dataId);
    if (currData === undefined) { return this; }
    const newData = new Data(
      {
        id: uuid(),
        publishingTime: prevData ? prevData.expirationTime || prevData.publishingTime : undefined,
        expirationTime: currData.publishingTime,
      }
    );
    return this.update(
      'file', file => file.appendData(newData)
    ).set('editingDataId', newData.id).selectData(newData.id);
  }
  public addNextData(dataId: string): DistributionEditorState {
    const nextData = this.file.getNextData(dataId);
    const currData = this.file.dataEntities.get(dataId);
    if (currData === undefined) { return this; }
    const newData = new Data(
      {
        id: uuid(),
        publishingTime: currData.expirationTime || currData.publishingTime,
        expirationTime: nextData ? nextData.publishingTime : undefined,
      }
    );
    return this.update(
      'file', file => file.appendData(newData)
    ).set('editingDataId', newData.id).selectData(newData.id);
  }
  public addLonelyData(): DistributionEditorState {
    const currentDateString = (new Date()).toISOString().slice(0, 10);
    const newData = new Data({ id: uuid(), publishingTime: currentDateString });
    return this.update(
      'file', file => file.appendData(newData)
    ).set('editingDataId', newData.id).selectData(newData.id);
  }
  // query
  public getEditingData(): Data | undefined {
    if (this.editingDataId === undefined || !this.file.dataEntities.has(this.editingDataId)) { return undefined; }
    return this.file.dataEntities.get(this.editingDataId);
  }
  public getTimelineDataSet(): TimelineDataSet {
    if (this.selectedDataId === undefined) { throw Error; }
    const prevData = this.file.getPreviousData(this.selectedDataId);
    const selectedData = this.file.dataEntities.get(this.selectedDataId) || undefined;
    const nextData = this.file.getNextData(this.selectedDataId);
    return new TimelineDataSet({ prevData, selectedData, nextData });
  }
  public getDataEntities(): OrderedMap<string, Data | undefined> {
    let dataEntities = this.file.dataEntities;
    if (this.selectedDataId) {
      dataEntities = dataEntities.update(this.selectedDataId, data => {
        if (data) { return data.set('selected', true); }
        return data;
      });
    }
    if (this.previousDataId) {
      dataEntities = dataEntities.update(this.previousDataId, data => {
        if (data) { return data.set('isNeighbor', true); }
        return data;
      });
    }
    if (this.nextDataId) {
      dataEntities = dataEntities.update(this.nextDataId, data => {
        if (data) { return data.set('isNeighbor', true); }
        return data;
      });
    }
    return dataEntities.map(
      data => {
        if (data) { return data.set('actions', intersection(data.actions, this.permittedActions)); }
        return;
      }
    );
  }
  public canSubmitData(): boolean {
    const data = this.getEditingData();
    if (!data) { return false; }
    if (data.isNewData()) {
      return data.publishingTime !== undefined && this.uploadedFile !== undefined && !this.requesting;
    }
    return data.publishingTime !== undefined && !this.requesting;
  }
}

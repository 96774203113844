import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import I18n from '../../lib/i18n';

export default class SelectedCountries extends PureComponent {
  static propTypes = {
    countries: PropTypes.instanceOf(Immutable.OrderedMap).isRequired,
  }

  renderSelectCountries() {
    const { countries } = this.props;
    return countries.valueSeq().map((country) => {
      if (!country.checked) return null;
      return (<li key={`select-${country.name}`}>{`${country.name}(${country.region})`}</li>);
    });
  }

  render() {
    return (
      <div className="pull-left">
        <div className="sortSelect__selectBox">
          <p className="bg-primary">{I18n.t('country_groups.selected_countries')}</p>
          <ul className="sortSelect__select">
            {this.renderSelectCountries()}
          </ul>
        </div>
      </div>
    );
  }
}

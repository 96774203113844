import { OrderedMap } from 'immutable';
import * as React from 'react';
import { actions } from '../../ducks/NewsSettingForm';
import {
  SimpleFormCheckbox,
  SimpleFormDateTime,
  SimpleFormHidden,
  SimpleFormLabel,
} from '../../lib/components/SimpleForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import Constants from '../../models/Constants';
import TopicCountry from '../../models/TopicCountry';

interface IProps {
  topicCountries: OrderedMap<string, TopicCountry>;
  constants: Constants;
}

export default class TopicCountryForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      this.dispatch(actions.addCountry(e.target.value));
    } else {
      this.dispatch(actions.removeCountry(e.target.value));
    }
  }

  public render() {
    const { topicCountries, constants } = this.props;

    return (
      <SimpleFormLabel
        label={I18n.t('activerecord.attributes.news_topic.countries')}
        name="news_topic[topic_countries][]"
        popoverText={I18n.t('general_news.tooltips.countries')}
        required={true}
      >
        <div className="row">
          {constants.shopCountries.valueSeq().map((country, index) => {
            const topicCountry = topicCountries.get(country.iso2);
            const topicCountryIdNode =
              topicCountry ? (
                <SimpleFormHidden
                  name={`news_topic[topic_countries][${index}][id]`}
                  controlId={`news_topic_countries_${index}_id`}
                  value={topicCountry.id}
                />
              ) : null;

            const topicCountryDestroyNode =
              ((topicCountry && topicCountry.destroy) ? (
                <SimpleFormHidden
                  name={`news_topic[topic_countries][${index}][_destroy]`}
                  controlId={`news_topic_countries_${index}__destroy`}
                  value="1"
                />
              ) : null);

            return(
              <div key={index} className="col-md-4" style={{height: '100px'}}>
                <div className="flex_column">
                  <SimpleFormCheckbox
                    name={`news_topic[topic_countries][${index}][country]`}
                    controlId={`news_topic_countries_${index}_country`}
                    collection={true}
                    label={country.iso2}
                    value={country.iso2}
                    defaultChecked={!!topicCountry && !topicCountry.destroy}
                    onChange={this.handleCheck}
                  />
                  {topicCountryIdNode}
                  {topicCountryDestroyNode}
                  <SimpleFormDateTime
                    label={I18n.t('activerecord.attributes.topic_country.publishing_time')}
                    name={`news_topic[topic_countries][${index}][publishing_time]`}
                    controlId={`news_topic_countries_${index}_publishing_time`}
                    defaultValue={topicCountry ? topicCountry.publishing_time : ''}
                    errorMessage={topicCountry ? topicCountry.errors.get('publishing_time') : []}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </SimpleFormLabel>
    );
  }
}

import React from 'react';
import NewsIcon from './NewsIcon';

interface IProps {
  newsIcon: string;
  topicName: string;
  publisher: string;
}

const NewsHeaderV2OrLater = (props: IProps) => (
  <div className="news-header">
    <NewsIcon newsIcon={props.newsIcon} />
    <div className="channel-name-and-publisher-name">
      <div className="channel-name">
        {props.topicName}
      </div>
      <div className="publisher-name">
        {props.publisher}
      </div>
    </div>
    <div className="publishing-date">
      YYYY/MM/DD
    </div>
  </div>
);

export default NewsHeaderV2OrLater;

import { List, Record } from 'immutable';
import { compact, flatten, forEach, maxBy, minBy, uniqBy } from 'lodash';

import Data from './DistributionFile/Data';

/*
  TimeLine表示のアルゴリズム

  スケジュール上の日付を線形に配置すると、長いスケジュールが一つあるだけで
  どこがかぶっているのか全然わからん問題がありました。
  なので、日付と日付がある程度離れるように歪める処理（normalizePoints）を実装しています。

  解
  Data云々は一旦忘れて、数直線上に並んだ日付だけを考えます。=> List<Point>
  表示するときには左端を原点とするので、最も過去の時刻に対するオフセットを座標とします。
  こうすることで、問題は、List<Point>の隣り合うPointの幅をいい感じに広げることになります。
  今回はナイーブに、最も短い幅が基準を超えるまで increaseRatio倍幅を広げ続ける実装にしました。
*/

interface IPoint {
  x: number;
  date: Date;
}

interface ISchedule {
  publishingTime: Date;
  expirationTime?: Date;
  position: string;
}

interface IWidth {
  size: number;
  p0: IPoint;
  p1: IPoint;
}

interface IAlignment {
  prev: { left: number, width: number };
  curr: { left: number, width: number };
  next: { left: number, width: number };
}

function createPointList(dataArray: ISchedule[], max: Date): List<IPoint> {
  let points: List<IPoint> = List();
  // 配信イベントの発生する時刻の配列に
  // あとでソートをするのでuniq条件が必須です
  const times = uniqBy(
    flatten(dataArray.map(data => [data.publishingTime, data.expirationTime || max])).filter(time => time.toString() !== 'Invalid Date'),
    date => date.toISOString(),
  );
  // 日時が全てInvalid Dateの場合はreturn
  if (!times.length) { return points; }
  // origin原点の数直線に変換
  const origin = minBy(times, time => time.toISOString());
  if (origin === undefined) { throw Error; } // dataArray.length > 1は保証されてますが、lodashの型定義がそうなっている
  forEach(times, time => {
    points = points.push({ x: time.getTime() - origin.getTime(), date: time });
  });
  return points;
}

function createWidthList(points: List<IPoint>): List<IWidth> {
  // ソートされたList<Point>を事前条件とし、ひとつ未来の時刻に対する幅を返します
  return points.slice(0, points.size - 1).map(p0 => {
    const index = points.indexOf(p0);
    const p1 = points.get(index + 1);
    if (p1 === undefined) { throw Error; }
    return { size: p1.x - p0.x, p0, p1 };
  });
}

function normalizePoints(ps: List<IPoint>, increaseRatio: number): List<IPoint> {
  let points = ps.sortBy(point => point.x);
  let widthList = createWidthList(points);
  let min = widthList.minBy(p => p.size);
  let lastPoint: IPoint = points.last();
  if (min === undefined || !('x' in lastPoint)) { throw Error; }
  // ポイント間の幅が全体の0.2倍(229px / 1148px)を超えるまで、短いやつを拡大し続ける
  while ((min.size / lastPoint.x) < 0.2) {
    // 幅を広げる処理
    const minIndex = points.indexOf(min.p1);
    const x = min.p0.x + (min.size * increaseRatio);
    points = points.set(minIndex, { x, date: min.p1.date });
    widthList = createWidthList(points);
    min = widthList.minBy(p => p.size);
    lastPoint = points.last();
    if (min === undefined || !('x' in lastPoint)) { throw Error; }
  }
  return points;
}

class UIDataAlignmentSimulator {
  private static INCREASE_RATIO = 1.2;
  public schedules: ISchedule[];
  public maxTime: Date;

  constructor(schedules: ISchedule[]) {
    this.schedules = schedules;
    this.maxTime = this.getMaxTime();
  }

  public getMaxTime(): Date {
    const times = compact(
      flatten(this.schedules.map(data => [data.publishingTime, data.expirationTime])),
    ).filter(time => time.toString() !== 'Invalid Date');
    if (times.length === 0) { return new Date('Invalid Date'); }
    // Dateオブジェクトの比較関数を信用してはいけない
    const minTime = minBy(times, time => time.toISOString());
    const maxTime = maxBy(times, time => time.toISOString());
    if (minTime === undefined || maxTime === undefined) { throw Error; }
    // 無限配信がある場合は適当にmarginを足して伸ばしておく
    if (this.hasInfinityDistribution()) {
      const margin = (maxTime.getTime() - minTime.getTime()) / 5; // marginはなんでもいいので適当な数値です
      return new Date(maxTime.getTime() + margin);
    }
    return maxTime;
  }

  public hasInfinityDistribution(): boolean {
    return compact(
      this.schedules,
    ).filter(data => data.expirationTime === undefined).length > 0;
  }

  public getAlignments(): IAlignment {
    const alignments = { prev: { left: 0, width: 0 }, curr: { left: 0, width: 0 }, next: { left: 0, width: 0 } };
    let points = createPointList(this.schedules, this.maxTime);
    // 配信開始日時がすべて同じ場合
    if (points.size < 2) {
      forEach(this.schedules, schedule => {
        alignments[schedule.position].width = 100;
      });
      return alignments;
    }
    points = normalizePoints(points, UIDataAlignmentSimulator.INCREASE_RATIO);
    const maxP: IPoint = points.last();
    if (!('x' in maxP)) { throw Error(); }

    forEach(this.schedules, schedule => {
      const p0 = points.find(p => p.date.getTime() === schedule.publishingTime.getTime());
      const p1 = points.find(p => p.date.getTime() === (schedule.expirationTime || this.maxTime).getTime());
      if (p0) { alignments[schedule.position].left = 100 * (p0.x / maxP.x); }
      if (p0 && p1) { alignments[schedule.position].width = 100 * ((p1.x - p0.x) / maxP.x); }
    });
    return alignments;
  }
}

function dataToSchedule(position: string, data?: Data): ISchedule | undefined {
  if (data === undefined) { return undefined; }
  return {
    publishingTime: new Date(data.publishingTime),
    expirationTime: data.expirationTime ? new Date(data.expirationTime) : undefined,
    position,
  };
}

const defaultValue: {
  prevData?: Data,
  selectedData: Data,
  nextData?: Data,
} = {
  prevData: undefined,
  selectedData: new Data(),
  nextData: undefined,
};

/* tslint:disable:max-classes-per-file */
export default class TimelineDataSet extends Record(defaultValue) {
  public getUIDataAlignments(): IAlignment {
    const schedules = compact([
      dataToSchedule('prev', this.prevData),
      dataToSchedule('curr', this.selectedData),
      dataToSchedule('next', this.nextData),
    ]);
    return new UIDataAlignmentSimulator(schedules).getAlignments();
  }
}

import React from 'react';
import Select, { Option } from 'react-select';

import { actions } from '../../ducks/TestDeliveryForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import SelectableList from '../../models/SelectableList';

interface IProps {
  devices: SelectableList;
}

export default class DeviceForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public handleDeviceUpdate = (selectedOption: Option<string>) => {
    this.dispatch(actions.updateDevice(selectedOption.map((option: Option) => option.value)));
  }

  public deviceSelector() {
    const { devices } = this.props;
    const options = devices.map((device: any) => (
      { label: `${device.serial} (${device.name})`, value: device.id }
    )).toArray();
    const value = devices.getSelected().toList().map((device: any) => (
      { label: `${device.serial} (${device.name})`, value: device.id }
    )).toArray();

    return(
      <Select
        name="data_list[device_ids][]"
        multi={true}
        value={value}
        options={options}
        onChange={this.handleDeviceUpdate}
        closeOnSelect={false}
      />
    );
  }

  public render() {
    return (
      <div>
        <p className="subTitle_18px testDataCheck_title">{I18n.t('activerecord.attributes.data_list.devices')}</p>
        <div className="testDataCheck_checkContainer clearfix" id="serialNum_box">
          { this.deviceSelector() }
        </div>
      </div>
    );
  }
}

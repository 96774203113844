import React from 'react';

import RatingRepresentation from './RatingRepresentation';

interface IProps {
  ratingInformation: Array<{
    rating_icon: string;
    contents_descriptors: string;
    interactive_elements: string;
  }>;
}

const RatingMultiRepresentation = (props: IProps) => {
  const { ratingInformation } = props;

  // コンテンツディスクリプタ/インタラクティブエレメントが指定されているレーティングを先に表示する
  // https://spdlybra.nintendo.co.jp/jira/browse/BCAT-3966
  const descriptorsRatings = [];
  const ratings = [];
  ratingInformation.forEach((rating) => {
    if (rating.contents_descriptors || rating.interactive_elements) {
      descriptorsRatings.push(rating);
    } else {
      ratings.push(rating);
    }
  });
  const sortedRatings = descriptorsRatings.concat(ratings);

  return (
    <>
      {
        sortedRatings.map((rating, index) => {
          const last = ratingInformation.length - 1 === index;
          return <RatingRepresentation
            key={index}
            ratingIcon={rating.rating_icon}
            contentsDescriptors={rating.contents_descriptors}
            interactiveElements={rating.interactive_elements}
            last={last}
          />;
        })
      }
    </>
  );
};

export default RatingMultiRepresentation;

import React from 'react';
import { IUnpackedFeaturedProduct } from '../../../models/MessagePreview/UnpackedFeaturedProduct';
import FeaturedProduct from './FeaturedProduct';

interface IProps {
  featuredProducts: IUnpackedFeaturedProduct[];
  ranking: boolean;
}

const FeaturedProductList = (props: IProps) => {
  const { featuredProducts, ranking } = props;
  if (featuredProducts) {
    const featuredProcuctsNodes = featuredProducts.map((featuredProduct, index) => (
      <FeaturedProduct featuredProduct={featuredProduct} rankingIndex={ranking ? index + 1 : undefined } key={index} />
    ));

    return (
      <div className="featured-products">
        {featuredProcuctsNodes}
      </div>
    );
  }

  return null;
};

export default FeaturedProductList;

import React from 'react';

import Base64Image from '../../../lib/components/Base64Image';
import { IUnpackedRelatedProduct } from '../../../models/MessagePreview/UnpackedRelatedProduct';
import NewsText from './NewsText';

interface IProps {
  relatedProduct: IUnpackedRelatedProduct;
}

const RelatedProduct = (props: IProps) => {
  const { relatedProduct } = props;
  const contentType =
    relatedProduct.content_type ?
      <div className="related-product-content-type">{relatedProduct.content_type}</div> :
      null;

  return (
    <div className="related-product">
      <Base64Image className="related-product-image" image={relatedProduct.image} />
      {contentType}
      <div className="related-product-name">
        <NewsText text={relatedProduct.name} />
      </div>
    </div>
  );
};

export default RelatedProduct;

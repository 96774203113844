import React from 'react';

import NewsIcon from '../sharedUnpackeNewsComponents/NewsIcon';

interface IProps {
  newsIcon: string;
  topicName: string;
}

const NewsHeader = (props: IProps) => (
  <div className="news-header">
    <NewsIcon newsIcon={props.newsIcon} />
    <div className="publisher">
      {props.topicName}
    </div>
    <div className="publishing-date">
      YYYY/MM/DD
    </div>
  </div>
);

export default NewsHeader;

import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';

const CountryRecord = new Record({
  name: undefined,
  initial: undefined,
  region: undefined,
  checked: undefined,
  order: undefined,
});

export default class Country extends CountryRecord {
  static PropTypes = recordOf({
    name: PropTypes.string.isRequired,
    initial: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
  })
}

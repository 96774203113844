import $ from 'jquery';
import "select2";
import 'select2/dist/css/select2.css';

function activateSelect2() {
  $('[data-role=select2]').select2({
    placeholder: "Select ...",
    allowClear: true,
    width: 'style',
  });
}

$(document).on('turbolinks:load', function(){
  $('.select2-container').remove()
  activateSelect2();
})

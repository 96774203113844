import ActionReducer from 'action-reducer';

import { INewsDescription } from '../models/NewsDescription';
import { INewsSetting } from '../models/NewsSetting';
import NewsSettingFormState from '../models/NewsSettingFormState';

const { createAction, reducer } = ActionReducer(new NewsSettingFormState());

export const actions = {
  updateSetting: createAction('NewsSettingForm/UPDATE_SETTING', ($$state, payload: { key: keyof INewsSetting, value: any }) => $$state.updateSetting(payload)),
  updateDescription: createAction('NewsSettingForm/UPDATE_DESCRIPTION', ($$state, payload: { language: string, key: keyof INewsDescription, value: any }) => $$state.updateDescription(payload)),
  addCountry: createAction('NewsSettingForm/ADD_COUNTRY', ($$state, payload: string) => $$state.addCountry(payload)),
  removeCountry: createAction('NewsSettingForm/REMOVE_COUNTRY', (state, payload: string) => state.removeCountry(payload)),
};

export default reducer;

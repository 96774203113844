/*
 * SimpleFormの部品群
 *
 * サポートしているコンポーネントは以下の通り
 * - SimpleFormString
 * - SimpleFormTextarea
 * - SimpleFormSelect
 * - SimpleFormCheckbox
 * - SimpleFormDateTime
 * - SimpleFormFile
 * - SimpleFormFileDescription
 * - SimpleFormHidden
 * - SimpleFormTabs
 *   - 唯一stateをもつ。activeなtabの情報を管理。
 * - SimpleFormTab
 *   - SimpleFormTabsの子として使うことを想定。単体での利用は想定外
 */

import $ from 'jquery';
import 'jquery-datetimepicker/jquery.datetimepicker.css';
// @ts-ignore
import moment from 'moment-timezone';
import * as React from 'react';
import I18n from '../i18n';
import Alert from './Alert';
import GlyphPopover from './GlyphPopover';

// tslint:disable:max-classes-per-file

/* SimpleFormBase:
 * bootstrapのformを作る際の規定クラス。
 * 共通で必要と思われる変換ヘルパーやコンポーネントはここで定義しています。
 */
interface IBase {
  name?: string;
}
class SimpleFormBase<T> extends React.PureComponent<T & IBase, {}> {
  public controlId: string;
  public input: any;

  constructor(props: T & IBase) {
    super(props);
    if (props.name) {
      this.controlId = this.nameToControlId(props.name);
    }
  }

  // input要素の入力値を取るAPI
  public getValue() {
    return this.input.value;
  }

  // formのnameからcontrolIdに指定する文字列を作るメソッド
  // ex.) "message[message_details][1][subject]"
  //       => "message_message_details_1_subject"
  public nameToControlId(name: string) {
    return (
      name.replace(/]/g, '').split('[').join('_')
    );
  }

  // 必須のマークをつけるヘルパー
  public requiredMark(required?: boolean) {
    if (required) {
      return (
        <span title="required" className="required_mark">{I18n.t('simple_form.required.mark')}</span>
      );
    }

    return (null);
  }

  // ヘルプ文言を書くためのヘルパー
  // tooltipに置き換わって不要になる可能性もあります。
  public helpBlock(helpMessage: string | undefined) {
    if (helpMessage !== undefined) {
      return (
        <p className="help-block"> {helpMessage} </p>
      );
    }

    return (null);
  }

  // バリデーションエラーを表示するためのヘルパー
  public errorBlock(errorMessage: string[] | undefined) {
    if (errorMessage !== undefined) {
      return (
        <span className="help-block"> {errorMessage.join(' ')} </span>
      );
    }

    return (null);
  }

  // バリデーションエラーがあった時にformにエラークラスをつけるためのヘルパー
  public hasError(errorMessage: string[] | undefined) {
    if (errorMessage === undefined || errorMessage.length === 0) {
      return ('');
    }

    return (' has-error');
  }
}

export class SimpleFormValidationErrorNotification {
  public props: {
    obj: any;
  };

  public render() {
    const { obj } = this.props;

    if (Object.keys(obj.errors).length !== 0) {
      return (
        <Alert
          message={I18n.t('simple_form.error_notification.default_message')}
          alertType="danger"
        />
      );
    }

    return (null);
  }
}

// とりあえずlabelだけほしい時
interface ILabel {
  name?: string;
  className?: string;
  required?: boolean;
  label?: string;
  helpMessage?: string;
  errorMessage?: string[];
  popoverText?: string;
  children: JSX.Element | JSX.Element[];
}
export class SimpleFormLabel extends SimpleFormBase<ILabel> {
  public render() {
    const { required, label, helpMessage, errorMessage, popoverText, children, className } = this.props;

    return (
      <div className={`form-group${this.hasError(errorMessage)} ${className}`}>
        <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label>
        <GlyphPopover title={label} body={popoverText} />
        { this.helpBlock(helpMessage) }
        { this.errorBlock(errorMessage) }
        <div id={this.controlId}>
          {children}
        </div>
      </div>
    );
  }
}

interface IStatic {
  required?: boolean;
  label?: string;
  value?: string | number;
}
export class SimpleFormStatic extends SimpleFormBase<IStatic> {
  public render() {
    const {
      required,
      label,
      value,
    } = this.props;

    return (
      <div className={'form-group'}>
        <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label>
        <p className="form-control-static">
          {value}
        </p>
      </div>
    );
  }
}

interface IString {
  name?: string;
  required?: boolean;
  label?: string;
  value?: string | number;
  defaultValue?: string;
  placeholder?: string;
  popoverText?: string;
  helpMessage?: string;
  errorMessage?: string[];
  deviceFont?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readOnly?: boolean;
}
export class SimpleFormString extends SimpleFormBase<IString> {
  public render() {
    const {
      name,
      required,
      label,
      value,
      defaultValue,
      placeholder,
      popoverText,
      helpMessage,
      errorMessage,
      deviceFont,
      onChange,
      disabled,
      readOnly,
    } = this.props;

    const style = deviceFont ?
      { fontFamily: 'NxNintendoFont,NxNintendoFontCnOrg,NxNintendoFontCnExt,NxNintendoFontKo,NxNintendoFontTw,NxNintendoFontExt' } :
      {};

    const className = readOnly ? 'form-control readonly' : 'form-control';

    return (
      <div className={`form-group${this.hasError(errorMessage)}`}>
        {label ? <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label> : null}
        {label ? <GlyphPopover title={label} body={popoverText} /> : null}
        <input
          style={style}
          className={className}
          id={this.controlId}
          name={name}
          type="text"
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          {...{ disabled, readOnly }}
          ref={(c) => { this.input = c; }}
        />
        { this.helpBlock(helpMessage) }
        { this.errorBlock(errorMessage) }
      </div>
    );
  }
}

interface ITextarea {
  name?: string;
  required?: boolean;
  label?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  popoverText?: string;
  helpMessage?: string;
  errorMessage?: string[];
  rows?: number;
  deviceFont?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export class SimpleFormTextarea extends SimpleFormBase<ITextarea> {
  public render() {
    const {
      name,
      required,
      label,
      value,
      defaultValue,
      placeholder,
      popoverText,
      helpMessage,
      errorMessage,
      rows,
      deviceFont,
      onChange,
      onBlur,
    } = this.props;

    const style = deviceFont ?
      { fontFamily: 'NxNintendoFont,NxNintendoFontCnOrg,NxNintendoFontCnExt,NxNintendoFontKo,NxNintendoFontTw,NxNintendoFontExt' } :
      {};

    return (
      <div className={`form-group${this.hasError(errorMessage)}`}>
        <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label>
        <GlyphPopover title={label} body={popoverText} />
        <textarea
          style={style}
          className="form-control"
          id={this.controlId}
          name={name}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          ref={(c) => { this.input = c; }}
        />
        { this.helpBlock(helpMessage) }
        { this.errorBlock(errorMessage) }
      </div>
    );
  }
}

interface ISelect {
  name?: string;
  required?: boolean;
  label?: string;
  value?: string | number;
  defaultValue?: any;
  values: Array<[string, string | number]>;
  includeBlank?: boolean;
  popoverText?: string;
  helpMessage?: string;
  errorMessage?: string[];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
}
export class SimpleFormSelect extends SimpleFormBase<ISelect> {
  public selectOptions() {
    const { values, includeBlank } = this.props;
    const newValues = [...values];

    if (includeBlank) {
      newValues.unshift(['', '']);
    }

    return (
      newValues.map((item, index) => <option key={index} value={item[1]}>{item[0]}</option>)
    );
  }

  public render() {
    const {
      name,
      required,
      label,
      value,
      defaultValue,
      popoverText,
      helpMessage,
      errorMessage,
      onChange,
      disabled
    } = this.props;

    return (
      <div className={`form-group${this.hasError(errorMessage)}`}>
        <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label>
        <GlyphPopover title={label} body={popoverText} />
        <div className="selectBox">
          <select
            className="form-control"
            id={this.controlId}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            ref={(c) => { this.input = c; }}
            disabled={disabled}
          >
            {this.selectOptions()}
          </select>
        </div>
        { this.helpBlock(helpMessage) }
        { this.errorBlock(errorMessage) }
      </div>
    );
  }
}

interface ICheckbox {
  name?: string;
  label?: string;
  controlId?: string;
  required?: boolean;
  value?: string | number;
  hiddenValue?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  helpMessage?: string;
  errorMessage?: string[];
  collection?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export class SimpleFormCheckbox extends SimpleFormBase<ICheckbox> {
  constructor(props: ICheckbox) {
    super(props);
    if (props.controlId) {
      this.controlId = props.controlId;
    }
  }

  public checkboxTag() {
    const { defaultChecked, checked, name, onChange, disabled } = this.props;
    const value = this.props.value || 1;

    return (
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        id={this.controlId}
        name={name}
        value={value}
        onChange={onChange}
        ref={(c) => { this.input = c; }}
        disabled={disabled}
      />
    );
  }

  public hiddenValue() {
    const { name, collection } = this.props;
    if (collection) {
      return (null);
    }

    const hiddenValue = (this.props.hiddenValue === undefined) ? '0' : this.props.hiddenValue;
    return (<input name={name} value={hiddenValue} type="hidden" />);
  }

  public render() {
    const {
      label,
      helpMessage,
      errorMessage,
      disabled
    } = this.props;

    const labelClass = disabled ? 'control-label checkbox__item disabled' : 'control-label checkbox__item';

    return (
      <div className="form-group">
        <div className="checkbox">
          { this.hiddenValue() }
          <label className={labelClass} htmlFor={this.controlId}>
            { this.checkboxTag() }
            { label }
          </label>
          { this.helpBlock(helpMessage) }
          { this.errorBlock(errorMessage) }
        </div>
      </div>
    );
  }
}

interface IDateTime {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  value?: string;
  controlId?: string;
  defaultValue?: string;
  popoverText?: string;
  helpMessage?: string;
  errorMessage?: string[];
  options?: any;
  onChange?: (event: Event | React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}
export class SimpleFormDateTime extends SimpleFormBase<IDateTime> {
  public datetimepicker: any;
  // tslint:disable-next-line:variable-name
  public datetimepicker__btn: any;

  public getValue() {
    return this.datetimepicker.value;
  }

  public componentDidMount() {
    $.datetimepicker.setLocale(I18n.locale);

    $.datetimepicker.setDateFormatter({
      parseDate(date: any, format: any) {
        const d = moment(date, format);
        return (d.isValid() ? d.toDate() : false);
      },

      formatDate(date: any, format: any) {
        return moment(date).format(format);
      },
    });

    // 何もしないと、datetimepickerでonChangeが発火しないので、
    // onSelectDateとonSelectTimeでonChangeに渡した関数をやや無理やり発火させます。
    const defaultOptions = {
      format: 'YYYY-MM-DD HH:mm',
      formatTime: 'HH:mm',
      formatDate: 'YYYY-MM-DD',
      onSelectDate: (ct: any, $i: any) => {
        const event = new Event('change');
        $i[0].dispatchEvent(event);
        if (this.props.onChange) { this.props.onChange(event); }
      },
      onSelectTime: (ct: any, $i: any) => {
        const event = new Event('change');
        $i[0].dispatchEvent(event);
        if (this.props.onChange) { this.props.onChange(event); }
      },
    };

    const options = Object.assign({}, defaultOptions, this.props.options || {});
    $(this.datetimepicker).datetimepicker(options);
    $(this.datetimepicker__btn).click(() => {
      $(this.datetimepicker).datetimepicker('show');
    });
  }

  public componentWillUnmount() {
    $(this.datetimepicker).datetimepicker('destroy');
  }

  public render() {
    const {
      name,
      required,
      disabled,
      label,
      value,
      defaultValue,
      popoverText,
      helpMessage,
      errorMessage,
      onChange,
      onBlur,
    } = this.props;

    return (
      <div className={`form-group${this.hasError(errorMessage)}`}>
        <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label>
        <GlyphPopover title={label} body={popoverText} />
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            id={this.controlId}
            name={name}
            disabled={disabled}
            autoComplete="off"
            ref={(c) => { this.datetimepicker = c; }}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
          />
          <div className="input-group-addon" ref={(c) => { this.datetimepicker__btn = c; }}>
            <span className="glyphicon glyphicon-calendar" />
          </div>
        </div>
        { this.helpBlock(helpMessage) }
        { this.errorBlock(errorMessage) }
      </div>
    );
  }
}

interface IFile {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  popoverText?: string;
  helpMessage?: string;
  errorMessage?: string[];
  onChange?: (event: Event | React.ChangeEvent<HTMLInputElement>) => void;
}
export class SimpleFormFile extends SimpleFormBase<IFile> {
  public render() {
    const { name, required, disabled, label, popoverText, helpMessage, errorMessage, onChange } = this.props;

    return (
      <div className={`form-group${this.hasError(errorMessage)}`}>
        <label className="control-label" htmlFor={this.controlId}>
          {this.requiredMark(required)}
          {label}
        </label>
        <GlyphPopover title={label} body={popoverText} />
        <input
          type="file"
          id={this.controlId}
          name={name}
          disabled={disabled}
          onChange={onChange}
          ref={(c) => { this.input = c; }}
        />
        { this.helpBlock(helpMessage) }
        { this.errorBlock(errorMessage) }
      </div>
    );
  }
}

/*
 * SimpleFormFileDescription
 *   添付ファイルの情報を表示するためのコンポーネント
 */
interface IFileDescription {
  src?: string;
  contentsFileName: string;
  contentsFileSize: string;
  contentsFingerprint?: string;
}
export class SimpleFormFileDescription extends SimpleFormBase<IFileDescription> {
  public render() {
    const { src, contentsFileName, contentsFileSize, contentsFingerprint } = this.props;

    // image
    if (src) {
      return (
        <div className="img-description">
          <img className="thumbnail" role="presentation" src={src} />
          <div>
            <dl className="dl-horizontal">
              <dt>{I18n.t('activerecord.attributes.parcel.contents_file_name')}</dt>
              <dd>{contentsFileName}</dd>
            </dl>
            <dl className="dl-horizontal">
              <dt>{I18n.t('activerecord.attributes.parcel.contents_file_size')}</dt>
              <dd>{contentsFileSize}</dd>
            </dl>
          </div>
        </div>
      );
    }

    // not image
    return (
      <div>
        <dl className="dl-horizontal">
          <dt>original filename</dt>
          <dd>{contentsFileName}</dd>
        </dl>
        <dl className="dl-horizontal">
          <dt>filesize</dt>
          <dd>{contentsFileSize}</dd>
        </dl>
        <dl className="dl-horizontal">
          <dt>MD5 fingerprint</dt>
          <dd>{contentsFingerprint}</dd>
        </dl>
      </div>
    );
  }
}

interface IHidden {
  name?: string;
  label?: string;
  controlId?: string;
  value?: number | string;
  defaultValue?: string;
}
export class SimpleFormHidden extends SimpleFormBase<IHidden> {
  public render() {
    const { name, value, defaultValue } = this.props;

    return (
      <input
        type="hidden"
        name={name}
        value={value}
        defaultValue={defaultValue}
        ref={(c) => { this.input = c; }}
      />
    );
  }
}

interface ITab {
  eventKey: string;
  title: string;
  active?: boolean;
  hidden?: boolean;
  errorMessage?: string;
  children: JSX.Element;
}
export class SimpleFormTab extends React.PureComponent<ITab, {}> {
  public paneClassName(active?: boolean) {
    if (active) {
      return ('tab-pane active');
    }

    return ('tab-pane');
  }

  public render() {
    const { eventKey, title, active, hidden } = this.props;
    return (
      <div className={this.paneClassName(active)} id={eventKey} title={title} hidden={hidden} >
        { React.Children.only(this.props.children) }
      </div>
    );
  }
}

interface ITabs {
  defaultActiveKey: string;
  aClassName?: string;
  errorMessage?: string;
  children: JSX.Element[];
}
interface ITabsState {
  activeKey: string;
  defaultActiveKey: string;
}
export class SimpleFormTabs extends React.PureComponent<ITabs, ITabsState> {
  public state = {
    activeKey: '',
    defaultActiveKey: '',
  };

  public componentWillMount() {
    const { defaultActiveKey } = this.props;

    this.setState({
      activeKey: defaultActiveKey,
      defaultActiveKey,
    });
  }

  public componentWillReceiveProps(nextProps: ITabs) {
    const { children } = nextProps;
    const nextDefaultActiveKey = nextProps.defaultActiveKey;
    const { defaultActiveKey, activeKey } = this.state;

    if (defaultActiveKey !== nextDefaultActiveKey || !children.find(child => child.key === activeKey)) {
      this.setState({
        activeKey: nextDefaultActiveKey,
        defaultActiveKey: nextDefaultActiveKey,
      });
    }
  }

  public tabClassName(eventKey: string) {
    if (eventKey === this.state.activeKey) {
      return ('active');
    }

    return ('');
  }

  public expanded(eventKey: string) {
    if (eventKey === this.state.activeKey) {
      return ('true');
    }

    return ('false');
  }

  public activeTab(eventKey: string) {
    if (eventKey === this.state.activeKey) {
      return (true);
    }

    return (false);
  }

  public handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (e.currentTarget.tagName === 'A') {
      const activeKey = e.currentTarget.href.split('#')[1];
      this.setState({ activeKey });
    }
  }

  public render() {
    const self = this;

    return (
      <div className="uncontroled-form">
        <ul className="nav nav-tabs">
          {
            React.Children.map(
              this.props.children,
              (child: any) => {
                if (child.props.hidden) { return (null); }

                return (
                  <li className={self.tabClassName(child.props.eventKey)}>
                    <a
                      className={this.props.aClassName}
                      href={`#${child.props.eventKey}`}
                      aria-expanded={self.expanded(child.props.eventKey)}
                      onClick={this.handleClick}
                    >
                      {child.props.title}
                      <span className="label label-danger" style={{ marginLeft: '5px' }}>
                        {child.props.errorMessage}
                      </span>
                    </a>
                  </li>
                );
              }
            )
          }
        </ul>
        <div className="tab-content">
          {
            React.Children.map(
              this.props.children,
              (child: any) => React.cloneElement(
                child,
                Object.assign({}, child.props, { active: self.activeTab(child.props.eventKey) })
              )
            )
          }
        </div>
      </div>
    );
  }
}
/* eslint-enable react/no-multi-comp */

import React from 'react';
import Base64Image from '../../../lib/components/Base64Image';

interface IProps {
  mainImage: string;
  movieUrl: string;
}

const MainImage = (props: IProps) => {
  const { mainImage, movieUrl } = props;
  if (mainImage) {
    if (movieUrl) {
      return (
        <Base64Image className="news-movie-image" image={mainImage} />
      );
    }

    return (
      <Base64Image className="news-main-image" image={mainImage} />
    );
  }

  return null;
};

export default MainImage;

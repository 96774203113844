import React from 'react';

import { IUnpackedFeaturedProduct } from '../../../models/MessagePreview/UnpackedFeaturedProduct';
import { IUnpackedMainContent } from '../../../models/MessagePreview/UnpackedMainContent';
import { IUnpackedRelatedMovie } from '../../../models/MessagePreview/UnpackedRelatedMovie';
import FeaturedProductList from '../sharedUnpackeNewsComponents/FeaturedProductList';
import Footer from '../sharedUnpackeNewsComponents/Footer';
import LikeButton from '../sharedUnpackeNewsComponents/LikeButton';
import MainImage from '../sharedUnpackeNewsComponents/MainImage';
import MainText from '../sharedUnpackeNewsComponents/MainText';
import MoreLink from '../sharedUnpackeNewsComponents/MoreLink';
import RatingMultiRepresentation from '../sharedUnpackeNewsComponents/RatingMultiRepresentation';
import RelatedMovieList from '../sharedUnpackeNewsComponents/RelatedMovieList';
import Subject from '../sharedUnpackeNewsComponents/Subject';

interface IProps {
  subject: string;
  body: IUnpackedMainContent[];
  more: string;
  featuredProducts: IUnpackedFeaturedProduct[];
  ranking: boolean;
  relatedMovies: IUnpackedRelatedMovie[];
  footerText: string;
  ratingInformation: Array<{
    rating_icon: string;
    contents_descriptors: string;
    interactive_elements: string;
  }>;
}

const NewsBody = (props: IProps) => {
  const {
    subject,
    body,
    more,
    featuredProducts,
    ranking,
    relatedMovies,
    footerText,
    ratingInformation,
  } = props;

  return(
    <div className="news-body">
      <Subject {...{ subject }} />
      { body.map((content, index) => {
        return(
          <div key={index}>
            <MainImage mainImage={content.main_image} movieUrl={content.movie_url} />
            <MainText mainText={content.text} tagSupported={true} />
          </div>
        );
      })}
      <FeaturedProductList {...{ featuredProducts, ranking }} />
      <MoreLink {...{ more }} />
      <RelatedMovieList {...{ relatedMovies }} />
      <LikeButton />
      <Footer {...{ footerText }} />
      <RatingMultiRepresentation {...{ ratingInformation }} />
    </div>
  );
};

export default NewsBody;

import React from 'react';

import Base64Image from '../../../lib/components/Base64Image';
import I18n from '../../../lib/i18n';
import { IUnpackedRelatedChannel } from '../../../models/MessagePreview/UnpackedRelatedChannel';
import NewsText from './NewsText';

interface IProps {
  relatedChannel: IUnpackedRelatedChannel;
}

const RelatedChannel = (props: IProps) => (
  <div className="related-channel">
    <div className="related-channel-image-area">
      <Base64Image className="related-channel-image" image={props.relatedChannel.topic_image} />
    </div>
    <div className="related-channel-name">
      <NewsText text={props.relatedChannel.topic_name} />
    </div>
    <div className="related-channel-subscribe">
      {I18n.t('messages.preview.related_channel_subscribe')}
    </div>
  </div>
);

export default RelatedChannel;

import PropTypes from 'prop-types';
import { Record, Map } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';
import Directory from './Directory';
import Device from './Device';
import NintendoAccount from './NintendoAccount';
import SelectableList from './SelectableList';

const TestDeliveryStateRecord = new Record({
  directories: new SelectableList(),
  nintendoAccounts: new SelectableList(),
  devices: new SelectableList(),
  currentDirectory: undefined,
  name: '',
  fileDirectoryMap: new Map(),
  prevPath: '',
  dataSizeLimit: undefined,
  errors: {},
});

export default class TestDeliveryState extends TestDeliveryStateRecord {
  static PropTypes = recordOf({
    directories: SelectableList.PropTypes.isRequired,
    nintendoAccounts: SelectableList.PropTypes.isRequired,
    devices: SelectableList.PropTypes.isRequired,
    name: PropTypes.string.isRequired,
    prevPath: PropTypes.string.isRequired,
    dataSizeLimit: PropTypes.number,
    errors: PropTypes.object,
  })
  constructor({ directories = [], nintendoAccounts = [], devices = [], name = '', prevPath = '', dataSizeLimit = undefined} = {}) {
    super({
      directories: new SelectableList(directories, Directory),
      devices: new SelectableList(devices, Device),
      nintendoAccounts: new SelectableList(nintendoAccounts, NintendoAccount),
      name,
      dataSizeLimit,
      prevPath,
    });
    // Rails contextから実データが渡ってきたときに通る
    if (directories[0] !== undefined) {
      const fileDirectoryMap = this.directories.valueSeq().reduce((acc, dir) => acc.merge(dir.fileDirectoryMap()), new Map());
      return this.selectDirectory(directories[0].id).set('fileDirectoryMap', fileDirectoryMap);
    }
    // Initial State
    return this;
  }
  getCurrentDirectory() {
    return this.directories.get(this.currentDirectory);
  }
  // ディレクトリの選択だけ排他な選択処理になる
  selectDirectory(id) {
    const newDirectories = this.directories.selectExclusive(id);
    return this.set('directories', newDirectories).set('currentDirectory', id);
  }
  toggleData(id) {
    // EDIT画面で初期状態をセットするとき、currentDirectoryが定まらない
    const directoryId = this.fileDirectoryMap.get(id);
    if (directoryId === undefined) { return this; }
    const newDirectory = this.directories.get(directoryId).toggleData(id);
    const newDirectories = this.directories.set(directoryId, newDirectory);
    return this.set('directories', newDirectories);
  }
  unselectData(id) {
    // 選択中のデータ削除はcurrentDirectoryが定まらない
    const directoryId = this.fileDirectoryMap.get(id);
    if (directoryId === undefined) { return this; }
    const newDirectory = this.directories.get(directoryId).unselectData(id);
    const newDirectories = this.directories.set(directoryId, newDirectory);
    return this.set('directories', newDirectories);
  }
  updateDevice(ids) {
    const reducer = (acc, id) => acc.set('devices', acc.devices.select(id));
    return ids.reduce(reducer, this.set('devices', this.devices.clear()));
  }
  updateNintendoAccount(ids) {
    const reducer = (acc, id) => acc.set('nintendoAccounts', acc.nintendoAccounts.select(id));
    return ids.reduce(reducer, this.set('nintendoAccounts', this.nintendoAccounts.clear()));
  }
  toggleVirtualFile(id) {
    const newDirectory = this.directories.get(this.currentDirectory).toggleVirtualFile(id);
    const newDirectories = this.directories.set(this.currentDirectory, newDirectory);
    return this.set('directories', newDirectories);
  }
  selectDataExclusive(id) {
    const newDirectory = this.directories.get(this.currentDirectory).selectDataExclusive(id);
    const newDirectories = this.directories.set(this.currentDirectory, newDirectory);
    return this.set('directories', newDirectories);
  }
  updateName(name) {
    return this.set('name', name);
  }
  isValid() {
    if (this.name.length === 0 ) {
      return false;
    } else {
      return this.isValidDataSize();
    }
  }
  isValidDataSize() {
    return this.dataSizeLimit === undefined || this.dataSizeLimit === null || this.getDeliveryDataSize() <= this.dataSizeLimit;
  }
  getDeliveryDataSize() {
    return this.directories.map(
      dir => dir.deliverables.valueSeq().filter(
        file=>file.selected
      ).map(
        file=>file.binary_file_size
      ).reduce((acc, e) => (acc + e),0)
    ).reduce((acc,e) => (acc + e), 0)
  }
}

import React from 'react';

import Entrance from './sharedUnpackeNewsComponents/Entrance';
import NewsListItem from './sharedUnpackeNewsComponents/NewsListItem';
import NewsListItemSearched from './sharedUnpackeNewsComponents/NewsListItemSearched';
import NewsListItemUnread from './sharedUnpackeNewsComponents/NewsListItemUnread';

interface IProps {
  listImage: string;
  subjectText: string;
  iconImage: string;
  isCaptionDisplay: boolean;
}

export default class ListImagePreviews extends React.PureComponent<IProps> {
  public render() {
    const { listImage, subjectText, iconImage, isCaptionDisplay } = this.props;

    return (
      <div className="list-image-previews">
        <Entrance
          listImage={listImage}
          text={isCaptionDisplay ? subjectText : ''}
        />
        <NewsListItem
          listImage={listImage}
          text={subjectText}
          iconImage={iconImage}
        />
        <NewsListItemSearched
          listImage={listImage}
          text={subjectText}
        />
        <NewsListItemUnread
          listImage={listImage}
          text={subjectText}
          iconImage={iconImage}
        />
      </div>
    );
  }
}

import React from 'react';

interface IProps {
  listImage: string;
}

const ListImage = (props: IProps) => (
  <div className="list-image-box">
    <img
      role="presentation"
      className="list-image"
      src={`data:image/jpeg;base64,${props.listImage}`}
    />
  </div>
);

export default ListImage;

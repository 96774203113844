import React from 'react';
import PropTypes from 'prop-types';

import I18n from '../../lib/i18n';
import {
  SimpleFormCheckbox,
} from '../../lib/components/SimpleForm';
import { SelectableList } from '../../models';

export default class VirtualFileSelector extends React.PureComponent {
  static propTypes = {
    directories: SelectableList.PropTypes.isRequired,
    selectDataExclusive: PropTypes.func.isRequired,
    toggleVirtualFile: PropTypes.func.isRequired,
  }
  static contextTypes = {
    timezone: PropTypes.string,
  }

  renderDataRow(file, display) {
    const { selectDataExclusive } = this.props;
    const dateObj = new Date(file.publishing_time);
    const date = dateObj.toLocaleDateString(I18n.locale, { timeZone: this.context.timezone });
    const time = dateObj.toLocaleTimeString(I18n.locale, { timeZone: this.context.timezone });
    return (
      <tr key={`data_${file.id}`} className={display ? '' : 'datafiles_hidden'}>
        <td />
        <td className="data_td_checkbox">
          <SimpleFormCheckbox
            name="data_list[deliverable_ids][]"
            controlId={`test_data_${file.id}`}
            label={file.label()}
            checked={file.selected}
            value={file.id.toString()}
            collection
            onChange={() => selectDataExclusive(file.id)}
          />
        </td>
        <td><p className="testData_statusValue">{`${file.binary_file_size} byte`}</p></td>
        <td><p className="testData_statusValue">{file.status}</p></td>
        <td><p className="testData_dateShowValue">{`${date} ${time}`}</p></td>
      </tr>
    );
  }
  renderVirtualFileRow(virtualFile) {
    const { toggleVirtualFile } = this.props;
    const rowClass = virtualFile.selected ? 'virtualFileSelector_expanded' : 'virtualFileSelector_closed';
    return (
      <tr key={`vf_${virtualFile.id}`} onClick={() => toggleVirtualFile(virtualFile.id)}>
        <td className={rowClass} >{virtualFile.filename}</td>
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }
  renderTableBodies() {
    return this.props.directories.map((dir) => {
      const attributes = dir.selected ? { id: 'select_item' } : { className: 'select_tableBody_hidden' };
      const rows = dir.virtualFiles.map((vf) => {
        const vfRow = this.renderVirtualFileRow(vf);
        const dataRows = dir.filterDatafileByVirtualFile(vf.id).map(
          datafile => this.renderDataRow(datafile, vf.selected)
        );
        return [vfRow, ...dataRows];
      });
      return (
        <tbody key={dir.id} {...attributes}>
          { rows }
        </tbody>
      );
    });
  }
  render() {
    return (
      <table className="table table-striped text-center sortTable">
        <thead>
          <tr>
            <th className="">{I18n.t('activerecord.attributes.datafile.filename')}</th>
            <th className="">{I18n.t('activerecord.attributes.datafile.summary')}</th>
            <th/>
            <th className="">{I18n.t('activerecord.attributes.datafile.status')}</th>
            <th className="">{I18n.t('activerecord.attributes.datafile.publishing_time')}</th>
          </tr>
        </thead>
        { this.renderTableBodies() }
      </table>
    );
  }
}

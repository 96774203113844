import * as React from 'react';
import { actions } from '../../../../ducks/MessageForm';
import { SimpleFormLabel } from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';
import SortableList from './SortableList';

interface IProps {
  index: number;
  parentFormName: string;
  $$messageDetail: MessageDetail;
}
interface IState {
  movieUrl: string;
  updateKey: string[];
}
export default class RelatedMoviesForm extends React.PureComponent<IProps, IState> {
  private dispatch = getDispatch();

  constructor(props: IProps) {
    super(props);
    this.state = {
      movieUrl: '',
      updateKey: ['messageDetails', props.index.toString(), 'related_movies'],
    };
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    const { updateKey: key } = this.state;
    this.dispatch(actions.switchMovies({ key, oldIndex, newIndex }));
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ movieUrl: e.target.value });
  }

  public handleClick = () => {
    const { updateKey: key, movieUrl } = this.state;

    this.dispatch(actions.addMovie({ key, movieUrl }));
    this.setState({ movieUrl: '' });
  }

  public render() {
    const { $$messageDetail, parentFormName } = this.props;
    const { updateKey, movieUrl } = this.state;
    const { related_movies: relatedMovies } = $$messageDetail;
    const { onSortEnd } = this;

    return (
      <div style={{ marginBottom: '25px' }}>
        <SimpleFormLabel
          label={I18n.t('activerecord.attributes.message_detail.related_movies')}
          errorMessage={Object.values(relatedMovies.errors)}
          popoverText={I18n.t('message_details.tooltips.related_movies')}
        >
          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" value={movieUrl} onChange={this.handleChange} />
            </div>
            <div className="col-md-6" style={{ marginBottom: '10px' }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleClick}
              >
                {I18n.t('common.action.add')}
              </button>
            </div>
          </div>
        </SimpleFormLabel>
        <SortableList
          {...{ relatedMovies, onSortEnd, parentFormName, updateKey }}
          useDragHandle={true}
          axis="xy"
        />
      </div>
    );
  }
}

import * as React from 'react';
import { Modal } from 'react-bootstrap';
import AttachmentForm from '../../lib/components/AttachmentForm';
import {
  SimpleFormDateTime,
  SimpleFormString,
} from '../../lib/components/SimpleForm';
import I18n from '../../lib/i18n';
import Data from '../../models/DistributionFile/Data';
import { DistributionEditorProps } from './index';

interface IDataEditViewProps {
  data: Data;
  canSubmit: boolean;
  canExpire: boolean;
  updateData: DistributionEditorProps['updateData'];
  submitData: DistributionEditorProps['submitData'];
  endEditData: DistributionEditorProps['endEditData'];
  setFile: DistributionEditorProps['setFile'];
  deleteFile: DistributionEditorProps['deleteFile'];
}

export default class DataEditView extends React.PureComponent<IDataEditViewProps> {
  public handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { setFile, deleteFile } = this.props;
    if (e.currentTarget.files && e.currentTarget.files.length) {
      const file = e.currentTarget.files[0];
      setFile({ dataId: this.props.data.id, file, name: file.name });
    } else {
      deleteFile({ dataId: this.props.data.id });
    }
  }
  public handleUpdate = (key: string) => (e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement> | Event) => {
    if (e.target && 'value' in e.target) {
      this.props.updateData({ dataId: this.props.data.id, key, value: e.target.value });
    }
  }
  public handleClose() { return null; }
  public submitData = () => this.props.submitData();
  public endEditData = () => this.props.endEditData();

  public renderBody() {
    const { data, canExpire } = this.props;
    const disabled = !data.dataEditable();
    return (
      <div>
        <div className="fields datalistPeriod_modal__box">
          <div className="form-group file optional virtual_file_datafiles_binary_contents">
            <AttachmentForm
              required={true}
              allowDestroy={false}
              label={I18n.t('activerecord.models.datafile')}
              $$attachment={data.binary}
              onChange={this.handleFileChange}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="form-group string optional virtual_file_datafiles_summary datalistPeriod_modal__box">
          <SimpleFormString
            label={I18n.t('activerecord.attributes.datafile.summary')}
            value={data.summary || ''}
            errorMessage={data.errors.summary}
            popoverText=""
            onChange={this.handleUpdate('summary')}
            disabled={disabled}
          />
        </div>
        <div className="flex_column datalistPeriod_modal__box">
          <div className="form-group datetime virtual_file_datafiles_publishing_time">
            <SimpleFormDateTime
              required={true}
              label={I18n.t('activerecord.attributes.datafile.publishing_time')}
              value={data.publishingTime || ''}
              errorMessage={data.errors.publishing_time}
              popoverText=""
              onChange={this.handleUpdate('publishingTime')}
              disabled={disabled}
            />
          </div>
          <div className="form-group datetime optional virtual_file_datafiles_expiration_time">
            <SimpleFormDateTime
              label={I18n.t('activerecord.attributes.datafile.expiration_time')}
              value={data.expirationTime || ''}
              errorMessage={data.errors.expiration_time}
              popoverText=""
              onChange={this.handleUpdate('expirationTime')}
              disabled={disabled && !canExpire}
            />
          </div>
        </div>
      </div>
    );
  }
  public render() {
    const { canSubmit } = this.props;
    return (
      <Modal backdrop="static" show={true} onHide={this.handleClose}>
        <Modal.Body>
          { this.renderBody() }
        </Modal.Body>
        <Modal.Footer>
          <div className="flex_column datalistPeriod_modal__btnBox">
            <button
              className="btn btn-default btn btn-primary"
              disabled={!canSubmit}
              onClick={this.submitData}
            >
              {I18n.t('common.action.update')}
            </button>
            <button
              className="btn btn-default datalistPeriod_modal__cancel"
              onClick={this.endEditData}
            >
              {I18n.t('common.back')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
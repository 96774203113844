import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import {
  SimpleFormLabel,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import notify from '../../../lib/notify';
import { IRootState } from '../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    $$editableLanguages: state.$$formStore.editableLanguages,
    $$countries: state.$$formStore.message.countries,
    $$ratingList: state.$$formStore.ratingList,
    semanticsVersion: state.$$formStore.message.semantics_version,
  };
}

type Props = ReturnType<typeof mapStateToProps>;

class SuggestRatingForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleSubmit = () => {
    const { $$editableLanguages, $$ratingList, $$countries } = this.props;
    $$editableLanguages.forEach(language => {
      const ratings = $$ratingList.getSuggestedRatings($$countries, language);
      this.dispatch(actions.applySuggestRatings({ language, ratings }));
    });
    notify({
      title: I18n.t('rating_systems.form.suggest.label'),
      message: I18n.t('common.notice.updated'),
      type: 'info',
    });
  }

  public render() {
    const { $$countries, $$editableLanguages, semanticsVersion, $$ratingList } = this.props;
    if (!$$ratingList.isAbleToSuggest($$countries, $$editableLanguages, semanticsVersion)) { return <div />; }

    return (
      <div style={{ marginBottom: '5px' }}>
        <SimpleFormLabel
          label={I18n.t('rating_systems.form.suggest.label')}
          popoverText={I18n.t('rating_systems.tooltip.suggest')}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleSubmit}
          >
            {I18n.t('rating_systems.form.suggest.apply')}
          </button>
        </SimpleFormLabel>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SuggestRatingForm);

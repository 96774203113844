import { List, Map, Record } from 'immutable';
import { compact } from 'lodash';
import FeaturedComment from './FeaturedComment';

export interface IFeaturedProductDetailRecord {
  id?: number;
  ns_uid: string;
  name: string;
  discount: boolean;
  regular_price: string;
  discount_price: string;
  comments: Map<string, FeaturedComment>;
  additional_labels: string[];
  errors: Map<string, string[]>;
  destroy: boolean;
}
const defaultValue: IFeaturedProductDetailRecord = {
  id: undefined,
  ns_uid: '',
  name: '',
  discount: false,
  regular_price: '',
  discount_price: '',
  comments: Map(),
  additional_labels: [],
  errors: Map(),
  destroy: false,
};

export default class FeaturedProductDetail extends Record(defaultValue) {
  public static additionalLabelOptions: Array<[string, string]> = [
    ['NEW', 'new'],
    ['Pre-order', 'pre_purchase'],
  ];

  public static convertLanguageISO(language: string): string {
    if (language.length === 2) { return language; }
    switch (language) {
      case 'en-US':
      case 'en-GB':
        return 'en';
      case 'es-419':
        return 'es';
      case 'fr-CA':
        return 'fr';
      case 'pt-BR':
        return 'pt';
      case 'zh-Hans':
      case 'zh-Hant':
        return 'zh';
      default:
        return 'en';
    }
  }
  constructor(object: any = defaultValue) {
    const newObject = object;
    if (object.errors) { newObject.errors = Map(object.errors); }
    if (object.comments) { newObject.comments = Map(object.comments.map((c: any) => [c.language, new FeaturedComment(c)])); }
    newObject.discount = !!object.regular_price;
    super(newObject);
  }

  // mutations
  public updateDetail(key: keyof IFeaturedProductDetailRecord, value: ValueOf<IFeaturedProductDetailRecord>): FeaturedProductDetail {
    return this.set(key, value).validate(key);
  }
  public updateComment(key: string, language: string, value: string): FeaturedProductDetail {
    return this.updateIn(['comments', language], comment => comment.updateComment(key, value));
  }
  public createNewComments(languages: (string[] | List<string>)) {
    const newComments: Map<string, FeaturedComment> = Map(
      (languages as string []).map((language: string): [string, FeaturedComment] => (
        [language, this.comments.get(language, new FeaturedComment({ language }))])
      )
    );
    return this.update('comments', comments => comments.merge(newComments));
  }
  public validate(key: keyof IFeaturedProductDetailRecord) {
    const value = this.get(key);
    if (typeof value !== 'string') { return this; }
    // FIXME: 本当はメンバ変数に長さの制限情報を持ちたかったが、FeaturedProductは様々な場所で初期化されるので苦肉の策
    const maxLength: number | undefined = (window as any).Constants.validators.message.featuredProduct[key];
    if (maxLength === undefined) { return this; }
    // 長さオーバーしているときにはnullを突っ込み、解消されたらcompactでnullだけ消す処理
    // this.errors[key]がundefinedなケースがあるのでその場合は[null]を代入します
    if (value.length > maxLength) {
      return this.updateIn(['errors', key], messages => messages ? messages.concat(null) : [null]);
    }
    return this.updateIn(['errors', key], messages => compact(messages));
  }
  // read methods
  public getError(column: string): string[] | undefined {
    return this.errors.get(column);
  }
  public shouldDestroy(): boolean {
    return this.id !== null && this.destroy;
  }
  public getLength(column: TypePropNames<FeaturedProductDetail, string>): string {
    if (column === undefined) { return ''; }
    return `${this.get(column, '').length}/${(window as any).Constants.validators.message.featuredProduct[column]}`;
  }
  public toggleDiscount() {
    if (this.discount) {
      return this.set('discount_price', this.regular_price).set('discount', false);
    }
    return this.merge({
      regular_price: this.discount_price,
      discount_price: '',
    }).set('discount', true);
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Immutable from 'immutable';
import BelongsCountryWidget from './BelongsCountryWidget';
import BelongsCountrySelector from './BelongsCountrySelector';
import { actions } from '../../ducks/BelongsCountry';

import I18n from '../../lib/i18n';
import { SimpleFormLabel } from '../../lib/components/SimpleForm';

function mapStateToProps(state) {
  return {
    $$belongsCountryStore: state.belongsCountryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(actions, dispatch),
  };
}

const BelongsCountry = (props) => {
  const { $$belongsCountryStore, updateSelectedCountry, errorMessage } = props;
  const countries = $$belongsCountryStore.get('countries');
  const countriesInitial = $$belongsCountryStore.get('countriesInitial');

  return (
    <SimpleFormLabel
      label={I18n.t('activerecord.attributes.country_group.countries')}
      errorMessage={errorMessage}
    >
      <BelongsCountrySelector {...{ countriesInitial }} />
      <BelongsCountryWidget {...{ countries, countriesInitial, updateSelectedCountry }} />
    </SimpleFormLabel>
  );
};

BelongsCountry.propTypes = {
  $$belongsCountryStore: PropTypes.instanceOf(Immutable.Map).isRequired,
  updateSelectedCountry: PropTypes.func.isRequired,
  errorMessage: PropTypes.arrayOf(PropTypes.string),
};

const BelongsCountryContainer = connect(mapStateToProps, mapDispatchToProps)(BelongsCountry);
export default BelongsCountryContainer;

import * as React from 'react';
import I18n from '../../../../../lib/i18n';

interface IProps {
  headerString: string;
}

interface IState {
  valid: boolean;
}

export default class HeaderSimulator extends React.PureComponent<IProps, IState> {
  public headerSpan: HTMLSpanElement | null;
  public state = {
    valid: true
  };

  public validateHeight() {
    if (this.headerSpan === null) { throw Error('HeaderSimulator#validateHeight: headerSpan is null.'); }
    const height = this.headerSpan.getBoundingClientRect().height;

    // Switchでの表示を再現すると1行あたり25px、2行で50pxになります。
    // 50pxを超えている = 3行を超えている、とみなせるので
    // heightが50を超えた場合はアラートを表示するようにします。
    if (height > 50) {
      this.setState({valid: false});
    } else {
      this.setState({valid: true});
    }
  }

  public componentDidMount() {
    this.validateHeight();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.headerString !== prevProps.headerString) {
      this.validateHeight();
    }
  }

  public renderError() {
    if (this.state.valid) {
      return null;
    }

    return(
      <div className="has-error">
        <span className="help-block header-validator-error">
          {I18n.t('messages.form.too_long_header', {header: `<header>${this.props.headerString}</header>`})}
        </span>
      </div>
    );
  }

  // Switch上での描画をシミュレーションするためのspan
  // cssを使って幅はあるけれど表示はしないようにしています。
  // 詳細はapplicaiton.scssのheader-validator-calcuratorを参照してください。
  public renderSimulator() {
    const { headerString } = this.props;
    return(
      <span
        className="header-validator-calcurator"
        ref={(c) => { this.headerSpan = c; }}
      >
        {headerString}
      </span>
    );
  }

  public render() {
    return(
      <div>
        {this.renderSimulator()}
        {this.renderError()}
      </div>
    );
  }
}

import * as React from 'react';
import { List } from 'immutable';
import {
  SortableContainer,
} from 'react-sortable-hoc';

import Product from '../../../models/Product';
import ProductList from '../../../models/ProductList';
import ProductRow from './ProductRow';
import { ICountry } from '../../../models/Message';

interface IProps {
  parentFormName: string;
  sortProducts: ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => void;
  unselectProduct: (id: string) => void;
  $$products: ProductList;
  countries: List<ICountry>;
}
const SortableList = SortableContainer(({
  $$products, countries, parentFormName, draggedIndex, unselectProduct,
}: {
  $$products: IProps['$$products'], countries, parentFormName: IProps['parentFormName'], unselectProduct: IProps['unselectProduct'], draggedIndex: number,
}) => {
  let maxLengthStyle = $$products.getSelectedMaxLength();
  const { maxLength } = Product;
  maxLengthStyle = maxLengthStyle + 1 <= maxLength ? maxLengthStyle + 1 : maxLength;
  return (
    <div className="product-table">
      {$$products.filterSelectedWithOrder().map(({ index, product }) => (
        <ProductRow
          key={`more-shop-query-list-${product.ns_uid}`}
          {...{ product, countries, parentFormName, maxLengthStyle, draggedIndex, unselectProduct, index }}
          productIndex={index}
        />
      )
      )}
    </div>
  );
});

interface IState {
  draggedIndex: number;
}

export default class ProductDragContainer extends React.PureComponent<IProps, IState> {
  public state = {
    draggedIndex: -1,
  };

  public onSortStart = ({ index }: { index: number }) => {
    this.setState({ draggedIndex: index });
  }

  public onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.props.sortProducts({ oldIndex, newIndex });
    this.setState({ draggedIndex: -1 });
  }

  public render() {
    const { $$products, countries, parentFormName, unselectProduct } = this.props;
    const { draggedIndex } = this.state;
    const { onSortStart, onSortEnd } = this;
    return (
      <SortableList
        {...{ $$products, countries, parentFormName, draggedIndex, onSortStart, onSortEnd, unselectProduct }}
        useDragHandle={true}
      />
    );
  }
}

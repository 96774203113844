import PropTypes from 'prop-types';
import { Record } from 'immutable';
import { recordOf } from 'react-immutable-proptypes';

const DeviceRecord = new Record({
  id: undefined,
  name: undefined,
  serial: undefined,
  selected: false,
});

export default class Device extends DeviceRecord {
  static PropTypes = recordOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    serial: PropTypes.string.isRequired,
    selected: PropTypes.bool,
  })
  toggle() {
    return this.set('selected', !this.selected);
  }
  select() {
    return this.set('selected', true);
  }
  unselect() {
    return this.set('selected', false);
  }
  label() {
    return `${this.serial} (${this.name})`;
  }
}

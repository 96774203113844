import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../../ducks/MessageForm';
import AttachmentForm from '../../../../lib/components/AttachmentForm';
import {
  SimpleFormHidden,
  SimpleFormTextarea,
} from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import isKeydownClick from '../../../../lib/isKeydownClick';
import MainContent from '../../../../models/MainContent';
import { IRootState } from '../../../../store/MessageForm';
import MainMovieForm from '../MainMovieForm';
import HeaderValidator from './HeaderValidator';

function mapStateToProps(state: IRootState) {
  return {
    semanticsVersion: state.$$formStore.message.semantics_version,
  };
}

interface IProps {
  index: number;
  mainContent: MainContent;
  mainContentIndex: number;
  isLastMainContent: boolean;
  totalBodyLength: number;
}

interface IState {
  headerStrings: string[];
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

class MainContentForm extends React.PureComponent<Props, IState> {
  public state = {
    headerStrings: [],
  };

  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // name = message[message_details_attributes][1][main_contents][1][body]から
    // key = ["messageDetails", 1,  "main_contents", 1, "body"]を作る必要がある
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const { index } = this.props;
    const { updateFormStore, validateForm, validateMainContentsBody } = actions;
    const value = e.target.value;
    this.dispatch(updateFormStore({ key, value }));
    this.dispatch(validateForm({ key }));
    this.dispatch(validateMainContentsBody(index));
  }

  public componentDidMount = () => {
    this.updateHeaderStrings();
  }

  public updateHeaderStrings = () => {
    const body = this.props.mainContent.body;

    if (body === null) {
      return;
    }

    const headersRegexp = /<header>([\s\S]*?)<\/header>/gm;
    const headers = body.match(headersRegexp);
    if (headers === null) {
      this.setState({headerStrings: []});
      return;
    }

    const headerStrings = headers.map((header: string) => (header.replace(/<\/?header>/g, '')));
    this.setState({headerStrings});
  }

  public handleRemoveContentBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isKeydownClick(e)) { return; }
    const { index } = this.props;
    const { removeMainContent } = actions;
    this.dispatch(removeMainContent(index));
  }

  /*
   * MainContentの削除ボタンは、最後のMainContentのみで表示。
   * ただし、MainContentがただ一つの場合は表示しない。
   */
  public renderRemoveContentBtn() {
    const { isLastMainContent } = this.props;
    if (isLastMainContent) {
      return (
        <button
          className="btn btn-danger"
          onClick={this.handleRemoveContentBtn}
        >
          {I18n.t('message_details.form.remove_main_content')}
        </button>
      );
    }
    return null;
  }

  public render() {
    const { index, mainContent, mainContentIndex, totalBodyLength, semanticsVersion } = this.props;
    const { headerStrings } = this.state;

    let bodyHelpMessage;
    if (semanticsVersion < 6) {
      bodyHelpMessage = I18n.t('message_details.hints.strong_tag_is_available');
    } else if (semanticsVersion === 6) {
      bodyHelpMessage = I18n.t('message_details.hints.strong_and_header_tags_are_available');
    } else {
      bodyHelpMessage = I18n.t('message_details.hints.strong_header_and_ita_tags_are_available');
    }

    let bodyPopoverMessage;
    if (semanticsVersion < 6) {
      bodyPopoverMessage = I18n.t('main_contents.tooltips.body');
    } else if (semanticsVersion === 6) {
      bodyPopoverMessage = I18n.t('main_contents.tooltips.body_v6');
    } else {
      bodyPopoverMessage = I18n.t('main_contents.tooltips.body_v7_or_later');
    }

    if (mainContent._destroy) {
      return (
        <div>
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][main_contents_attributes][${mainContentIndex}][id]`}
            value={mainContent.id || ''}
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][main_contents_attributes][${mainContentIndex}][_destroy]`}
            value="true"
          />
        </div>
      );
    }

    return(
      <div className="bs-callout bs-callout-info">
        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][main_contents_attributes][${mainContentIndex}][id]`}
          value={mainContent.id || ''}
        />

        {/* メイン画像 */}
        <AttachmentForm
          label={I18n.t('main_contents.form.main_image', { index: mainContentIndex + 1 })}
          attachmentName={`message[message_details_attributes][${index}][main_contents_attributes][${mainContentIndex}][main_image_attributes]`}
          attachmentType="message_main_image"
          helpMessage={mainContent.main_movie_attributes.movies.isEmpty() ? I18n.t('activerecord.hints.message_detail.main_image') : I18n.t('activerecord.hints.message_detail.main_image_with_movie')}
          popoverText={I18n.t('message_details.tooltips.main_image')}
          $$attachment={mainContent.main_image_attributes}
        />

        {/* メイン動画*/}
        <MainMovieForm
          label={I18n.t('main_contents.form.main_movie', {index: mainContentIndex + 1})}
          index={index}
          parentFormName={`message[message_details_attributes][${index}][main_contents_attributes][${mainContentIndex}]`}
          parentResource={mainContent}
        />

        {/* ニュースの本文 */}
        <SimpleFormTextarea
          name={`message[message_details_attributes][${index}][main_contents_attributes][${mainContentIndex}][body]`}
          label={I18n.t('main_contents.form.body', { index: mainContentIndex + 1 })}
          value={mainContent.body || ''}
          errorMessage={mainContent.errors.body}
          helpMessage={`${totalBodyLength}/${mainContent.validators.body.maximum} ${bodyHelpMessage}`}
          popoverText={bodyPopoverMessage}
          rows={10}
          onChange={this.handleChange}
          onBlur={this.updateHeaderStrings}
          deviceFont={true}
        />
        <HeaderValidator headerStrings={headerStrings}/>
        { this.renderRemoveContentBtn() }
      </div>
    );
  }
}

export default connect(mapStateToProps)(MainContentForm);

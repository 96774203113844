import * as React from 'react';
import { connect } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import { actions } from '../../../../ducks/MessageForm';
import {
  SimpleFormHidden,
  SimpleFormString,
} from '../../../../lib/components/SimpleForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import Movie from '../../../../models/Movie';
import { IRootState } from '../../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    timezone: state.$$formStore.timezone,
  };
}

const DragHandle = SortableHandle(({ movie }: { movie: Movie }) => (
  <div style={{ cursor: 'move', marginBottom: '5px' }}>
    <i className="glyphicon glyphicon-menu-hamburger" />
    <img src={movie.image} className="card-img-top" role="presentation" />
  </div>
));

export interface IMovieInputFormProps {
  updateKey: string[];
  movieIndex: number;
  formName: string;
  movie: Movie;
  allNullOrFilledNames: boolean;
}

type Props = IMovieInputFormProps & ReturnType<typeof mapStateToProps>;

class InputForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public componentWillMount() {
    const { updateKey: prefix, movieIndex: index, movie } = this.props;
    const digest = movie.digest();
    if (digest === undefined) { return; }
    this.dispatch(actions.fetchMovieImage({ prefix, index, digest }));
    this.dispatch(actions.fetchMovieSchedule({ prefix, index, digest }));
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { updateKey: prefix, movieIndex: index } = this.props;
    const key = 'name';
    const { value } = e.target;
    this.dispatch(actions.updateMovie({ prefix, index, key, value }));
  }

  public handleClick = (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { deleteMovie } = actions;
    const { updateKey: key } = this.props;
    this.dispatch(deleteMovie({ key, index }));
  }

  public render() {
    const { movie, movieIndex, formName, timezone, allNullOrFilledNames } = this.props;
    const { _destroy: destroy } = movie;
    const lengths: { [s: string]: string } = {};
    Object.keys(movie.validators).forEach(key => {
      const length = movie[key] ? movie[key].length : 0;
      lengths[key] = `${length}/${movie.validators[key].maximum}`;
    });
    movie.validateName();

    const nameErrors =
      allNullOrFilledNames ?
        movie.errors.name :
        [].concat(
          movie.errors.name || [],
          [I18n.t('activerecord.errors.message_detail.related_movies.invalid_names')]
        );

    return (
      <div className="col-md-4 card" style={{ display: destroy ? 'none' : '' }}>
        <div className="card-block">
          <DragHandle {...{ movie }} />
          <SimpleFormString
            name={`${formName}[name]`}
            label={I18n.t('activerecord.attributes.message_detail.movie_name')}
            value={movie.name || ''}
            popoverText={I18n.t('message_details.tooltips.movie_name')}
            helpMessage={lengths.name}
            onChange={this.handleChange}
            errorMessage={nameErrors}
          />
          <a href={`${(window as any).Constants.ncmsVideoUrl}?id=${movie.ncms_video_id}`} target="_blank" rel="noreferrer">
            {I18n.t('message_details.form.view_on_ncms')}
          </a>
          <SimpleFormHidden
            name={`${formName}[ncms_video_id]`}
            value={movie.ncms_video_id || ''}
          />
          <SimpleFormString
            name={`${formName}[start_datetime]`}
            label={I18n.t('activerecord.attributes.movie.start_datetime')}
            value={movie.humanStartDatetime(timezone)}
            readOnly={true}
          />
          <SimpleFormString
            name={`${formName}[end_datetime]`}
            label={I18n.t('activerecord.attributes.movie.end_datetime')}
            value={movie.humanEndDatetime(timezone)}
            readOnly={true}
          />
          <pre className="card-text"><code>{movie.url}</code></pre>
          <input name={`${formName}[_destroy]`} type="hidden" value={destroy ? '1' : '0'} />
          <input name={`${formName}[id]`} type="hidden" value={movie.id || ''} />
          <input name={`${formName}[url]`} type="hidden" value={movie.url} />
          <input name={`${formName}[order]`} type="hidden" value={movieIndex} />
          <button className="btn btn-danger" onClick={this.handleClick(movieIndex)}>
            {I18n.t('common.action.destroy')}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(InputForm);

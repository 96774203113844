import $ from 'jquery';

$(document).on('turbolinks:load', function(){
  $('a[href^="#"][data-role="scroll"]').click(function(){
    var speed = 400;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == '' ? 'html' : href);
    var position = target.offset().top - 100;

    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
});
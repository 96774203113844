import React from 'react';
import { connect } from 'react-redux';
import { getDispatch } from '../../lib/dispatchExporter';

import * as ducks from '../../ducks/MessagePreview';

interface IProps {
  languagesAndIds: Array<{key: string}>;
  activeLanguage: string;
}

function mapStateToProps(state: any) {
  return {
    languagesAndIds: state.$$previewStore.get('languagesAndIds'),
    activeLanguage: state.$$previewStore.get('activeLanguage'),
  };
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

export class LanguageSelector extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const { languagesAndIds } = this.props;
    const aTag = e.target as HTMLAnchorElement;
    const language = aTag.text;
    this.dispatch(ducks.actions.changeActiveLanguage(language));
    this.dispatch(ducks.actions.fetchUnpackedNewsRequest(languagesAndIds[language]));
  }

  public renderButton(language: string) {
    const { activeLanguage } = this.props;
    const btnClass = activeLanguage === language ? 'btn btn-default active disabled' : 'btn btn-default';

    return (
      <a className={btnClass} key={language} onClick={this.handleClick}>
        {language}
      </a>
    );
  }

  public render() {
    const { languagesAndIds } = this.props;
    const buttons = Object.keys(languagesAndIds).map((language) => (
      this.renderButton(language)
    ));

    return (
      <div className="btn-group" role="group">
        { buttons }
      </div>
    );
  }
}

export const LanguageSelectorContainer = connect(mapStateToProps)(LanguageSelector);
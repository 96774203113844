import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import { SimpleFormSelect, } from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import FeaturedProductDetail from '../../../models/FeaturedProductDetail';
import { IRootState } from '../../../store/MessageForm';
import FeaturedProductForm from './FeaturedProductForm';

function mapStateToProps(state: IRootState) {
  return {
    selectableCountries: state.$$formStore.message.countries.map((e: any) => e.iso2).toArray(),
  };
}

interface IProps {
  productDetails: List<FeaturedProductDetail>;
  language: string;
  languageIndex: number;
  semanticsVersion: number;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
interface IState {
  selectedCountry: string;
  isFetching: boolean;
}

class FeaturedProductFormList extends React.PureComponent<Props, IState> {
  private dispatch = getDispatch();

  constructor(props: Props) {
    super(props);

    this.state = {
      isFetching: false,
      selectedCountry: props.selectableCountries[0],
    };
  }

  public handleChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedCountry: e.target.value });
  }

  public handleAllFetch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { productDetails, language } = this.props;
    const { selectedCountry: country } = this.state;
    const nsUids = productDetails.map(detail => detail.ns_uid);
    this.setState({ isFetching: true });
    nsUids.forEach(nsUid => {
      this.dispatch(actions.fetchPriceRequest({ language, country, nsUid, _this: this }));
    });
  }

  public render() {
    const { productDetails, language, languageIndex, selectableCountries, semanticsVersion } = this.props;
    const { selectedCountry, isFetching } = this.state;
    return (
      <div>
        <div className="col-md-12">
          <div className="col-md-4">
            <SimpleFormSelect
              label={I18n.t('featured_products.country_price.label')}
              values={selectableCountries.map<[string, string]>(c => [c, c])}
              value={selectedCountry}
              popoverText={I18n.t('featured_products.country_price.tooltip')}
              onChange={this.handleChangeCountry}
            />
          </div>
          <div className="col-md-8">
            <button
              style={{ marginTop: '30px' }}
              className="btn btn-primary"
              disabled={isFetching}
              onClick={this.handleAllFetch}
            >
              {I18n.t('featured_products.action.fetch_all')}
            </button>
          </div>
        </div>
        <div className="col-md-12">
          {productDetails.map(
            (detail, index) => <FeaturedProductForm key={detail.ns_uid}
              {...{ detail, selectedCountry, language, languageIndex, index, semanticsVersion }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FeaturedProductFormList);

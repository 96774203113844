import { Record } from 'immutable';

const defaultValue: {
  id: number;
  status: string;
  summary: string;
  region: string;
  publishing_time: Date;
} = {
  id: 0,
  status: '',
  summary: '',
  region: '',
  publishing_time: new Date(),
};

export default class Calendar extends Record(defaultValue) {
  constructor(obj: any) {
    super(obj);
  }

  public get color() {
    switch (this.status) {
      case 'draft': {
        return '#777777';
      }
      case 'review_requesting': {
        return '#5cb85c';
      }
      case 'censor_requesting': {
        return '#f0ad4e';
      }
      case 'tencent_censor_requesting': {
        return '#f0ad4e';
      }
      case 'publishing_approved': {
        return '#5bc0de';
      }
      case 'publishing': {
        return '#337ab7';
      }
      case 'expired': {
        return '#d9534f';
      }
    }
    return '#000';
  }
}

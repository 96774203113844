import * as React from 'react';

import { actions } from '../../../ducks/MessageForm';
import {
  SimpleFormCheckbox,
  SimpleFormHidden,
  SimpleFormSelect,
  SimpleFormString,
  SimpleFormTextarea,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import FeaturedComment from '../../../models/FeaturedComment';
import FeaturedProductDetail, { IFeaturedProductDetailRecord } from '../../../models/FeaturedProductDetail';

interface IProps {
  detail: FeaturedProductDetail;
  selectedCountry: string;
  language: string;
  languageIndex: number;
  semanticsVersion: number;
  index: number;
}
interface IState {
  isFetching: boolean;
}

export default class FeaturedProductForm extends React.PureComponent<IProps, IState> {
  public static LABEL_INPUT_VERSION = 3;
  public state = {
    isFetching: false,
  };
  private dispatch = getDispatch();

  public updateComment = (key: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value: string = e.target.value;
    const { detail, language } = this.props;
    this.dispatch(actions.updateComment({
      nsUid: detail.ns_uid,
      key,
      language,
      value,
    }));
  }

  public updateDetail = (key: keyof IFeaturedProductDetailRecord) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value: string | string[] = key === 'additional_labels' ? [e.target.value] : e.target.value;
    this.dispatch(actions.updateProductDetail({
      nsUid: this.props.detail.ns_uid,
      key,
      value,
    }));
  }

  public toggleProductDiscount = () => {
    this.dispatch(actions.toggleProductDiscount(this.props.detail.ns_uid));
  }

  public renderDeleteProduct() {
    const { detail, index } = this.props;
    return (
      <div>
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][id]`}
          value={detail.id || ''}
        />
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][_destroy]`}
          value="1"
        />
      </div>
    );
  }

  public fetchPrice = (nsUid: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { selectedCountry: country, language } = this.props;
    this.setState({ isFetching: true });
    this.dispatch(actions.fetchPriceRequest({ language, country, nsUid, _this: this }));
  }

  public render() {
    const { detail, language, languageIndex, index } = this.props;
    const comment = detail.comments.get(language, new FeaturedComment({}));
    // 永続化されていてdestroy true
    if (detail.shouldDestroy()) { return this.renderDeleteProduct(); }
    // 永続化されていなくてdestroy true
    if (detail.destroy) { return null; }

    // FIXME: language依存のerrors対応
    return (
      <div className="featuredProduct-form well">
        <h3>
          {detail.name}
          <button
            style={{ marginLeft: '20px' }}
            className="btn btn-primary"
            disabled={this.state.isFetching}
            onClick={this.fetchPrice(detail.ns_uid)}
          >
            {this.state.isFetching ? I18n.t('featured_products.action.fetching') : I18n.t('featured_products.action.fetch')}
          </button>
        </h3>
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][id]`}
          value={detail.id || ''}
        />
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][ns_uid]`}
          value={detail.ns_uid}
        />
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][order]`}
          value={index}
        />
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][featured_comments_attributes][${languageIndex}][id]`}
          value={comment.id || ''}
        />
        <SimpleFormHidden
          name={`message[featured_products_attributes][${index}][featured_comments_attributes][${languageIndex}][language]`}
          value={language}
        />
        <div className="row">
          <div className="col-md-2">
            <SimpleFormCheckbox
              controlId={`discount_control_${index}`}
              label={I18n.t('featured_products.discount.label')}
              checked={detail.discount}
              onChange={this.toggleProductDiscount}
            />
          </div>
          <div className="col-md-10 flex_column">
            { detail.discount ? (
              <div className="flex_column">
                <SimpleFormString
                  name={`message[featured_products_attributes][${index}][regular_price]`}
                  label={I18n.t('featured_products.regular_price.label')}
                  value={detail.regular_price}
                  helpMessage={detail.getLength('regular_price')}
                  errorMessage={detail.getError('regular_price')}
                  popoverText={I18n.t('featured_products.regular_price.tooltip')}
                  onChange={this.updateDetail('regular_price')}
                />
                <SimpleFormString
                  name={`message[featured_products_attributes][${index}][discount_price]`}
                  label={I18n.t('featured_products.discount_price.label')}
                  value={detail.discount_price}
                  helpMessage={detail.getLength('discount_price')}
                  errorMessage={detail.getError('discount_price')}
                  popoverText={I18n.t('featured_products.discount_price.tooltip')}
                  onChange={this.updateDetail('discount_price')}
                />
              </div>
            ) : (
              <div>
                { /* labelとtooltipがregluar_priceになっていますが正しいです。*/}
                <SimpleFormString
                  name={`message[featured_products_attributes][${index}][discount_price]`}
                  label={I18n.t('featured_products.regular_price.label')}
                  value={detail.discount_price}
                  helpMessage={detail.getLength('discount_price')}
                  errorMessage={detail.getError('discount_price')}
                  popoverText={I18n.t('featured_products.regular_price.tooltip')}
                  onChange={this.updateDetail('discount_price')}
                />
                <SimpleFormHidden
                  name={`message[featured_products_attributes][${index}][regular_price]`}
                  value=""
                />
              </div>
            ) }
          </div>
        </div>
        <div className="flex_column">
          <SimpleFormString
            name={`message[featured_products_attributes][${index}][featured_comments_attributes][${languageIndex}][sale_info]`}
            label={I18n.t('featured_products.sale_info.label')}
            value={comment.sale_info || ''}
            helpMessage={comment.getLength('sale_info')}
            errorMessage={comment.getError('sale_info')}
            popoverText={I18n.t('featured_products.sale_info.tooltip')}
            onChange={this.updateComment('sale_info')}
          />
          <SimpleFormString
            name={`message[featured_products_attributes][${index}][featured_comments_attributes][${languageIndex}][demo_info]`}
            label={I18n.t('featured_products.demo_info.label')}
            value={comment.demo_info || ''}
            helpMessage={comment.getLength('demo_info')}
            errorMessage={comment.getError('demo_info')}
            popoverText={I18n.t('featured_products.demo_info.tooltip')}
            onChange={this.updateComment('demo_info')}
          />
          {this.props.semanticsVersion >= FeaturedProductForm.LABEL_INPUT_VERSION ?
            <SimpleFormSelect
              name={`message[featured_products_attributes][${index}][additional_labels][]`}
              label={I18n.t('featured_products.additional_labels.label')}
              includeBlank={true}
              values={FeaturedProductDetail.additionalLabelOptions}
              value={detail.additional_labels[0] || ''}
              errorMessage={detail.getError('additional_labels')}
              popoverText={I18n.t('featured_products.additional_labels.tooltip')}
              onChange={this.updateDetail('additional_labels')}
            /> : null }
        </div>
        <SimpleFormTextarea
          name={`message[featured_products_attributes][${index}][featured_comments_attributes][${languageIndex}][comment]`}
          label={I18n.t('featured_products.comment.label')}
          value={comment.comment || ''}
          helpMessage={`${comment.getLength('comment')} ${I18n.t('message_details.hints.strong_and_ita_tags_are_available')}`}
          errorMessage={comment.getError('comment')}
          popoverText={I18n.t('featured_products.comment.tooltip')}
          onChange={this.updateComment('comment')}
          deviceFont={true}
          rows={6}
        />
        <SimpleFormString
          name={`message[featured_products_attributes][${index}][featured_comments_attributes][${languageIndex}][supplement]`}
          label={I18n.t('featured_products.supplement.label')}
          value={comment.supplement || ''}
          helpMessage={`${comment.getLength('supplement')} ${I18n.t('message_details.hints.strong_and_ita_tags_are_available')}`}
          errorMessage={comment.getError('supplement')}
          popoverText={I18n.t('featured_products.supplement.tooltip')}
          onChange={this.updateComment('supplement')}
        />
      </div>
    );
  }
}

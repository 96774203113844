import { List, Record } from 'immutable';

import Calendar from './Calendar';

const defaultValue: {
  calendars: List<Calendar>;
  regions: List<string>;
} = {
  calendars: List(),
  regions: List(),
};

export default class CalendarList extends Record(defaultValue) {}

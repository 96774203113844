import React from 'react';

import Base64Image from '../../../lib/components/Base64Image';
import { IUnpackedRelatedMovie } from '../../../models/MessagePreview/UnpackedRelatedMovie';
import NewsText from './NewsText';

interface IProps {
  relatedMovie: IUnpackedRelatedMovie;
}

const RelatedMovie = (props: IProps) => {
  const { relatedMovie } = props;
  const movieNameComponent =
    relatedMovie.movie_name ?
    (
      <div className="related-movie-name">
        <NewsText text={relatedMovie.movie_name} />
      </div>
    ) : null;

  return (
    <div className="related-movie">
      <Base64Image className="related-movie-image" image={relatedMovie.movie_image} />
      { movieNameComponent}
    </div>
  );
};

export default RelatedMovie;

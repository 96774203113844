import { Record } from 'immutable';
import UnpackedNewsV1 from './MessagePreview/UnpackedNewsV1';
import UnpackedNewsV2 from './MessagePreview/UnpackedNewsV2';
import UnpackedNewsV3 from './MessagePreview/UnpackedNewsV3';
import UnpackedNewsV4 from './MessagePreview/UnpackedNewsV4';
import UnpackedNewsV5 from './MessagePreview/UnpackedNewsV5';
import UnpackedNewsV6 from './MessagePreview/UnpackedNewsV6';
import UnpackedNewsV7 from './MessagePreview/UnpackedNewsV7';
import UnpackedNewsV8 from './MessagePreview/UnpackedNewsV8';
import UnpackedNewsV9 from './MessagePreview/UnpackedNewsV9';

export type UnpackedNews =
  UnpackedNewsV1 |
  UnpackedNewsV2 |
  UnpackedNewsV3 |
  UnpackedNewsV4 |
  UnpackedNewsV5 |
  UnpackedNewsV6 |
  UnpackedNewsV7 |
  UnpackedNewsV8 |
  UnpackedNewsV9;

const defaultValue: {
  showModal: boolean,
  semanticsVersion: number,
  languagesAndIds: any,
  activeLanguage: string,
  unpackedNews?: UnpackedNews,
  isFetching: boolean,
  fetchError: string,
  messageId: number,
  isPrintPreview: boolean,
} = {
  showModal: false,
  semanticsVersion: 0,
  languagesAndIds: null,
  activeLanguage: '',
  unpackedNews: undefined,
  isFetching: false,
  fetchError: '',
  messageId: 0,
  isPrintPreview: false,
};

export default class MessagePreviewState extends Record(defaultValue) {
  public startFetching() {
    return this.merge({
      isFetching: true,
      fetchError: '',
    });
  }

  public setUnpackedNews(unpackedNews: UnpackedNews) {
    const semanticsVersion = this.get('semanticsVersion');

    switch (semanticsVersion) {
      case 1:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV1(unpackedNews),
        });
      case 2:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV2(unpackedNews),
        });
      case 3:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV3(unpackedNews),
        });
      case 4:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV4(unpackedNews),
        });
      case 5:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV5(unpackedNews),
        });
      case 6:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV6(unpackedNews),
        });
      case 7:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV7(unpackedNews),
        });
      case 8:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV8(unpackedNews),
        });
      case 9:
        return this.merge({
          isFetching: false,
          fetchError: '',
          unpackedNews: new UnpackedNewsV9(unpackedNews),
        });
      default:
        return this.merge({
          isFetching: false,
          fetchError: 'unsupported semantics version',
          unpackedNews: undefined,
        });
    }
  }

  public setFetchError(message: string) {
    return this.merge({
      isFetching: false,
      fetchError: message,
    });
  }

  public setActiveLanguage(language: string) {
    return this.set('activeLanguage', language);
  }

  public openModal() {
    return this.set('showModal', true);
  }

  public closeModal() {
    return this.set('showModal', false);
  }
}

import { createAction, handleActions } from 'redux-actions';
import { TestDeliveryState } from '../models';

// Actions
const SELECT_DIRECTORY = 'TestDelivery/SELECT_DIRECTORY';
const TOGGLE_VIRTUAL_FILE = 'TestDelivery/TOGGLE_VIRTUAL_FILE';
const TOGGLE_TESTDATA = 'TestDelivery/TOGGLE_TESTDATA';
const UNSELECT_TESTDATA = 'TestDelivery/UNSELECT_TESTDATA';
const SELECT_DATA_EXCLUSIVE = 'TestDelivery/SELECT_DATA_EXCLUSIVE';
const UPDATE_DEVICE = 'TestDelivery/UPDATE_DEVICE';
const UPDATE_NINTENDO_ACCOUNT = 'TestDelivery/UPDATE_NINTENDO_ACCOUNT';
const UPDATE_NAME = 'TestDelivery/UPDATE_NAME';

// Reducer
const reducer = handleActions({
  [SELECT_DIRECTORY]: ($$state, action) => $$state.selectDirectory(action.payload),
  [TOGGLE_VIRTUAL_FILE]: ($$state, action) => $$state.toggleVirtualFile(action.payload),
  [TOGGLE_TESTDATA]: ($$state, action) => $$state.toggleData(action.payload),
  [UNSELECT_TESTDATA]: ($$state, action) => $$state.unselectData(action.payload),
  [SELECT_DATA_EXCLUSIVE]: ($$state, action) => $$state.selectDataExclusive(action.payload),
  [UPDATE_DEVICE]: ($$state, action) => $$state.updateDevice(action.payload),
  [UPDATE_NINTENDO_ACCOUNT]: ($$state, action) => $$state.updateNintendoAccount(action.payload),
  [UPDATE_NAME]: ($$state, action) => $$state.updateName(action.payload),
}, new TestDeliveryState());
export default reducer;

// Action Creators
export const actions = {
  selectDirectory: createAction(SELECT_DIRECTORY),
  toggleVirtualFile: createAction(TOGGLE_VIRTUAL_FILE),
  toggleTestData: createAction(TOGGLE_TESTDATA),
  unselectTestData: createAction(UNSELECT_TESTDATA),
  selectDataExclusive: createAction(SELECT_DATA_EXCLUSIVE),
  updateDevice: createAction(UPDATE_DEVICE),
  updateNintendoAccount: createAction(UPDATE_NINTENDO_ACCOUNT),
  updateName: createAction(UPDATE_NAME),
};

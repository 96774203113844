import { Record } from 'immutable';
import moment from 'moment-timezone';

import Attachment from '../Attachment';

interface IErrors {
  summary?: string[];
  publishing_time?: string[];
  expiration_time?: string[];
}

const defaultValue: {
  id: string,
  archiveId?: number,
  summary: string,
  status: string,
  publishingTime: string,
  expirationTime?: string,
  published: boolean,
  binary: Attachment,
  actions: string[],
  selected: boolean,
  isNeighbor: boolean,
  errors: IErrors,
} = {
  id: '',
  archiveId: undefined,
  summary: '',
  status: 'new',
  publishingTime: '',
  expirationTime: undefined,
  published: false,
  binary: new Attachment(),
  actions: [],
  selected: false,
  isNeighbor: false,
  errors: {},
};

export default class Data extends Record(defaultValue) {
  private static UUID_LENGTH = 36;
  private static SUMMARY_DISPLAY_LENGTH = 20;
  public isNewData(): boolean {
    return this.id.length === Data.UUID_LENGTH;
  }
  public dataEditable(): boolean {
    return this.status === 'draft' || this.status === 'new';
  }
  public getPublishingDateTime(timezone?: string): { date: string, time: string, zone?: string } {
    if (this.publishingTime === undefined) { return { date: '', time: '', zone: '' }; }
    const dateTime = new Date(this.publishingTime);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();
    // 夏時間があるため、zoneの抽出には日付を使って確認
    const zone = timezone ? moment(date.replace(/\//g, '-')).tz(timezone).format('z') : undefined;
    return { date, time, zone };
  }
  public getExpirationDateTime(timezone?: string): { date: string, time: string, zone?: string } {
    if (this.expirationTime === undefined) { return { date: '', time: '', zone: '' }; }
    const dateTime = new Date(this.expirationTime);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();
    // 夏時間があるため、zoneの抽出には日付を使って確認
    const zone = timezone ? moment(date.replace(/\//g, '-')).tz(timezone).format('z') : undefined;
    return { date, time, zone };
  }
  public compactSummary(): string {
    if (this.summary.length <= Data.SUMMARY_DISPLAY_LENGTH) { return this.summary; }
    return `${this.summary.slice(0, Data.SUMMARY_DISPLAY_LENGTH)}...`;
  }
  public rawLink(): string {
    if (this.isNewData()) { return ''; }
    return `/archives/${this.archiveId}/raw`;
  }
  public hasScheduleError(): boolean {
    return 'publishing_time' in this.errors || 'expiration_time' in this.errors;
  }
}

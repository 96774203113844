import 'bootstrap-notify';
import * as $ from 'jquery';

interface ISettingObject {
  animate: {
    enter: string,
    exit: string,
  };
  placement: {
    align: string,
    from: string
  };
  type: string;
}

const settingObjectDefaultValue: ISettingObject = {
  animate: {
    enter: 'animated fadeInDown',
    exit: 'animated fadeOutUp',
  },
  placement: {
    align: 'right',
    from: 'bottom',
  },
  type: 'danger',
};
interface ITextObject {
  title: string;
  message: string;
  type: string;
}
export default function notify(
  textObject: ITextObject,
  settingObject: ISettingObject = settingObjectDefaultValue,
) {
  if (textObject.type) {
    settingObject.type = textObject.type;
    delete textObject.type;
  }
  $.notify(textObject, settingObject);
}

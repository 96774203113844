import React from 'react';

import I18n from '../../../lib/i18n';
import ListImage from './ListImage';
import NewsText from './NewsText';

interface IProps {
  listImage: string;
  text: string;
}

export default class Entrance extends React.PureComponent<IProps> {
  public entranceWithCaptionComponent() {
    const { listImage, text } = this.props;
    return (
      <div className="entrance list-image-preview gradient">
        <ListImage listImage={listImage} />
        <NewsText className={'entrance-text'} text={text}/>
      </div>
    );
  }

  public entranceWithoutCaptionComponent() {
    const { listImage } = this.props;
    return (
      <div className="entrance list-image-preview">
        <ListImage listImage={listImage} />
      </div>
    );
  }

  public render() {
    const { text } = this.props;

    const entranceComponent =
      text ?
      this.entranceWithCaptionComponent() :
      this.entranceWithoutCaptionComponent();

    return (
      <div>
        <h4>{I18n.t('messages.preview.entrance')}</h4>
        {entranceComponent}
      </div>
    );
  }
}

import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import { SimpleFormSelect, SimpleFormString } from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import MessageDetail from '../../../models/MessageDetail';
import { IRootState } from '../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    isChina: state.$$formStore.isChina,
    $$message: state.$$formStore.message,
  };
}

interface IProps {
  index: number;
  $$messageDetail: MessageDetail;
}
type Props = IProps & ReturnType<typeof mapStateToProps>;
class SystemAppletParametersForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { updateFormStore, validateForm } = actions;

    // name = message[message_details_attributes][1][more_link_text]から
    // key = ["messageDetails", 1,  "more_link_text"]を作る必要がある
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.value;
    this.dispatch(updateFormStore({ key, value }));
    this.dispatch(validateForm({ key }));
  }

  public systemAppletTypeSelector(index: number) {
    const { isChina, $$messageDetail } = this.props;
    const systemAppletTypes = (window as any).Constants.message.systemAppletTypes;
    const systemAppletTypeOptions = Object.keys(systemAppletTypes)
      .filter(e => !isChina || e !== 'PARENTAL_CONTROL') // 中国向けにはペアコンは含めない
      .map<[string, string]>(
        e => [e, systemAppletTypes[e]]
      );

    return (
      <SimpleFormSelect
        required={true}
        name={`message[message_details_attributes][${index}][more_system_applet_type]`}
        label={I18n.t('activerecord.attributes.message_detail.more_system_applet_type')}
        includeBlank={true}
        values={systemAppletTypeOptions}
        value={$$messageDetail.more_system_applet_type || ''}
        errorMessage={$$messageDetail.getIn(['errors', 'more_system_applet_type'])}
        popoverText={I18n.t('message_details.tooltips.more_system_applet_type')}
        onChange={this.handleChange}
      />
    );
  }

  public render() {
    const { index, $$messageDetail, $$message } = this.props;
    if (!$$message.hasNxNewsLevel()) { return null; }
    const lengths: { [k: string]: string } = {};
    Object.keys($$messageDetail.validators).forEach(key => {
      const length = $$messageDetail[key] ? $$messageDetail[key].length : 0;
      lengths[key] = `${length}/${$$messageDetail.validators[key].maximum}`;
    });

    return (
      <fieldset>
        {this.systemAppletTypeSelector(index)}
        <SimpleFormString
          name={`message[message_details_attributes][${index}][more_system_applet_text]`}
          required={true}
          label={I18n.t('activerecord.attributes.message_detail.more_system_applet_text')}
          value={$$messageDetail.more_system_applet_text || ''}
          errorMessage={$$messageDetail.errors.more_system_applet_text}
          helpMessage={lengths.more_system_applet_text}
          popoverText={I18n.t('message_details.tooltips.more_system_applet_text')}
          onChange={this.handleChange}
          deviceFont={true}
        />
      </fieldset>
    );
  }
}

export default connect(mapStateToProps)(SystemAppletParametersForm);

import React from 'react';
import NewsText from './NewsText';

interface IProps {
  interactiveElements: string;
}

const InteractiveElements = (props: IProps) => {
  const { interactiveElements } = props;
  if (interactiveElements) {
    return (
      <NewsText text={interactiveElements} className="interactive-elements" />
    );
  }

  return null;
};

export default InteractiveElements;

import * as React from 'react';

import { actions } from '../../ducks/NewsSettingForm';
import Alert from '../../lib/components/Alert';
import AttachmentForm from '../../lib/components/AttachmentForm';
import {
  SimpleFormCheckbox,
  SimpleFormDateTime,
  SimpleFormHidden,
  SimpleFormSelect,
  SimpleFormString,
} from '../../lib/components/SimpleForm';
import { getDispatch } from '../../lib/dispatchExporter';
import I18n from '../../lib/i18n';
import { INewsSetting } from '../../models/NewsSetting';
import NewsSettingFormState from '../../models/NewsSettingFormState';
import NewsDescriptionList from './NewsDescriptionList';
import TopicCountryForm from './TopicCountryForm';

interface IProps {
  state: NewsSettingFormState;
}
export default class NewsSettingForm extends React.PureComponent<IProps> {
  private dispatch = getDispatch();

  public updateSetting = (key: keyof INewsSetting) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Event) => {
    if (e instanceof Event) { return; }
    const { value } = e.target;
    this.dispatch(actions.updateSetting({ key, value }));
  }

  public renderErrorNotification() {
    if (this.props.state.isValid()) { return null; }
    return (
      <Alert
        message={I18n.t('simple_form.error_notification.default_message')}
        alertType="danger"
      />
    );
  }
  public renderNewsName() {
    const { setting } = this.props.state;
    if (setting.name === undefined) { return null; }
    return (<SimpleFormString
      required={true}
      name="general_news[name]"
      label={I18n.t('activerecord.attributes.general_news.name')}
      value={setting.name}
      helpMessage={setting.getLength('name')}
      errorMessage={setting.getError('name')}
      popoverText={I18n.t('general_news.tooltips.name')}
      onChange={this.updateSetting('name')}
    />);
  }
  public renderNewsNote() {
    const { setting } = this.props.state;
    if (setting.note === undefined) { return null; }
    return (<SimpleFormString
      name="general_news[note]"
      label={I18n.t('activerecord.attributes.general_news.note')}
      value={setting.note}
      helpMessage={setting.getLength('note')}
      errorMessage={setting.getError('note')}
      onChange={this.updateSetting('note')}
    />);
  }
  public renderNewsPass() {
    const { setting, selectOptions: options } = this.props.state;
    if (setting.news_passphrase_type === undefined) { return null; }
    return (<SimpleFormSelect
      name="general_news[option][news_passphrase_type]"
      label={I18n.t('activerecord.attributes.channel_option.news_passphrase_type')}
      values={options.newsPassphraseTypes}
      value={setting.news_passphrase_type}
      errorMessage={setting.getError('news_passphrase_type')}
      onChange={this.updateSetting('news_passphrase_type')}
    />);
  }
  public renderCensor() {
    const { setting } = this.props.state;
    if (setting.enable_censor === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="general_news[option][enable_censor]"
      label={I18n.t('activerecord.attributes.channel_option.enable_censor')}
      defaultChecked={setting.enable_censor}
      onChange={this.updateSetting('enable_censor')}
    />);
  }
  public renderTencentCensor() {
    const { setting } = this.props.state;
    if (setting.enable_tencent_censor === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="general_news[option][enable_tencent_censor]"
      label={I18n.t('activerecord.attributes.channel_option.enable_tencent_censor')}
      defaultChecked={setting.enable_tencent_censor}
      onChange={this.updateSetting('enable_tencent_censor')}
    />);
  }
  public renderReview() {
    const { setting } = this.props.state;
    if (setting.enable_review === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="general_news[option][enable_review]"
      label={I18n.t('activerecord.attributes.channel_option.enable_review')}
      defaultChecked={setting.enable_review}
      onChange={this.updateSetting('enable_review')}
    />);
  }
  public renderFormLevel() {
    const { setting, selectOptions: options } = this.props.state;
    if (setting.news_form_level === undefined) { return null; }
    return (<SimpleFormSelect
      name="general_news[option][news_form_level]"
      label={I18n.t('activerecord.attributes.channel_option.news_form_level')}
      values={options.newsFormLevels}
      value={setting.news_form_level.toString()}
      errorMessage={setting.getError('news_form_level')}
      onChange={this.updateSetting('news_form_level')}
    />);
  }
  public renderSemanticsVersion() {
    const { setting , selectOptions: options } = this.props.state;
    if (setting.semantics_version === undefined) { return null; }
    return (<SimpleFormSelect
      name="general_news[option][semantics_version]"
      label={I18n.t('activerecord.attributes.channel_option.semantics_version')}
      values={options.semanticsVersions}
      value={setting.semantics_version.toString()}
      errorMessage={setting.getError('semantics_version')}
      onChange={this.updateSetting('semantics_version')}
    />);
  }
  public renderNpnsTopicId() {
    const { setting } = this.props.state;
    if (setting.npns_topic_id === undefined) { return null; }
    return (<SimpleFormString
      required={true}
      name="news_topic[npns_topic_id]"
      label={I18n.t('activerecord.attributes.news_topic.npns_topic_id')}
      value={setting.npns_topic_id}
      errorMessage={setting.getError('npns_topic_id')}
      onChange={this.updateSetting('npns_topic_id')}
    />);
  }
  public renderExpirationTime() {
    const { setting } = this.props.state;
    if (setting.expiration_time === undefined) { return null; }
    const options = {
      defaultTime: '00:00',
    };
    return (
      <div className="row">
        <div className="col-md-3">
          <SimpleFormDateTime
            name="news_topic[expiration_time]"
            label={I18n.t('activerecord.attributes.news_topic.expiration_time')}
            value={setting.expiration_time}
            errorMessage={setting.getError('expiration_time')}
            options={options}
            popoverText={I18n.t('general_news.tooltips.expiration_time')}
            onChange={this.updateSetting('expiration_time')}
          />
        </div>
      </div>
    );
  }
  public renderServiceStatus() {
    const { setting, selectOptions: options } = this.props.state;
    if (setting.service_status === undefined) { return null; }
    return (<SimpleFormSelect
      name="news_topic[service_status]"
      label={I18n.t('activerecord.attributes.news_topic.service_status')}
      values={options.serviceStatuses}
      value={setting.service_status}
      errorMessage={setting.getError('service_status')}
      popoverText={I18n.t('general_news.tooltips.service_status')}
      onChange={this.updateSetting('service_status')}
    />);
  }
  public renderOfficial() {
    const { setting } = this.props.state;
    if (setting.official === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="news_topic[official]"
      label={I18n.t('activerecord.attributes.news_topic.official')}
      defaultChecked={setting.official}
      onChange={this.updateSetting('official')}
    />);
  }
  public renderPublic() {
    const { setting } = this.props.state;
    if (setting.public === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="news_topic[public]"
      label={I18n.t('activerecord.attributes.news_topic.public')}
      defaultChecked={setting.public}
      onChange={this.updateSetting('public')}
    />);
  }
  public renderChina() {
    const { setting } = this.props.state;
    if (setting.is_china === undefined) { return null; }
    return (
      <SimpleFormCheckbox
        name="news_topic[is_china]"
        label={I18n.t('activerecord.attributes.news_topic.is_china')}
        defaultChecked={setting.is_china}
        onChange={this.updateSetting('is_china')}
      />
    );
  }
  public renderTopicCountryForm() {
    const { setting, topicCountries, constants } = this.props.state;
    if (setting.topic_countries === undefined) { return null; }
    return <TopicCountryForm {...{ topicCountries, constants }} />;
  }
  public renderNaRequired() {
    const { setting } = this.props.state;
    if (setting.na_required === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="news_topic[na_required]"
      label={I18n.t('activerecord.attributes.news_topic.na_required')}
      defaultChecked={setting.na_required}
      onChange={this.updateSetting('na_required')}
    />);
  }
  public renderPseudo() {
    const { setting } = this.props.state;
    if (setting.pseudo === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="news_topic[pseudo]"
      label={I18n.t('activerecord.attributes.news_topic.pseudo')}
      defaultChecked={setting.pseudo}
      onChange={this.updateSetting('pseudo')}
    />);
  }
  public renderListImageRequired() {
    const { setting } = this.props.state;
    if (setting.list_image_required === undefined) { return null; }
    return (<SimpleFormCheckbox
      name="general_news[option][list_image_required]"
      label={I18n.t('activerecord.attributes.channel_option.list_image_required')}
      defaultChecked={setting.list_image_required}
      onChange={this.updateSetting('list_image_required')}
    />);
  }
  public renderNewsIconImage() {
    const { setting } = this.props.state;
    if (setting.news_icon_image === undefined) { return null; }
    return (<AttachmentForm
      required={true}
      label={I18n.t('activerecord.attributes.news_topic.news_icon_image')}
      attachmentName="news_topic[news_icon_image]"
      attachmentType="message_icon_image"
      popoverText={I18n.t('general_news.tooltips.news_icon_image')}
      $$attachment={setting.news_icon_image}
    />);
  }
  public renderDefaultLanguage() {
    const { setting, constants } = this.props.state;
    if (setting.default_language === undefined) { return null; }
    return (<SimpleFormSelect
      required={true}
      name="news_topic[default_language]"
      label={I18n.t('activerecord.attributes.news_topic.default_language')}
      values={constants.languages.map<[string, string]>((language: string) => [language, language]).toArray()}
      value={setting.default_language}
      errorMessage={setting.getError('default_language')}
      popoverText={I18n.t('messages.tooltips.default_language')}
      onChange={this.updateSetting('default_language')}
    />);
  }

  public render() {
    const { state } = this.props;
    return (
      <div>
        <div className="form-inputs">
          <SimpleFormHidden
            name="general_news[option][id]"
            value={state.setting.channel_option_id || ''}
          />
          <SimpleFormHidden
            name="news_topic[id]"
            value={state.setting.news_topic_id || ''}
          />
          { this.renderErrorNotification() }
          { this.renderNewsName() }
          { this.renderNewsNote() }
          { this.renderNewsPass() }
          { this.renderCensor() }
          { this.renderTencentCensor() }
          { this.renderReview() }
          { this.renderFormLevel() }
          { this.renderSemanticsVersion() }
          { this.renderNpnsTopicId() }
          { this.renderExpirationTime() }
          { this.renderServiceStatus() }
          { this.renderOfficial() }
          { this.renderPublic() }
          { this.renderNaRequired() }
          { this.renderPseudo() }
          { this.renderListImageRequired() }
          { this.renderChina() }
          { this.renderTopicCountryForm() }
          { this.renderNewsIconImage() }
          { this.renderDefaultLanguage() }
          <NewsDescriptionList
            descriptions={state.descriptions}
            editableLanguages={state.editableLanguages}
          />
        </div>
      </div>
    );
  }
}

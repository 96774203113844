import * as React from 'react';
import { connect } from 'react-redux';
import Select, { Option } from 'react-select';
import { SimpleFormLabel } from '../../../../lib/components/SimpleForm';

import { actions } from '../../../../ducks/MessageForm';
import { getDispatch } from '../../../../lib/dispatchExporter';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';
import MessageFormState from '../../../../models/MessageFormState';

interface IProps {
  index: number;
  parentFormName: string;
  $$messageDetail: MessageDetail;
  ratingSystem: string;
}
type Props = IProps & IMapStateToProps;

class Descriptors extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public updateSelectedDescriptor = (type: string) => (ids: Option<string>) => {
    const { index, ratingSystem } = this.props;
    const { updateFormStore } = actions;
    const key = ['messageDetails', index, type, ratingSystem];
    this.dispatch(updateFormStore({ key, value: ids.split(',').map((id: string) => parseInt(id, 10)) }));
  }

  public renderSelect(formName: string, options: any, type: string) {
    const { $$messageDetail, ratingSystem } = this.props;
    const value = $$messageDetail[type].get(ratingSystem);
    const name = `${formName}[${type}][${ratingSystem}][]`;
    return (
      <Select
        multi={true}
        simpleValue={true}
        onChange={this.updateSelectedDescriptor(type)}
        {...{ name, value, options }}
      />
    );
  }

  public renderContentsDescriptors() {
    const { $$messageDetail, contentsDescriptors, parentFormName, ratingSystem } = this.props;
    return (
      <SimpleFormLabel
        name={`${parentFormName}[contents_descriptors][${ratingSystem}][]`}
        label={I18n.t('activerecord.attributes.message_detail.contents_descriptors')}
        errorMessage={$$messageDetail.errors.contents_descriptors}
      >
        {this.renderSelect(`${parentFormName}`, contentsDescriptors, 'contents_descriptors')}
      </SimpleFormLabel>
    );
  }

  public renderInteractiveElements() {
    const { $$messageDetail, interactiveElements, parentFormName, ratingSystem } = this.props;
    if ( interactiveElements.length > 0) {
      return (
        <SimpleFormLabel
          name={`${parentFormName}[interactive_elements][${ratingSystem}][]`}
          label={I18n.t('activerecord.attributes.message_detail.interactive_elements')}
          errorMessage={$$messageDetail.errors.interactive_elements}
        >
          {this.renderSelect(`${parentFormName}`, interactiveElements, 'interactive_elements')}
        </SimpleFormLabel>
      );
    }
    return null;
  }

  public render() {
    return (
      <div>
        { this.renderContentsDescriptors() }
        { this.renderInteractiveElements() }
      </div>
    );
  }
}

interface IDescriptor {
  value: number;
  label: string;
}

interface IMapStateToProps {
  contentsDescriptors: Array<IDescriptor>;
  interactiveElements: Array<IDescriptor>;
}

// ニュース設定言語の設定が存在すればその言語のディスクリプタ名を使用する
// 存在しない場合は先頭の設定言語のものを使用する
function getDescriptorName(labels: Array<{ lang: string, value: string }>, language: string): string {
  const label = labels.find(name => name['lang'] === language);
  return label !== undefined ? label['value'] : labels[0]['value'];
}

function descriptors(descriptorType: string, state: { $$formStore: MessageFormState }, ownProps: any): Array<IDescriptor> {
  const language = ownProps.$$messageDetail.language;
  const descriptors = state.$$formStore.getIn([descriptorType, ownProps.ratingSystem])
    .map((e: any) => ({ value: e.value, label: getDescriptorName(e.label, language) }));
  return descriptors;
}

function mapStateToProps(state: { $$formStore: MessageFormState }, ownProps: any): IMapStateToProps {
  return {
    contentsDescriptors: descriptors('contentsDescriptors', state, ownProps),
    interactiveElements: descriptors('interactiveElements', state, ownProps)
  };
}

export default connect(mapStateToProps, null)(Descriptors);

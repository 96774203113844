import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import { MessagePreviewContainer } from '../components/MessagePreview';
import { setDispatch } from '../lib/dispatchExporter';
import createStore from '../store/MessagePreview';

export interface IMessagePreviewAppProps {
  semanticsVersion: number;
  languagesAndIds: Array<{key: string}>;
  messageId: number;
  isPrintPreview: boolean;
}

const MessagePreviewApp = (props: IMessagePreviewAppProps, railsContext: RailsContext) => {
  (window as any).Constants = railsContext.Constants;
  const store = createStore(props);
  setDispatch(store.dispatch);

  return (
    <Provider store={store}>
      <MessagePreviewContainer />
    </Provider>
  );
};

ReactOnRails.register({ MessagePreviewApp });

import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import ravenMiddleware from 'redux-raven-middleware';

import testDeliveryReducer from '../ducks/TestDeliveryForm';
import { TestDeliveryState } from '../models';

export default (props, _railsContext) => { // eslint-disable-line
  const { data_list, ...args } = props;
  let state = new TestDeliveryState(args);
  state = state.set("errors", data_list.errors);
  // EDIT系のために選択状態を反映
  state = state.updateDevice(data_list.devices.map(device => device.id));
  state = state.updateNintendoAccount(data_list.nintendo_accounts.map(na => na.id));
  const virtualFileIds = new Set();
  for (const deliverable of data_list.deliverables) {
    state = state.toggleData(deliverable.id);
    virtualFileIds.add(
      {
        id: deliverable.virtual_file_id,
        directoryId: deliverable.directory_id,
      }
    );
  }
  // 選択されているVirtualFileは開いておく
  // VirtualFileがどのDirectoryに入っているかのMapがないため、
  // いちいちDirectoryを選択してからtoggleする必要がある
  // その結果描画されたときにcurrentDirectoryが最後のものになるため、
  // 改めてcurrentDirectoryをdirectories.firstにセットし直す処理が入った
  // FIXME: そもそもas_jsonでフロント用モデルを作るのではなく、
  // 専用のモデルを容易してselected状態をサーバがから送るべき
  for (const virtualFileId of virtualFileIds) {
    state = state.selectDirectory(virtualFileId.directoryId).toggleVirtualFile(virtualFileId.id);
  }
  state = state.selectDirectory(args.directories[0].id);

  const initialState = { $$formStore: state };

  // reducer
  const combinedReducer = combineReducers({
    $$formStore: testDeliveryReducer,
  });

  const middlewares =
    process.env.NODE_ENV === 'production' ?
    [ravenMiddleware(window.raven)] :
    [createLogger(), ravenMiddleware(window.raven)];

  const composedStore = compose(
    applyMiddleware(...middlewares),
    process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  );
  const storeCreator = composedStore(createStore);
  const store = storeCreator(combinedReducer, initialState);

  return store;
};

import * as React from 'react';

import I18n from '../../lib/i18n';
import Data from '../../models/DistributionFile/Data';
import TimelineDataSet from '../../models/TimelineDataSet';
import { DistributionEditorProps } from './index';

interface IDataViewProps {
  data?: Data;
  left: number;
  width: number;
  timezone: string;
}

const PreviousDataView = (props: IDataViewProps) => {
  const { data, left, width, timezone } = props;
  const style = { width: `${width}%`, left: `${left}%` };
  if (data === undefined) { return null; }
  const pubDateTime = data.getPublishingDateTime(timezone);
  const expDateTime = data.getExpirationDateTime(timezone);
  const judge = data.hasScheduleError() ? 'ng' : 'ok';
  return (
    <div className={`datalistPeriod_item datalistPeriod_${judge}Item datalistPeriod_item__1`} style={style}>
      <div className="datalistPeriod_name">{ data.summary }</div>
      <div className="datalistPeriod_date cf">
        <div className="datalistPeriod_date__start">
          { pubDateTime.date }
          <br />
          { `${pubDateTime.time} ${pubDateTime.zone}` }
        </div>
        <div className="datalistPeriod_date__end">
          { expDateTime.date }
          <br />
          { `${expDateTime.time} ${expDateTime.zone}` }
        </div>
      </div>
    </div>
  );
};

const CurrentDataView = (props: IDataViewProps) => {
  const { data, left, width, timezone } = props;
  const style = { width: `${width}%`, left: `${left}%` };
  if (data === undefined) { return null; }
  const pubDateTime = data.getPublishingDateTime(timezone);
  const expDateTime = data.getExpirationDateTime(timezone);
  const judge = data.hasScheduleError() ? 'ng' : 'ok';

  return (
    <div className={`datalistPeriod_item datalistPeriod_${judge}Item datalistPeriod_current datalistPeriod_item__2`} style={style}>
      <div className="datalistPeriod_name">{ data.summary }</div>
      <div className="datalistPeriod_date cf">
        <div className="datalistPeriod_date__start">
          { pubDateTime.date }
          <br />
          { `${pubDateTime.time} ${pubDateTime.zone}` }
        </div>
        <div className="datalistPeriod_date__end">
          { expDateTime.date }
          <br />
          { `${expDateTime.time} ${expDateTime.zone}` }
        </div>
      </div>
    </div>
  );
};

const NextDataView = (props: IDataViewProps) => {
  const { data, left, width, timezone } = props;
  const style = { width: `${width}%`, left: `${left}%` };
  if (data === undefined) { return null; }
  const pubDateTime = data.getPublishingDateTime(timezone);
  const expDateTime = data.getExpirationDateTime(timezone);
  const judge = data.hasScheduleError() ? 'ng' : 'ok';
  return (
    <div className={`datalistPeriod_item datalistPeriod_${judge}Item datalistPeriod_item__3`} style={style}>
      <div className="datalistPeriod_name">{ data.summary }</div>
      <div className="datalistPeriod_date cf">
        <div className="datalistPeriod_date__start">
          { pubDateTime.date }
          <br />
          { `${pubDateTime.time} ${pubDateTime.zone}` }
        </div>
        <div className="datalistPeriod_date__end">
          { expDateTime.date }
          <br />
          { `${expDateTime.time} ${expDateTime.zone}` }
        </div>
      </div>
    </div>
  );
};

interface ITimelineViewProps {
  timeline: TimelineDataSet;
  timezone: string;
  selectData: DistributionEditorProps['selectData'];
}

export default class TimelineView extends React.PureComponent<ITimelineViewProps> {
  public selectPrev = () => { if (this.props.timeline.prevData) { this.props.selectData(this.props.timeline.prevData.id); } };
  public selectNext = () => { if (this.props.timeline.nextData) { this.props.selectData(this.props.timeline.nextData.id); } };

  public render() {
    const { timeline, timezone } = this.props;
    const { prev, curr, next } = timeline.getUIDataAlignments();
    return (
      <div className="datalistPeriod">
        <div className="datalistPeriod_box">
          <div className="datalistPeriod_display">
            <PreviousDataView data={timeline.prevData} left={prev.left} width={prev.width} timezone={timezone} />
            <CurrentDataView data={timeline.selectedData} left={curr.left} width={curr.width} timezone={timezone} />
            <NextDataView data={timeline.nextData} left={next.left} width={next.width} timezone={timezone} />
          </div>
          <div className="datalistPeriod_btnBox">
            <button
              className="btn btn-default"
              disabled={timeline.prevData === undefined}
              onClick={this.selectPrev}
            >
              <i className="glyphicon glyphicon-chevron-left" />
              {I18n.t('virtual_files.show.previous')}
            </button>
            <button
              className="btn btn-default"
              disabled={timeline.nextData === undefined}
              onClick={this.selectNext}
            >
              {I18n.t('virtual_files.show.next')}
              <i className="glyphicon glyphicon-chevron-right" />
            </button>
          </div>
        </div>

      </div>
    );
  }
}

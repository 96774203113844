import * as React from 'react';
import { connect } from 'react-redux';

import { SimpleFormHidden, SimpleFormSelect } from '../../../../lib/components/SimpleForm';
import I18n from '../../../../lib/i18n';
import MessageDetail from '../../../../models/MessageDetail';
import MoreShopQuery from '../../../../models/MoreShopQuery';
import { IRootState } from '../../../../store/MessageForm';

function mapStateToProps(state: IRootState) {
  return {
    isChina: state.$$formStore.isChina,
  };
}

interface IProps {
  index: number;
  parentFormName: string;
  hasNxNewsLevel?: boolean;
  $$messageDetail: MessageDetail;
  $$moreShopQuery: MoreShopQuery;
  semanticsVersion: number;
  updateShopJumpQuery: (key: string, value: any) => void;
}

interface IState {
  selectList: Array<[string, string]>;
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

class SceneSelector extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    const selectList: Array<[string, string]> = [
      [I18n.t('activerecord.hints.message_detail.more_shop_query.new'), 'new'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.pickup'), 'pickup'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.sale'), 'sale'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.softDetail'), 'softDetail'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.aocDetail'), 'aocDetail'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.aocList'), 'aocList'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.bundleDetail'), 'bundleDetail'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.list'), 'list'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.subscriptions'), 'subscriptions'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.consumption'), 'consumption'],
      [I18n.t('activerecord.hints.message_detail.more_shop_query.search'), 'search'],
    ];

    if (props.semanticsVersion >= 5 && !props.isChina) {
      selectList.push([I18n.t('activerecord.hints.message_detail.more_shop_query.preticketDetail'), 'preticketDetail']);
      selectList.push([I18n.t('activerecord.hints.message_detail.more_shop_query.membership_service'), 'membership_service']);
      selectList.push([I18n.t('activerecord.hints.message_detail.more_shop_query.membership_top'), 'membership_top']);
    }

    if (props.hasNxNewsLevel) {
      this.state = { selectList };
    } else {
      this.state = { selectList: selectList.filter((e) => !['new', 'pickup', 'sale', 'search', 'preticketDetail'].includes(e[1]))};
    }
  }

  public handleSceneChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const { updateShopJumpQuery } = this.props;

    updateShopJumpQuery('detailScene', value);
    updateShopJumpQuery('scene', value);
  }

  public render() {
    const { index, $$moreShopQuery, parentFormName } = this.props;
    const { selectList } = this.state;

    return (
      <div className="col-md-6">
        <SimpleFormSelect
          name={`select-type-${index}`}
          label={I18n.t('activerecord.attributes.message_detail.more_shop_query')}
          values={selectList}
          value={$$moreShopQuery.detailScene}
          onChange={this.handleSceneChange}
          popoverText={I18n.t('message_details.tooltips.more_shop_query')}
          includeBlank={true}
          required={true}
        />
        <SimpleFormHidden
          name={`${parentFormName}[scene]`}
          value={$$moreShopQuery.scene || ''}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(SceneSelector);

import React from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import RailsContextWrapper from '../lib/components/RailsContextWrapper';

import createStore from '../store/TestDeliveryForm';
import { setDispatch } from '../lib/dispatchExporter';
import { TestDeliveryFormContainer } from '../components/TestDeliveryForm';

const TestDeliveryFormApp = (props, _railsContext) => {
  window.Constants = _railsContext.Constants;
  const store = createStore(props, _railsContext);
  setDispatch(store.dispatch);
  const reactComponent = (
    <Provider store={store}>
      <RailsContextWrapper {..._railsContext}>
        <TestDeliveryFormContainer />
      </RailsContextWrapper>
    </Provider>
    );
  return reactComponent;
};

ReactOnRails.register({ TestDeliveryFormApp });

import * as React from 'react';
import { List } from 'immutable';
import {
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import GlyphPopover from '../../../lib/components/GlyphPopover';
import I18n from '../../../lib/i18n';
import Product from '../../../models/Product';
import RelatedChannelsSupport from './RelatedChannelsSupport';
import { ICountry } from '../../../models/Message';

interface IDraggableNameProps {
  productIndex: number;
  title: string;
  maxLengthStyle: number;
  isValidProduct: boolean;
}
const DraggableName = SortableHandle((props: IDraggableNameProps) => {
  const { maxLengthStyle, productIndex, title, isValidProduct } = props;
  const style = isValidProduct ? {} : { color: 'red' };
  return (
    <span style={{ ...style, cursor: 'move', width: `${maxLengthStyle}em` }}>
      <i className="glyphicon glyphicon-menu-hamburger" />
      {productIndex + 1}: {title}
    </span>
  );
});

interface IProps {
  productIndex: number;
  parentFormName: string;
  maxLengthStyle: number;
  draggedIndex: number;
  product: Product;
  unselectProduct: (id: string) => void;
  countries: List<ICountry>;
}
class ProductRow extends React.PureComponent<IProps> {
  public unselectProduct = (id: string) => () => {
    this.props.unselectProduct(id);
  }

  public renderDestroyButton(id: string) {
    return (
      <div style={{ float: 'right' }} onClick={this.unselectProduct(id)}>
        <span style={{ color: 'red' }} className="glyphicon glyphicon-remove" />
      </div>
    );
  }

  public renderProductDetail(product: Product, isValidProduct: boolean) {
    let title = product.availableName();
    let body = `[${I18n.t('activerecord.attributes.product.oldest_release_at')}]<br />${product.oldest_release_at}`;
    if (!isValidProduct) {
      title = `${product.ns_uid}: ${I18n.t('products.index.invalid_product')}`;
      body = I18n.t('messages.tooltips.invalid_product');
    }
    return (
      <div style={{ float: 'right', marginRight: '10px' }}>
        <GlyphPopover {...{ title, body }} glyph="info-sign" dangerouslySetInnerHTML={true} />
      </div>
    );
  }

  public render() {
    const { productIndex, product, countries, parentFormName, maxLengthStyle, draggedIndex } = this.props;
    const { maxLength } = Product;
    const isValidProduct = product.valid && product.isValidAvailableCountries(countries.map(e => e.iso2).toArray());
    const text = isValidProduct ? product.availableName() : `${product.ns_uid}: ${I18n.t('products.index.invalid_product')}`;
    const title = text.length > maxLength ? `${text.slice(0, maxLength - 1)}…` : text;
    const opacity = draggedIndex === productIndex ? 0 : 1;
    const style = {
      border: '1px dashed gray',
      padding: '0.5rem 1rem',
      marginBottom: '.5rem',
    };

    if (!isValidProduct) {
      const errorStyle = {
        border: '1px dashed red',
        padding: '0.5rem 1rem',
        marginBottom: '.5rem',
      };
      return (
        <div className="product-row" style={{ ...errorStyle, opacity, maxWidth: `${maxLengthStyle}em` }}>
          <DraggableName {...{ title, productIndex, maxLengthStyle, isValidProduct }} />
          {this.renderDestroyButton(product.ns_uid)}
          {this.renderProductDetail(product, isValidProduct)}
          <input type="hidden" name={`${parentFormName}`} value={product.ns_uid} />
        </div>
      );
    }

    return (
      <div className="product-row" style={{ ...style, opacity, maxWidth: `${maxLengthStyle}em` }}>
        <DraggableName {...{ title, productIndex, maxLengthStyle, isValidProduct }} />
        {this.renderDestroyButton(product.ns_uid)}
        {this.renderProductDetail(product, isValidProduct)}
        <input type="hidden" name={`${parentFormName}`} value={product.ns_uid} />
        <RelatedChannelsSupport { ...{ product }} />
      </div>
    );
  }
}

export default SortableElement(ProductRow);

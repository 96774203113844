import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import ravenMiddleware from 'redux-raven-middleware';
import { fromJS } from 'immutable';
import reducer, { deviceFormSaga, $$initialState } from '../ducks/DeviceForm';

export default (props, _railsContext) => { // eslint-disable-line
  // reducer
  const combinedReducer = combineReducers({
    $$deviceFormStore: reducer,
  });

  const initialState = {
    $$deviceFormStore: $$initialState.mergeDeep({
      devices: fromJS(props.devices),
      import_size: props.import_size,
    }),
  };

  const sagaMiddlewares = [];

  const middlewares =
    process.env.NODE_ENV === 'production' ?
    [ravenMiddleware(window.raven)] :
    [createLogger(), ravenMiddleware(window.raven)];

  const sagaMiddleware = createSagaMiddleware(...sagaMiddlewares);
  const composedStore = compose(
    applyMiddleware(sagaMiddleware, ...middlewares),
    process.env.NODE_ENV !== 'production' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  );
  const storeCreator = composedStore(createStore);
  const store = storeCreator(combinedReducer, initialState);

  // Sagaの起動
  sagaMiddleware.run(deviceFormSaga);

  return store;
};

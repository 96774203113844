import { List } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';

import { actions } from '../../../ducks/MessageForm';
import AttachmentForm from '../../../lib/components/AttachmentForm';
import {
  SimpleFormCheckbox,
  SimpleFormHidden,
  SimpleFormSelect,
  SimpleFormString,
  SimpleFormTextarea,
} from '../../../lib/components/SimpleForm';
import { getDispatch } from '../../../lib/dispatchExporter';
import I18n from '../../../lib/i18n';
import Message from '../../../models/Message';
import MessageDetail from '../../../models/MessageDetail';
import { IRootState } from '../../../store/MessageForm';
import BrowserParametersFormContainer from './BrowserParametersForm';
import FeaturedProductFormList from './FeaturedProductFormList';
import GameParametersFormContainer from './GameParametersForm';
import MainContentsForm from './MainContentsForm';
import MainMovieForm from './MainMovieForm';
import RatingFormContainer from './RatingForm';
import RelatedMoviesForm from './RelatedMoviesForm';
import ShopParametersFormContainer from './ShopParametersForm';
import SystemAppletParametersFormContainer from './SystemAppletParametersForm';
import NsoParametersFormContainer from './NsoParametersForm';

function mapStateToProps(state: IRootState) {
  return {
    isChina: state.$$formStore.isChina,
    copyingDetails: state.$$formStore.copyingDetails,
  };
}

interface IProps {
  index: number;
  $$message: Message;
  $$messageDetail: MessageDetail;
  featuredProductDetails: List<any>;
  byCountryGroup: boolean;
  showAllowDomainsForm?: boolean;
  hidden?: boolean;
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

class MessageDetailForm extends React.PureComponent<Props> {
  private dispatch = getDispatch();

  public validationErrorNotification() {
    const { index, copyingDetails, $$messageDetail } = this.props;
    let mainContentsHaveErrors = false;
    let relatedMoviesHaveErrors = false;

    if (Object.keys($$messageDetail.errors).length !== 0) {
      // form入力中にエラーになった場合は、errorのvalueに[null]という値が入ります。
      // 発生しているエラーが[null]のみの場合は、このエラーコンポーネントを表示しないようにします
      const hasErrorMessages = Object.values($$messageDetail.errors).reduce((acc: boolean, cur: string[]) => (acc || cur[0] !== null), false);

      if (!hasErrorMessages) { return null; }

      return (
        <div className="alert alert-danger" role="alert">
          <div> {I18n.t('simple_form.error_notification.default_message')} </div>
          <div> {copyingDetails ? I18n.t('message_details.error.failed_to_copy_message_details') : null} </div>
          <ul>
            { Object.entries($$messageDetail.errors).map(([column, errors]) => {
              if (errors.length === 1 && errors[0] === null) { return null; }
              if (column.match(/^main_contents/)) {
                if (!mainContentsHaveErrors) {
                  mainContentsHaveErrors = true;
                  return(
                    <li key="main_contents">
                      <a href="#main_contents" data-role="scroll" className="no-warning">
                        {I18n.t('activerecord.errors.message_detail.main_contents_have_errors')}
                      </a>
                    </li>
                  );
                }

                return(null);
              }
              if (column.match(/^related_movies/)) {
                if (!relatedMoviesHaveErrors) {
                  relatedMoviesHaveErrors = true;
                  return(
                    <li key="related_movies">
                      <a href="#related_movies" data-role="scroll" className="no-warning">
                        {I18n.t('activerecord.errors.message_detail.related_movies_have_errors')}
                      </a>
                    </li>
                  );
                }

                return(null);
              }

              let columnLabel = '';
              let anchor = '';
              if (column.match(/^list_image/)) {
                anchor = `#message_message_details_attributes_${index}_list_image_attributes_contents`;
                columnLabel = I18n.t('activerecord.attributes.message_detail.list_image');
              } else if (column.match(/^main_image/)) {
                anchor = `#message_message_details_attributes_${index}_main_image_attributes_contents`;
                columnLabel = I18n.t('activerecord.attributes.message_detail.main_image');
              } else if (column.match(/^application_arg/)) {
                anchor = `#message_message_details_attributes_${index}_application_arg_attributes_contents`;
                columnLabel = I18n.t('activerecord.attributes.message_detail.application_arg');
              } else if (column === 'more_shop_query' || column === 'more_application_id') {
                anchor = `#message_message_details_attributes_${index}_more_type`;
                columnLabel = I18n.t(`activerecord.attributes.message_detail.${column}`);
              } else {
                anchor = `#message_message_details_attributes_${index}_${column}`;
                columnLabel = I18n.t(`activerecord.attributes.message_detail.${column}`);
              }
              return (
                <li key={column}>
                  <a href={anchor} data-role="scroll" className="no-warning">{columnLabel}</a>
                  {`: ${errors.join(',')}`}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return (null);
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    // name = message[message_details_attributes][1][subject]から
    // key = ["messageDetails", 1,  "subject"]を作る必要がある
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const { updateFormStore, validateForm } = actions;

    const value = e.target.value;
    this.dispatch(updateFormStore({ key, value }));
    this.dispatch(validateForm({ key }));
  }

  public handleFooterAnnotationCheckboxChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.value === '1' ? true : false;
    const { index } = this.props;
    const footerTextKey = ['messageDetails', index, 'footer_text'];
    this.dispatch(actions.updateFormStore({ key, value }));
    this.dispatch(actions.updateFooterTextValidator(index));
    // フラグの更新後は、フラグそのものではなくて、footer_textを再度バリデーションする
    this.dispatch(actions.validateForm({ key: footerTextKey }));
  }

  public handleDestroyCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = ['messageDetails'].concat(e.target.name.replace(/]/g, '').split('[').slice(2));
    const value = e.target.checked ? e.target.value : '';
    this.dispatch(actions.updateFormStore({ key, value }));
  }

  public destroyCheckbox() {
    const { index, $$messageDetail } = this.props;
    if ($$messageDetail.id) {
      return (
        <SimpleFormCheckbox
          name={`message[message_details_attributes][${index}][_destroy]`}
          label={I18n.t('messages.form.destroy_message_detail')}
          onChange={this.handleDestroyCheckbox}
        />
      );
    }

    return (null);
  }

  public renderMoreTypeParametersComponent() {
    const { index, $$message, $$messageDetail } = this.props;
    switch ($$messageDetail.more_type) {
      case 'SHOP':
        return (<ShopParametersFormContainer {...{ index, $$message, $$messageDetail }} />);
      case 'BROWSER':
        return (<BrowserParametersFormContainer {...{ index, $$messageDetail }} />);
      case 'GAME':
        return (<GameParametersFormContainer {...{ index, $$messageDetail, hasNxNewsFormLevel: $$message.hasNxNewsLevel() }} />);
      case 'SYSTEM_APPLET':
        return (<SystemAppletParametersFormContainer {...{ index, $$messageDetail }} />);
      case 'NSO':
        return (<NsoParametersFormContainer {...{ index, $$messageDetail }} />);
      default:
        return (null);
    }
  }

  public renderRatingForm() {
    const { index, $$messageDetail } = this.props;
    return (<RatingFormContainer {...{ index, $$messageDetail }} />);
  }

  // 表示していない言語のパラメータをsaveしないために必要
  // _destroy を渡すことで、DBにセーブされているレコードも削除できます。
  public renderHiddenDestroy() {
    const { index, hidden } = this.props;
    if (hidden) {
      return (
        <input
          type="hidden"
          name={`message[message_details_attributes][${index}][_destroy]`}
          value="1"
        />
      );
    }

    return null;
  }

  public renderMoreTypeSelector() {
    const { index, $$message, $$messageDetail, byCountryGroup, isChina } = this.props;
    const moreTypes = (window as any).Constants.message.moreTypes;
    const moreTypeOptions = Object.keys(moreTypes)
      .filter(e => {
        // この2つはNXニュースだけで使える機能
        if (e === 'SYSTEM_APPLET' && !$$message.hasNxNewsLevel()) { return false; }
        // https://spdlybra.nintendo.co.jp/jira/browse/BCAT-2429 の事情により一旦閉じておく
        // if (e === 'BROWSER' && !$$message.hasNxNewsLevel()) { return false; }
        if (e === 'BROWSER') { return false; }
        // 国別配信がオフのフォームでは選択させない
        if (e === 'SHOP' && !byCountryGroup) { return false; }
        // v1ではつかわせない
        if (e === 'GAME' && $$message.semantics_version === 1) { return false; }
        // 「NSO」は、以下の場合には選択肢に表示しない。
        // 総合ニュース(ニュースフォームの閲覧レベルが「NX_NEWS」または「DEBUGGER_NEWS」のチャンネル)ではない場合
        // セマンティックバージョンがv9未満の場合
        // Terra(中国)向けのニュースの場合
        if (e === 'NSO' && ((!($$message.hasNxNewsLevel() || $$message.hasDebuggerNewsLevel())) || $$message.semantics_version < 9 || isChina)) { return false; }
        return true;
      }).map<[string, string]>(e => [e, e]);

    return (
      <SimpleFormSelect
        name={`message[message_details_attributes][${index}][more_type]`}
        label={I18n.t('activerecord.attributes.message_detail.more_type')}
        includeBlank={true}
        values={moreTypeOptions}
        value={$$messageDetail.more_type || ''}
        errorMessage={$$messageDetail.errors.more_type}
        popoverText={I18n.t('message_details.tooltips.more_type')}
        onChange={this.handleChange}
      />
    );
  }

  public renderShowDistributionArea() {
    const { $$message, $$messageDetail, index, isChina } = this.props;
    const options: Array<[string, number]> = [
      [I18n.t('activerecord.attributes.message_detail.show_distribution_area_items.include'), 1],
      [I18n.t('activerecord.attributes.message_detail.show_distribution_area_items.not_include'), 0],
    ];

    if ($$message.isSystemNews() || isChina) {
      return(
        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][show_distribution_area]`}
          value={0}
        />
      );
    }

    return (
      <div className="col-md-4">
        <SimpleFormSelect
          name={`message[message_details_attributes][${index}][show_distribution_area]`}
          label={I18n.t('activerecord.attributes.message_detail.show_distribution_area')}
          values={options}
          value={$$messageDetail.show_distribution_area ? 1 : 0}
          onChange={this.handleFooterAnnotationCheckboxChange}
          popoverText={I18n.t('message_details.tooltips.show_distribution_area')}
        />
      </div>
    );
  }

  public renderContentsExcuse() {
    const { $$message, $$messageDetail, index } = this.props;

    if ($$message.isSystemNews() || !$$message.isJapan()) {
      return(
        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][show_contents_excuse]`}
          value={0}
        />
      );
    }

    const options: Array<[string, number]> = [
      [I18n.t('activerecord.attributes.message_detail.show_contents_excuse_items.include'), 1],
      [I18n.t('activerecord.attributes.message_detail.show_contents_excuse_items.not_include'), 0],
    ];

    return (
      <div className="col-md-4">
        <SimpleFormSelect
          name={`message[message_details_attributes][${index}][show_contents_excuse]`}
          label={I18n.t('activerecord.attributes.message_detail.show_contents_excuse')}
          values={options}
          value={$$messageDetail.show_contents_excuse ? 1 : 0}
          onChange={this.handleFooterAnnotationCheckboxChange}
          popoverText={I18n.t('message_details.tooltips.show_contents_excuse', {description: I18n.t('nx.description_of_contents_excuse')})}
        />
      </div>
    );
  }

  public renderNsoPrecaution() {
    const { $$message, $$messageDetail, index, isChina } = this.props;
    const options: Array<[string, number]> = [
      [I18n.t('activerecord.attributes.message_detail.show_nso_precaution_items.include'), 1],
      [I18n.t('activerecord.attributes.message_detail.show_nso_precaution_items.not_include'), 0],
    ];

    if ($$message.isSystemNews() || isChina) {
      return (
        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][show_nso_precaution]`}
          value={0}
        />
      );
    }

    return (
      <div className="col-md-4">
        <SimpleFormSelect
          name={`message[message_details_attributes][${index}][show_nso_precaution]`}
          label={I18n.t('activerecord.attributes.message_detail.show_nso_precaution')}
          values={options}
          value={$$messageDetail.show_nso_precaution ? 1 : 0}
          onChange={this.handleFooterAnnotationCheckboxChange}
          popoverText={I18n.t('message_details.tooltips.show_nso_precaution')}
        />
      </div>
    );
  }

  public renderFeaturedProductsForm(lengths: { [s: string]: string }) {
    const { $$message, $$messageDetail, featuredProductDetails, index, hidden } = this.props;
    // FIXME: hiddenにより入力されていない言語がPOSTされている
    // 状態をモデルに持てばhiddenフラグが不要になるので、そのときになおす
    if ($$message.canInputListNews() && !hidden) {
      return (
        <div>
          <SimpleFormString
            name={`message[message_details_attributes][${index}][featured_list_name]`}
            label={I18n.t('activerecord.attributes.message_detail.featured_list_name')}
            value={$$messageDetail.featured_list_name || ''}
            errorMessage={$$messageDetail.errors.featured_list_name}
            helpMessage={lengths.featured_list_name}
            popoverText={I18n.t('message_details.tooltips.featured_list_name')}
            onChange={this.handleChange}
          />
          <FeaturedProductFormList
            productDetails={featuredProductDetails}
            language={$$messageDetail.language}
            languageIndex={index}
            semanticsVersion={$$message.semantics_version}
          />
        </div>
      );
    }
    return null;
  }

  public renderRelatedMoviesForm() {
    const { $$message, $$messageDetail, index } = this.props;
    if (!$$message.canInputMovieNews()) { return null; }
    return (
      <div id="related_movies">
        <RelatedMoviesForm
          parentFormName={`message[message_details_attributes][${index}][related_movies_attributes]`}
          {...{ $$messageDetail, index }}
        />
      </div>
    );
  }

  public renderMainContentsForm(lengths: { [s: string]: string }) {
    const { index, $$message, $$messageDetail } = this.props;

    if ( $$message.semantics_version >= 5) {
      return (
        <div id="main_contents">
          <MainContentsForm {...{index, $$messageDetail}} />
        </div>
      );
    } else {
      return(
        <div>
          {/* メイン画像 */}
          <AttachmentForm
            label={I18n.t('activerecord.attributes.message_detail.main_image')}
            attachmentName={`message[message_details_attributes][${index}][main_image_attributes]`}
            attachmentType="message_main_image"
            helpMessage={$$messageDetail.main_contents_attributes.isEmpty() ? I18n.t('activerecord.hints.message_detail.main_image') : I18n.t('activerecord.hints.message_detail.main_image_with_movie')}
            popoverText={I18n.t('message_details.tooltips.main_image')}
            $$attachment={$$messageDetail.main_image_attributes}
          />

          {/* メイン動画*/}
          <MainMovieForm
            label={I18n.t('activerecord.attributes.main_content.main_movie')}
            index={index}
            parentFormName={`message[message_details_attributes][${index}]`}
            parentResource={$$messageDetail}
          />

          {/* ニュースの本文 */}
          <SimpleFormTextarea
            name={`message[message_details_attributes][${index}][body]`}
            label={I18n.t('activerecord.attributes.message_detail.body')}
            value={$$messageDetail.body || ''}
            errorMessage={$$messageDetail.errors.body}
            helpMessage={`${lengths.body} ${I18n.t('message_details.hints.strong_tag_is_available')}`}
            popoverText={I18n.t('message_details.tooltips.body')}
            rows={10}
            onChange={this.handleChange}
            deviceFont={true}
          />
        </div>
      );
    }
  }

  public renderDecorationTextForm(lengths: { [s: string]: string }) {
    const { index, $$message, $$messageDetail } = this.props;

    if ($$message.semantics_version < 7 || $$message.decoration_type !== 'simple') {
      return(
        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][decoration_text]`}
          value={''}
        />
      );
    }

    return(
      <SimpleFormTextarea
        name={`message[message_details_attributes][${index}][decoration_text]`}
        label={I18n.t('activerecord.attributes.message_detail.decoration_text')}
        value={$$messageDetail.decoration_text || ''}
        errorMessage={$$messageDetail.errors.decoration_text}
        helpMessage={lengths.decoration_text}
        popoverText={I18n.t('message_details.tooltips.decoration_text')}
        rows={4}
        onChange={this.handleChange}
        deviceFont={true}
      />
    );
  }

  public render() {
    const { index, $$messageDetail } = this.props;
    const lengths: { [s: string]: string } = {};
    Object.keys($$messageDetail.validators).forEach(key => {
      const length = $$messageDetail[key] ? $$messageDetail[key].length : 0;
      lengths[key] = `${length}/${$$messageDetail.validators[key].maximum}`;
    });

    return (
      <div>
        {this.validationErrorNotification()}
        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][language]`}
          value={$$messageDetail.language}
        />
        {this.destroyCheckbox()}

        {this.renderDecorationTextForm(lengths)}

        {/* リスト画像 */}
        <AttachmentForm
          label={I18n.t('activerecord.attributes.message_detail.list_image')}
          required={true}
          attachmentName={`message[message_details_attributes][${index}][list_image_attributes]`}
          attachmentType="message_list_image"
          helpMessage={I18n.t('activerecord.hints.message_detail.list_image')}
          popoverText={I18n.t('message_details.tooltips.list_image')}
          errorMessage={$$messageDetail.errors.list_image}
          $$attachment={$$messageDetail.list_image_attributes}
        />

        {/* ニュースのタイトル */}
        <SimpleFormTextarea
          name={`message[message_details_attributes][${index}][subject]`}
          required={true}
          label={I18n.t('activerecord.attributes.message_detail.subject')}
          value={$$messageDetail.subject || ''}
          errorMessage={$$messageDetail.errors.subject}
          helpMessage={lengths.subject}
          popoverText={I18n.t('message_details.tooltips.subject')}
          onChange={this.handleChange}
          deviceFont={true}
        />

        {/* main contents */}
        { this.renderMainContentsForm(lengths) }

        {/* ランキング、特集ニュースの商品 */}
        {this.renderFeaturedProductsForm(lengths)}

        {/* リンクの種類セレクター */}
        {this.renderMoreTypeSelector()}

        {/* 各リンクごとの設定 */}
        <div className="more-parameter-form col-md-12">
          {/* 選択されてないmore_typeのパラメータが削除されるようにから文字列のhiddenを仕込んでおきます。*/}

          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_link_url]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_link_text]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_shop_text]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_application_text]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_system_applet_type]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_system_applet_text]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_nso_text]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][more_nso_relative_url]`}
            value=""
          />
          <SimpleFormHidden
            name={`message[message_details_attributes][${index}][allow_domains]`}
            value=""
          />
          { this.renderMoreTypeParametersComponent() }
        </div>

        {/* 関連動画 */}
        {this.renderRelatedMoviesForm()}

        {/* フッター */}
        <SimpleFormTextarea
          name={`message[message_details_attributes][${index}][footer_text]`}
          label={I18n.t('activerecord.attributes.message_detail.footer_text')}
          value={$$messageDetail.footer_text || ''}
          errorMessage={$$messageDetail.errors.footer_text}
          helpMessage={lengths.footer_text}
          popoverText={I18n.t('message_details.tooltips.footer_text')}
          rows={10}
          onChange={this.handleChange}
          deviceFont={true}
        />

        {/* 宛先地域の記載 */}
        <div className="row">
          { this.renderShowDistributionArea() }
          { this.renderNsoPrecaution() }
          { this.renderContentsExcuse() }
        </div>

        {/* レーティング */}
        {this.renderRatingForm()}

        <SimpleFormHidden
          name={`message[message_details_attributes][${index}][id]`}
          value={$$messageDetail.id || ''}
        />

        { this.renderHiddenDestroy() }
      </div>
    );
  }
}

export default connect(mapStateToProps)(MessageDetailForm);

import React from 'react';

import I18n from '../../../lib/i18n';
import ListImage from './ListImage';
import NewsText from './NewsText';

interface IProps {
  listImage: string;
  text: string;
  iconImage: string;
}

const NewsListItemUnread = (props: IProps) => {
  const { listImage, text, iconImage } = props;

  return (
    <div>
      <h4>{I18n.t('messages.preview.news_list_item_unread')}</h4>
      <div className="news-list-item-unread list-image-preview">
        <ListImage listImage={listImage} />
        <div className="news-list-item-unread-title">
          <div className="list-news-icon-box">
            <img
              role="presentaion"
              className="list-news-icon"
              src={`data:image/jpeg;base64,${iconImage}`}
            />
          </div>
          <NewsText text={text} className="news-list-item-unread-text"/>
        </div>
      </div>
    </div>
  );
};

export default NewsListItemUnread;